<template>
  <DatePicker
    v-model="period"
    autoApply
    range
    locale="ru"
    class="w-100"
    placeholder="Выставьте период"
    :enable-time-picker="false"
    :clearable="false"
    :format="getRangeUIFormat"
  />
</template>

<script setup>
import { pushFilterToURL } from "@/service/router";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useDatesChanger } from "@/composables/datesChanger";
import { setCurrentMonth } from "@/util/common";

const route = useRoute();
const { getRangeUIFormat } = useDatesChanger();

const period = ref([]);

const formatedPeriod = computed(() => {
  if (!period.value.length) {
    return [];
  }

  return period.value.map((v) => {
    const d = new Date(v).toISOString().split("T")[0];

    return d;
  });
});

watch(
  period,
  (v) => {
    if (v.length === 0) {
      period.value = setCurrentMonth();
      return;
    }

    pushFilterToURL([
      {
        name: "endDate",
        value: encodeURIComponent(formatedPeriod.value[1]),
      },
      {
        name: "startDate",
        value: encodeURIComponent(formatedPeriod.value[0]),
      },
    ]);
  },
  { immediate: true }
);

watch(() => route.query, setDates, { immediate: true });

function setDates() {
  if (!route.query.startDate && !route.query.endDate) {
    return;
  }

  if (route.query.startDate) {
    const start = new Date(route.query.startDate);
    period.value[0] = start;
  }

  if (route.query.endDate) {
    const end = new Date(route.query.endDate);
    period.value[1] = end;
  }
}
</script>
