<template>
  <div class="consultation-booking">

    <router-link
      :to="{ name: 'consultations-parent-list' }"
      class="bordered-item mb-3"
      >К консультациям</router-link
    >

    <PageTitle title="Запись на нонсультацию" />

    <div class="consultation-booking__grid">
      <div
        class="consultation-booking__block consultation-booking__block--employee"
      >
        <h3 class="mb-3">Шаг 1: Консультант</h3>
        <v-label class="mb-2">Выберите сотрудника</v-label>
        <div class="g-col g-col--2-1fr">
          <FilterByEmployee />
        </div>
      </div>

      <div class="consultation-booking__block consultation-booking__block--day">
        <h3 class="mb-3">Шаг 2: День</h3>
        <v-label class="mb-2">Выберите день из доступных в календаре</v-label>

        <DatePicker
          v-if="allowedDates.length > 0"
          v-model="date"
          autoApply
          inline
          locale="ru"
          class="w-100"
          :disabled-week-days="[6, 0]"
          :format="getOneDayUIFormat"
          :enable-time-picker="false"
          placeholder="Введите дату"
          :min-date="new Date()"
          :allowed-dates="allowedDates"
          :day-class="getDayClass"
          :month-change-on-scroll="false"
        />
        <p v-else>Доступных дат нет</p>
      </div>

      <div
        class="consultation-booking__block consultation-booking__block--time"
      >
        <h3 class="mb-3">Шаг 3: Выберите время</h3>

        <ul v-if="timeSlots.length" class="d-flex flex-wrap list">
          <li
            v-for="s in timeSlots"
            :key="s.id"
            class="ma-1"
            :class="{ chosen: slot && s.id === slot.id }"
          >
            <AppButton @click="slot = s">
              {{ getTimeFromDate(s.consultationDate) }}
              ({{ s.consultationDuration }} мин)
            </AppButton>
          </li>
        </ul>
        <p v-else>Пожалуйста выполните предыдущие шаги</p>
      </div>

      <div
        class="consultation-booking__block consultation-booking__block--finish"
      >
        <h3 class="mb-3">Шаг 4: Подтвердите бронирование</h3>

        <div>
          <p v-if="date">
            {{ getDateFormatWithoutTime(date) }}
          </p>
          <p v-if="slot && slot.consultationDate">
            {{ getTimeFromDate(slot.consultationDate) }}
            ({{ slot.consultationDuration }} мин)
          </p>
        </div>

        <AppButton :disabled="isDisabled" @click="book"
          >Забронировать</AppButton
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDatesChanger } from "@/composables/datesChanger";
import { useConsultationsStore } from "@/store/consultations";
import { storeToRefs } from "pinia";

import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { computed, onMounted, ref, watch } from "vue";

import { areTwoDatesEqual } from "@/util/common";
import { useRoute, useRouter } from "vue-router";
import { useConsultationsData } from "@/composables/consultations";

import FilterByEmployee from "@/components/filtersElements/FilterByEmployee.vue";
import PageTitle from "@/elements/PageTitle.vue";
import AppButton from "@/elements/AppButton.vue";

const { showError, showToastPopup } = usePopupStore();
const { getOneDayUIFormat, getTimeFromDate, getDateFormatWithoutTime } =
  useDatesChanger();

const { consultations } = storeToRefs(useConsultationsStore());
const { setConsultations } = useConsultationsStore();
const { getSlotsByEmployee } = useConsultationsData();

const date = ref(null);
const slot = ref(null);

const route = useRoute();
const router = useRouter();

const isDisabled = computed(() => {
  return !date.value || !slot.value || !route.query.filterByEmployee;
});

const availableSlots = computed(() => {
  if (!consultations.value.length) {
    return [];
  }

  return consultations.value.filter(
    (v) => v.parentConsultationStatus === "CONSULTATION_CREATED"
  );
});

watch(date, () => {
  slot.value = null;
});

watch(() => route.query.filterByEmployee, getSlotsByEmployee, {
  immediate: true,
});

onMounted(() => {
  setConsultations([]);
});

watch(
  availableSlots,
  (l) => {
    if (l.length === 0) {
      return;
    }

    date.value = [0].consultationDate;
  },
  {
    deep: true,
  }
);

const allowedDates = computed(() => {
  if (availableSlots.value.length === 0) {
    return [];
  }

  return availableSlots.value.map((v) => v.consultationDate);
});

const timeSlots = computed(() => {
  const d = new Date(date.value);

  return availableSlots.value.filter((v) => {
    const d2 = new Date(v.consultationDate);
    return areTwoDatesEqual(d, d2);
  });
});

async function book() {
  if (!slot.value) {
    return;
  }

  try {
    await bookHandler();
    showToastPopup("Вы записаны на консультацию");
    router.push({ name: "consultations-parent-list" });
  } catch (error) {
    showError(error);
  }
}

async function bookHandler() {
  return customAxios.post("/parent_consultation/parent/book_slot", {
    slotId: slot.value.id,
  });
}

function getDayClass(date) {
  const isExisted =
    allowedDates.value.findIndex((v) => areTwoDatesEqual(date, v)) >= 0;

  if (!isExisted) {
    return;
  }
  return "highlighted-day";
}
</script>

<style lang="scss">
.consultation-booking {
  li {
    background-color: $main-2;
    color: #fff;
    border-radius: 8px;
    padding: 8px;
    font-weight: 600;

    &.chosen {
      background-color: $success;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }

  &__block {
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;

    &--employee {
      grid-column: 1/ -1;
      grid-row: 1 / 2;
    }

    &--day {
      grid-column: 1/ 2;
      grid-row: 2 / 4;
    }

    &--finish {
      display: grid;
      grid-template-rows: max-content 1fr max-content;

      p {
        text-align: center;
        font-size: 20px;
        line-height: 40px;
        font-weight: 600;
        color: $success;
      }

      button {
        background-color: $success;
        color: #fff;
        padding: 8px;
        border-radius: 8px;
        font-weight: 600;

        &:disabled {
          background-color: rgba($text, 0.2);
        }
        &:hover:not(:disabled) {
          background-color: rgba($success, 0.8);
        }
        &:active:not(:disabled) {
          background-color: rgba($success, 0.9);
        }
      }
    }
  }

  .highlighted-day {
    // background-color: $main-2;
    // color: #fff;
  }

  .dp__active_date {
    // background-color: $success !important;
    // border-color: $success !important;
  }

  @media (max-width: 768px) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
</style>
