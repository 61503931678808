<template>
  <div>
    <SectionPromo class="mb-5" />

    <div v-if="user">
      <WChild v-if="isChild" class="mb-10" />
      <WBookings v-if="mainRole === 'ROLE_PARENT'" />
      <WCommonCognations />
      <WLastPhotos />
      <SchoolNews />
    </div>

    <div v-else>
      <SectionAbout />
      <SectionBenefits />
      <SectionContactUs />
    </div>

    <SiteFooter />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/auth";

import SchoolNews from "./SchoolNews.vue";
import SiteFooter from "@/home/SiteFooter.vue";
import WChild from "@/widgets/WChild.vue";

import WCommonCognations from "@/widgets/WCommonCognations.vue";
import WLastPhotos from "@/widgets/WLastPhotos.vue";
import SectionContactUs from "@/home/SectionContactUs.vue";

import SectionBenefits from "@/home/SectionBenefits.vue";
import SectionAbout from "@/home/SectionAbout.vue";
import SectionPromo from "@/home/SectionPromo.vue";
import WBookings from "@/consultations/parent/WBookings.vue";

const { user, isChild, mainRole } = storeToRefs(useAuthStore());
</script>
