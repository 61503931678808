<template>
    <div class="s-preview">
    <div class="s-preview__top">
      <div v-if="item.id">
        <v-label class="mr-2">ID анализа:</v-label>
        <span> {{ item.id }}</span>
      </div>
      <div v-if="item.createDateTime">
        <v-label class="mr-2">Дата создания анализа:</v-label>
        <span> {{ getDateFormatWithTime(item.createDateTime) }}</span>
      </div>
      <div v-if="item.analysisDateTime">
        <v-label class="mr-2">Дата последней правки:</v-label>
        <span> {{ getDateFormatWithTime(item.analysisDateTime) }}</span>
      </div>
      <div>
        <v-label class="mr-2">Автор:</v-label>
        <span  v-if="!!item.creator"> {{ item.creator.lastname }} {{ item.creator.firstname }} {{ item.creator.secname }}</span>
      </div>
      <div>
        <v-label class="mr-2">Поседняя версия:</v-label>
        <span> {{ item.isLastVersion ? "Да" : "Нет" }}</span>
      </div>
      <div>
        <v-label class="mr-2">Виден родителям:</v-label>
        <span> {{ item.showForParent ? "Да" : "Нет" }}</span>
      </div>
      
      <div>
        <v-label class="mr-2">Strengths:</v-label>
        <div >
            <ol v-if="item.strengthsTagSet && item.strengthsTagSet.length > 0" class="pl-5">
                <li v-for="p in item.strengthsTagSet" :key="p.id">{{ p.name }}</li>
            </ol>
            <span v-else>Ярлыки не выбраны...</span>
        </div>
      </div>

      <div>
        <v-label class="mr-2">Weaknesses:</v-label>
        <div >
            <ol v-if="item.weaknessesTagSet && item.weaknessesTagSet.length > 0" class="pl-5">
                <li v-for="p in item.weaknessesTagSet" :key="p.id">{{ p.name }}</li>
            </ol>
            <span v-else>Ярлыки не выбраны...</span>
        </div>
      </div>

      <div>
        <v-label class="mr-2">Opportunities:</v-label>
        <div >
            <ol v-if="item.opportunitiesTagSet && item.opportunitiesTagSet.length > 0" class="pl-5">
                <li v-for="p in item.opportunitiesTagSet" :key="p.id">{{ p.name }}</li>
            </ol>
            <span v-else>Ярлыки не выбраны...</span>
        </div>
      </div>

      <div>
        <v-label class="mr-2">Threats:</v-label>
        <div >
            <ol v-if="item.threatsTagSet && item.threatsTagSet.length > 0" class="pl-5">
                <li v-for="p in item.threatsTagSet" :key="p.id">{{ p.name }}</li>
            </ol>
            <span v-else>Ярлыки не выбраны...</span>
        </div>
      </div>

      <div>
        <v-label class="mr-2">Содержимое:</v-label>
        <span v-if="item.noteBody"> {{ item.noteBody }}</span>
        <span v-else>Заметка пуста...</span>

      </div>

     

    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";


defineProps({ item: { type: Object, required: true } });
const { getDateFormatWithTime } = useDatesChanger();

</script>

<style lang="scss">

.s-preview {
  background-color: #fff;
  box-shadow: $shadow;
  border-radius: 8px;
  cursor: pointer;

  a {
    text-decoration: none;
    color: $text;
  }

  li {
    text-decoration: none;
    color: $main-2;
  }

  span {
    color: $main-2;
  }

  &__toggler {
    background-color: $main-2;
    color: #fff;
    display: flex;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 4px;
    align-items: center;

    svg {
      stroke: #fff;
      margin-left: 8px;
    }

    &.isOpened {
      svg {
        transform: rotate(-180deg);
      }
    }

    &:hover {
      background-color: rgba($main-2, 0.8);
    }
    &:active {
      background-color: rgba($main-2, 0.7);
    }
  }

  &__top {
    padding: 8px;
  }
}
</style>
