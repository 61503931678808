<template>
    <div class="s-preview">
    <div class="s-preview__top">
      <div v-if="item.id">
        <v-label class="mr-2">ID анализа:</v-label>
        <span> {{ item.id }}</span>
      </div>
      <div v-if="item.createDateTime">
        <v-label class="mr-2">Дата создания анализа:</v-label>
        <span> {{ getDateFormatWithTime(item.createDateTime) }}</span>
      </div>
      <div v-if="item.analysisDateTime">
        <v-label class="mr-2">Дата последней правки:</v-label>
        <span> {{ getDateFormatWithTime(item.analysisDateTime) }}</span>
      </div>
      <div>
        <v-label class="mr-2">Автор:</v-label>
        <span  v-if="!!item.creator"> {{ item.creator.lastname }} {{ item.creator.firstname }} {{ item.creator.secname }}</span>
      </div>
      <div>
        <v-label class="mr-2">Поседняя версия:</v-label>
        <span> {{ item.isLastVersion ? "Да" : "Нет" }}</span>
      </div>
      <div>
        <v-label class="mr-2">Виден родителям:</v-label>
        <span> {{ item.showForParent ? "Да" : "Нет" }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";


defineProps({ item: { type: Object, required: true } });
const { getDateFormatWithTime } = useDatesChanger();

</script>

<style lang="scss">
.s-preview {
  background-color: #fff;
  box-shadow: $shadow;
  border-radius: 8px;
  cursor: pointer;

  a {
    text-decoration: none;
    color: $text;
  }

  span {
    color: $main-2;
  }

  &__toggler {
    background-color: $main-2;
    color: #fff;
    display: flex;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 4px;
    align-items: center;

    svg {
      stroke: #fff;
      margin-left: 8px;
    }

    &.isOpened {
      svg {
        transform: rotate(-180deg);
      }
    }

    &:hover {
      background-color: rgba($main-2, 0.8);
    }
    &:active {
      background-color: rgba($main-2, 0.7);
    }
  }

  &__top {
    padding: 8px;
  }
}
</style>
