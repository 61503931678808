<template>
  <tr>
    <td>
      {{ getDateFormatWithTime(item.consultationDate) }} ({{
        item.consultationDuration
      }}
      мин)
    </td>
    <td>
      {{ item.consultant.lastname }} {{ item.consultant.firstname }}
      {{ item.consultant.secname }}
    </td>
    <td>
      {{ consultationStatuses[item.parentConsultationStatus] }}
    </td>
    <td>
      <AppButton
        v-if="canUnbook"
        @click="unbook"
        class="bordered-item ma-1 bordered-item--danger"
        >Отменить</AppButton
      >
      <p v-else>Не доступны</p>
    </td>
    <td>
      <AppButton
        v-if="item.parentConsultationStatus === 'CONSULTATION_COMPLETED'"
        class="bordered-item"
        @click="$emit('showMessages', item.id)"
        >Протокол</AppButton
      >
      <p v-else>Не доступны</p>
    </td>
  </tr>
</template>

<script setup>
import { consultationStatuses } from "@/util/common";
import { computed, defineProps, defineEmits } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

import AppButton from "@/elements/AppButton.vue";
import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";
import { useConsultationsData } from "@/composables/consultations";

const { getDateFormatWithTime } = useDatesChanger();
const { showError, showToastPopup, showConfirmPopup, closePopup } =
  usePopupStore();
const { getMyBookings } = useConsultationsData();
const props = defineProps({ item: { type: Object, required: true } });
defineEmits(["showMessages"]);

const canUnbook = computed(
  () =>
    [
      "CONSULTATION_CREATED",
      "CONSULTATION_BOOKED",
      "CONSULTATION_BOOKED_CONFIRMED",
    ].findIndex((v) => v === props.item.parentConsultationStatus) >= 0
);

async function unbook() {
  try {
    const res = await customConfirm();
    if (res === "yes") {
      closePopup();
      await unbookHandler();
      showToastPopup("Консультация отменена");
      getMyBookings();
    } else {
      closePopup();
    }
  } catch (error) {
    showError(error);
  }
}

async function customConfirm() {
  return showConfirmPopup("Отменить консультацию?");
}

async function unbookHandler() {
  return customAxios.post("/parent_consultation/parent/unbook_slot", {
    slotId: props.item.id,
  });
}
</script>
