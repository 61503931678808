<template>
  <div>
    <PageTitle title="Таблица Журнала" />

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>

      <template v-slot:content>
        <p class="mb-3">Здесь отобразится журнал.</p>

        <v-label>Выставьте</v-label>
        <ol class="pl-5">
          <li>Год</li>
          <li>Предмет</li>
          <li>Класс</li>
          <li>Период</li>
        </ol>
      </template>
    </TogglerOfWrapper>

    <div v-if="isAdmin" class="d-flex justify-center my-3">
      <router-link
        :to="{ name: 'teacher-journal-create' }"
        class="accent-button"
        >Создать Журнал</router-link
      >
    </div>

    <JSearch class="my-3" />
    <div v-if="journal">
      <div class="d-flex justify-center mt-5">
        <router-link
          :to="{
            name: 'sheets',
            query: {
              filterByJournalId: $route.query.filterByJournalId,
            },
          }"
          class="bordered-item ma-1"
          >К оценочным</router-link
        >
        <router-link
          :to="{
            name: 'dictations',
            query: {
              filterByJournalId: $route.query.filterByJournalId,
            },
          }"
          class="bordered-item ma-1"
          >К диктантам</router-link
        >
        <v-btn @click="getChilds" class="bordered-item ma-1">
        Добавить ребенка
        </v-btn>
      </div>
      <div v-if="showChildForm" class="mt-4 mb-4">
        <div class="page-wrapper">
          <h3 class="mb-3">Добавить ребенка в журнал</h3>
          <v-autocomplete
                    v-model="journalChildren"
                    :items="journalChildrenset"
                      outlined
                      dense
                      item-value="id"
                      label="Дети из группы (подгруппы)"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.lastname + ' ' + item.raw.firstname + ' ' + item.raw.secname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.lastname + ' ' + item.raw.firstname + ' ' + item.raw.secname"
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
            </v-autocomplete>
            <div class="text-right">
              <v-btn @click="showChildForm=false" class="bordered-item ma-1">Отмена</v-btn>
              <v-btn @click="addChildToJournal" class="bordered-item ma-1">Добавить</v-btn>
            </div>
        </div>
      </div>
      <JPreview class="my-2" />
      <JTable class="mt-5" />
      
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { useAuthStore } from "@/store/auth";

import PageTitle from "@/elements/PageTitle.vue";
import JSearch from "./JSearch.vue";
import JTable from "./JTable.vue";

import JPreview from "./JPreview.vue";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import {ref} from "vue";
import { customAxios } from "@/service/customAxios";
import { useRoute } from "vue-router";
import { usePopupStore } from "@/store/popup";
const route = useRoute();
const { showError} = usePopupStore();

const { isAdmin } = storeToRefs(useAuthStore());
const { journal } = storeToRefs(useSubjectsJournalStore());
const journalChildrenset = ref([]);
const journalChildren = ref('');
const showChildForm = ref(false);

async function getChildSet() {
  return customAxios.get("/teacher/log/children_for_log/" + route.query.filterByJournalId);
}
async function addChild() {
  return customAxios.post("/teacher/log/add_child", {
    listId: Number(route.query.filterByJournalId),
    childId: journalChildren.value
  });
}
async function addChildToJournal() {
  try {
    const res =await addChild();
    console.log("res:" + res.data)
   
    showChildForm.value=false;
    journalChildren.value='';
    
  } catch (e) {
    showError(e);
    showChildForm.value=false;
    journalChildren.value='';
  }
}

async function getChilds() {
  try {
    const res =await getChildSet();
    console.log("res:" + res.data)
    journalChildrenset.value=res.data;
    if(journalChildrenset.value.length>0){
      journalChildren.value=journalChildrenset.value[0].id
    }
    showChildForm.value=true;
  } catch (e) {
    showError(e);
  }
}
</script>
