<template>
  <div v-if="roles.length >= 2" class="mt-5 mb-2">
    <v-label>Ваша основная роль</v-label>
    <v-select
      variant="outlined"
      density="compact"
      hide-details
      :items="roles"
      v-model="role"
    />
  </div>
</template>

<script setup>
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";

const { roles, mainRole } = storeToRefs(useAuthStore());
const { setMainRole } = useAuthStore();

const role = ref(mainRole.value);

watch(
  role,
  (v) => {
    if (!v) {
      role.value = roles.value[0].value;
    }

    setMainRole(v);
  },
  { immediate: true }
);
</script>
