<template>
  
  <div>
    <AppButton @click="$router.back"  class="bordered-item mb-3">Назад</AppButton>
    <PageTitle :title="'Справочник ярлыков для SWOT анализа'" />
    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <div class="my-3">
          <label>Ярлыки:</label>
          <ol class="pl-5">
            <li>
              Справчник наполняеся краткими характеристиками - Ярлыками для наполнения разделов SWOT анализа.
            </li>
          </ol>  
        </div>
        <div class="my-3">
          <label>Параметры Ярлыка:</label>
          <ol class="pl-5">
            <li>
              <strong>Ярлык</strong> - текстовое описание характеристики, не более 250 символов.
            </li>
            <li>
              <strong>Тип</strong> - соответствует разделу SWOT анализа.
            </li>
            <li>
              <strong>Активен для выбора</strong> - параметр, отвечающий за доступность Ярлыка для выбора при заполнении SWOT анализа.
            </li>
            
          </ol>
        </div>
        <div class="my-3">
          <label>Удаление Ярлыка:</label>
          <ol class="pl-5">
            <li>
              Кнопка <strong>Удалить </strong> - в таблице удалит Ярлык если он ни где не используется.
            </li>
          </ol>
        </div>
        <div class="my-3">
          <label>Редактирование Ярлыка:</label>
          <ol class="pl-5">
            <li>
              Кнопка <strong>Изменить </strong> - даст полностью изменить не используемый Ярлык, в противном случае изменится только параметр  "Активен для выбора".
            </li>
          </ol>
        </div>
      </template>
 
    </TogglerOfWrapper>
    <AppButton @click="showTagModal=true" class="bordered-item ma-1">Добавить</AppButton>
    <v-dialog v-model="showTagModal" persistent scrollable>
      <div class="page-wrapper my-3">
            <div class="ma-1">
                <h4>Ярлык</h4>
                <v-text-field
                    v-model="name"
                    maxlength="250"
                    variant="outlined"
                    counter="250"
                    hide-details
                ></v-text-field>
            </div>
            <div class="ma-1">
                <h4>Тип</h4>
                <v-autocomplete
                    v-model="tagTypes"
                    :items="sourceTagTypes"
                    variant="outlined"
                    placeholder="Тип"
                    density="compact"
                    hide-detail
                    multiple
                ></v-autocomplete>
            </div>
            <div class="ma-1">
                <h4>Раздел</h4>
                <v-autocomplete
                    v-model="sectionId"
                    :items="sections"
                    variant="outlined"
                    placeholder="Раздел"
                    item-value="id"
                    item-title="sectionName"
                    density="compact"
                    hide-detail
                ></v-autocomplete>
            </div>
            <div class="ma-1">
                <h4>Активен для выбора</h4>
                <v-switch
                    v-model="active"
                    :label="`${active ? ` Да` : `Нет`}`"
                    color="success"
                    hide-details
                />
            </div>
            <div class="d-flex justify-center flex-wrap mt-5">
              <AppButton v-if="id" @click="save" class="bordered-item ma-1">Сохранить</AppButton>
              <AppButton v-else @click="create" class="bordered-item ma-1">Добавить</AppButton>
              <AppButton v-if="id" @click="clearSwotTag" class="bordered-item ma-1">Отменить </AppButton>
        </div>
    </div>
    </v-dialog>
    <div class="s-report">
        <Vue3EasyDataTable
        :empty-message="'Ярлыки не добавлены'"
        :index-column-width="20"
        show-index
        fixed-index
        :rows-of-page-separator-message="'из'"
        :rows-per-page-message="'строк на страницу'"
        :headers="tagHeaders"
        :items="tags"
        multi-sort>
                    <template #item-name="{ name }">{{name}}</template>
                    <template #header-tagTypes="header">
                      <div class="filter-column">
                        <font-awesome-icon @click.stop="showTagTypeFilter=!showTagTypeFilter" icon=" fa-filter" class="filter-icon ma-1" />
                        {{ header.text }}
                        <div class="filter-menu filter-sport-menu" v-if="showTagTypeFilter">
                          <select class="tagType-selector" v-model="tagFilterCriteria" name="tagType" >
                            <option value="STRENGTHS">STRENGTHS</option>
                            <option value="WEAKNESSES"> WEAKNESSES</option>
                            <option value="OPPORTUNITIES">OPPORTUNITIES</option>
                            <option value="THREATS">THREATS</option>
                            <option value="all">Все</option>
                          </select>
                        </div>
                      </div>
                    </template>

                    <template #header-section="header">
                      <div class="filter-column">
                        <font-awesome-icon @click.stop="showSectionFilter=!showSectionFilter" icon=" fa-filter" class="filter-icon ma-1" />
                        {{ header.text }}
                        <div class="filter-menu filter-sport-menu" v-if="showSectionFilter && !!sections && sections.length>0">
                          <select class="tagType-selector" v-model="sectionFilterCriteria" name="tagType" >
                            <option v-for="s in sections" :key="s" value="s.id">{{s.sectionName}}</option>
                            <option value="all">Все</option>
                          </select>
                        </div>
                      </div>
                    </template>


                    <template #item-tagTypes="{tagTypes}">
                      <div v-if="!!tagTypes && tagTypes.length>0">
                        <p v-for="t in tagTypes" :key="t">{{ t}}</p>
                      </div>
                      <div v-else>
                        Не задано
                      </div>
                    </template>
                    <template #item-section="{section}">
                       <div v-if="section">{{ section.sectionName }}</div> 
                    </template>
                    <template #item-active="{active}">
                        <div v-if="active">Да</div>
                        <div v-else>Нет</div>
                    </template>
                    <template #item-actions="item">
                        <AppButton 
                            @click="editSwotTag(item)"
                            class="bordered-item ma-1"
                        >Изменить
                        </AppButton>
                        <AppButton 
                            @click="removeSwotTag(item)"
                            class="bordered-item ma-1"
                        >Удалить
                        </AppButton>
                    </template>
        </Vue3EasyDataTable>
    </div>
</div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";
import AppButton from "@/elements/AppButton.vue";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import PageTitle from "@/elements/PageTitle.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";

const tags = ref([]);
const sections = ref([]);
const sectionId = ref('');
const showTagTypeFilter = ref(false);
const tagFilterCriteria = ref('all');
const sourceTagTypes = ref(["STRENGTHS","WEAKNESSES" ,"OPPORTUNITIES", "THREATS"]);
const tagTypes = ref();
const name = ref('');
const active = ref(true);
const id = ref('');
const tagHeaders = ref([
      { text: "Ярлык", value: "name", sortable: true },
      { text: "Тип", value: "tagTypes", sortable: true , width:150},
      { text: "Секция", value: "section", sortable: true , width:150},
      { text: "Активен", value: "active", sortable: true, width:100},
      { text: "Действие", value: "actions", width:200}
    ]);

const { showError  } = usePopupStore();

const showSectionFilter = ref(false);
const sectionFilterCriteria = ref('all');
const showTagModal = ref(false);

const { showToastPopup } = usePopupStore();

//const filtereddata = ref([]);




// const loadingStatus = ref("loading");
//watch(() =>sectionFilterCriteria, filterDataBySection);
//watch(currentNote, setCurrentData);



onMounted(async () => {
  await getTags();
  await getSections();
  tagTypes.value = sourceTagTypes.value;
});

//function filterDataBySection(){
//  if(sectionFilterCriteria.value!=='all'){
//    filtereddata.value = tags.value.filter((s) =>s.sectionId==sectionFilterCriteria.value);
//  } else {
//    filtereddata.value = tags.value;
// }
 
//}
function editSwotTag(tag){
id.value=tag.id;
tagTypes.value=tag.tagTypes;
name.value=tag.name;
active.value=tag.active;
sectionId.value =  tag.sectionId;
showTagModal.value = true;
}

function clearSwotTag(){
id.value='';
tagTypes.value = sourceTagTypes.value;
name.value='';
active.value=true;
sectionId.value = sections.value[0].id;
showTagModal.value = false;

}

function clearSwotTagAfterAdd(){
id.value='';
tagTypes.value = sourceTagTypes.value;
name.value='';
active.value=true;
showTagModal.value = false;
}


async function getTags() {
  const r = await getTagsHandler();
  tags.value = r.data;
}

async function getTagsHandler() {
  return customAxios.get("/tutor/swot/tag");
}

async function getSections() {
  const r = await getSectionsHandler();
  sections.value = r.data;
  sectionId.value = sections.value[0].id
}

async function getSectionsHandler() {
  return customAxios.get("/tutor/swot/tag/direction");
}

async function create() {
  try {
    let r = await createHandler();
    if (r.status==201){
      showToastPopup("Тег создан");
      console.log("r:" + r)
      if(r!=undefined && r.data){
        tags.value.push(r.data);
        clearSwotTagAfterAdd();
      }
    }
    
  } catch (error) {
    console.log("error:" + error)
    showError(error);
  }
}

async function save() {
  try {
    const r = await saveHandler();
    if (r.status==202){
      showToastPopup("Тег обновлен");
      console.log("r:" + r)
      let index = getIndex(tags.value, r.data.id);
      tags.value.splice(index, 1, r.data);
      clearSwotTag();
    }
  } catch (error) {
    console.log("error:" + error)
    showError(error);
  }
}
async function saveHandler() {
  return customAxios.put("/tutor/swot/tag/" +id.value , {
    name: name.value,
    active: active.value,
    tagTypes: tagTypes.value,
    sectionId: sectionId.value,
  });
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}


async function createHandler() {
  return customAxios.post("/tutor/swot/tag", {
    name: name.value,
    active: active.value,
    tagTypes: tagTypes.value,
    sectionId: sectionId.value,
  });
}

async function removeSwotTag(tag) {
    id.value = tag.id;
    removeSwotTagHandler();
    let index = getIndex(tags.value, id);
    tags.value.splice(index, 1);
    showToastPopup("Запись удалена");
}
async function removeSwotTagHandler() {
  console.log("id for remove:" + id.value)
  return customAxios.delete("/tutor/swot/tag/" + id.value);
}
</script>

<style lang="scss">
.s-report {
  &__tags {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}

.filter-column {
  display: flex;
  align-items: center;
  justify-items: center;
  position: relative;
}

.filter-menu {
  padding: 15px 30px;
  z-index: 1;
  position: absolute;
  top: 30px;
  width: 200px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
}

.tagType-selector {
  width: 100%;
}

</style>
