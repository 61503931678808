import {
  workshopsBookingList,
  progress,
  schoolLife,
  schedule,
  usersReports,
} from "./common-links";

const workshops = {
  type: "list",
  title: "Мастерские",
  icon: "flask",
  links: [
    {
      ...workshopsBookingList,
      title: "Бронирование",
    },
    {
      type: "link",
      title: "Мои",
      icon: "circle-icon",
      name: "child-workshops",
    },
  ],
};
export const child = [
  schedule,
  workshops,
  ...progress,
  ...schoolLife,
  ...usersReports,
];
