<template>
  <div class="school-news wrapper my-10">
    <h2 class="text-center">Эвриканские новости</h2>

    <Swiper
      v-if="news.length"
      :navigation="true"
      :pagination="{
        type: 'fraction',
      }"
      :modules="[Navigation, Pagination]"
      class="school-news__swiper"
      :breakpoints="{
        '320': {
          slidesPerView: 1,
          spaceBetween: 8,
        },
        '480': {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        '768': {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      }"
    >
      <SwiperSlide v-for="n in news" :key="n.id" class="school-news__slide">
        <NewsCard :item="n" />
      </SwiperSlide>
    </Swiper>

    <Swiper
      v-else-if="loadingStatus === 'loading'"
      :breakpoints="{
        '320': {
          slidesPerView: 1,
          spaceBetween: 8,
        },
        '480': {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        '768': {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      }"
      class="school-news__swiper"
    >
      <SwiperSlide
        v-for="v in 3"
        :key="v"
        class="school-news__slide school-news__card-shadow"
      >
      </SwiperSlide>
    </Swiper>

    <div v-else class="page-wrapper mt-5">
      <div class="d-flex align-center justify-center">
        <img src="news.png" height="150" />
        <span>Ждём свежих новостей</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useNewsStore } from "@/store/news";

import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper/modules";
import { usePopupStore } from "@/store/popup";

import NewsCard from "@/components/NewsCard";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const { showError } = usePopupStore();
const { news } = storeToRefs(useNewsStore());
const { getPublishedNews, setNews } = useNewsStore();

const loadingStatus = ref("loading");

onMounted(getNews);

async function getNews() {
  loadingStatus.value = "loading";

  try {
    const r = await getPublishedNews();
    setNews(r.data);
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}
</script>

<style lang="scss">
.school-news {
  &__swiper {
    overflow: hidden;
    margin: 0;
    padding: 40px 8px;
    box-sizing: border-box;
  }

  &__slide {
    height: auto;
    align-self: stretch;
  }

  &__card-shadow {
    border-radius: 16px;
    height: 300px;
    background-image: url("../assets/school-pattern.png");

    animation-name: slideHightlight;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-delay: 0s;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }
    &:nth-child(3) {
      animation-delay: 1s;
    }
  }

  .swiper-button-next,
  .swiper-button-prev,
  .swiper-pagination {
    color: $main;
  }

  .swiper-button-next,
  .swiper-button-prev {
    border-radius: 50%;
    box-shadow: $shadow;
    width: 60px;
    height: 60px;
    background-color: rgba(#fff, 0.6);

    &::after {
      font-size: 40px;
    }
  }

  .swiper-button-disabled {
    opacity: 0;
  }
}

@keyframes slideHightlight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
