<template>
  <div v-if="$route.path.includes('create')" class="mb-2">
    <div class="page-wrapper">
      <h4 class="text-deep-purple-lighten-1 font-weight-bold">
        Предупреждение
      </h4>
      <p>Фото/видео можно будет прикрепить после создания Достижения</p>
      <p>(На странице редактирования)</p>
    </div>
  </div>
</template>
