<template>
  <router-view></router-view>
</template>

<script setup>
import { useConsultationsData } from "@/composables/consultations";
import { watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const { getConsultationsForEmployee } = useConsultationsData();

watch(() => route.query.startDate, getConsultationsForEmployee, { immediate: true });
watch(() => route.query.endDate, getConsultationsForEmployee, { immediate: true });
</script>
