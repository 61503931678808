<template>
  <div>
    <h3 class="mb-3">Добавленные слоты</h3>

    <div v-if="slots.length > 0" class="page-wrapper">
      <div class="table-wrapper">
        <table class="table-custom">
          <thead>
            <tr class="table-custom__headers">
              <th></th>
              <th>Дата</th>
              <th>Время</th>

              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, i) in slots" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ getDateFormatWithoutTime(s.consultationDate) }}</td>
              <td>{{ getTimeFromDate(s.consultationDate) }}</td>

              <td>
                <AppButton
                  @click="removeSlot(i)"
                  class="bordered-item bordered-item--danger"
                  >Удалить</AppButton
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <p v-else class="page-wrapper">Пока вы не добавили ни одного слота</p>
  </div>
</template>

<script setup>
import { useDatesChanger } from "@/composables/datesChanger";
import AppButton from "@/elements/AppButton.vue";
import { useConsultationsStore } from "@/store/consultations";

const { getTimeFromDate, getDateFormatWithoutTime } = useDatesChanger();
const { slots, removeSlot } = useConsultationsStore();
</script>
