<template>
    <v-autocomplete
      v-model="skillLevel"
      :items="skillLeels"
      variant="outlined"
      placeholder="Уровень"
      item-value="level"
      item-title="title"
      density="compact"
      hide-details
      bg-color="white"
    ></v-autocomplete>
  </template>
  
  <script setup>
  import { watch, ref, onMounted } from "vue";
  import { removeFilterFromURL, pushFilterToURL } from "@/service/router";
  import { useRoute } from "vue-router";
  
  const route = useRoute();
  
  const skillLeels = ref([
    {title:"Уровень сложности: 1", level:1},
    {title:"Уровень сложности: 2", level:2},
    {title:"Уровень сложности: Все", level:null}]);

  const skillLevel = ref(skillLeels.value[0].level);


  watch(skillLevel, (value) =>{
    if(value){
        pushFilterToURL({ name: "skillLevel", value: value })
    } else {
        removeFilterFromURL("skillLevel")
    }
    }
 )

 onMounted(async () => {
  setCurrentFilter();
});


function setCurrentFilter() {
  let level;
  if (!route.query.skillLevel ) {
    return;
  } else {
    level = Number(route.query.skillLevel);
    if(level && skillLeels.value.findIndex((v) => v.level === level) >= 0){
        let index = skillLeels.value.findIndex((v) => v.level === level);
        skillLevel.value = skillLeels.value[index].level;
    } else {
        skillLevel.value = skillLeels.value[0].level;
    }
  }
}
  watch(() => route.query.skillLevel, setCurrentFilter);

  </script>
  