<template>
  <div class="page-wrapper">
    <div class="table-wrapper">
      <table class="table-custom">
        <thead>
          <tr class="table-custom__headers">
            <th>Начало</th>
            <th>Сотрудник</th>
            <th>Родитель</th>
            <th>
              <p>Время</p>
              <p>(мин)</p>
            </th>
            <th>Статус</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          <CConsultationsRow v-for="c in consultations" :key="c.id" :item="c" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { useConsultationsStore } from "@/store/consultations";
import { storeToRefs } from "pinia";
import CConsultationsRow from "./CConsultationsRow.vue";

const { consultations } = storeToRefs(useConsultationsStore());
</script>
