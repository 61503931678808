<template>
  <router-view></router-view>
</template>

<script setup>
import { watch } from "vue";
import { useRoute } from "vue-router";
import { useJournalData } from "@/composables/journalData";

const { updateJournal } = useJournalData();
const route = useRoute();

watch(() => route.query.filterByJournalId, updateJournal, {
  immediate: true,
});
</script>
