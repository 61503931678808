<template>
  <CustomForm
    title="диктанта"
    @create="create"
    @remove="remove"
    :saveIsDisable="saveIsDisable"
    :canRemove="props.sheet && props.sheet.creatorId === userId"
    :show-add-child="true"
    @addChild="getChilds"
  >
    <div v-if="dateTitle" class="form__item">
      <div class="form__label">Дата</div>
      <v-chip class="pa-2">{{ dateTitle }}</v-chip>
    </div>
    <div
      :class="{
        error: v$.name.$errors.length,
        success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
      }"
      class="form__item"
    >
      <input
        v-model="v$.name.$model"
        type="text"
        @focus="v$.name.$touch"
        placeholder="Название"
        maxlength="100"
        class="form__input"
      />

      <div
        :class="{ 'showing-tip': v$.name.$dirty && v$.name.$model.length }"
        class="form__tip-wrapper"
      >
        <div class="form__tip form__tip--counter showing">
          <span>Любые символы </span>
          <span> {{ v$.name.$model.length }} / 100 </span>
        </div>
      </div>

      <div
        class="input-errors"
        v-for="error of v$.name.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>

      <v-switch
        v-model="isActive"
        color="success"
        :label="isActive ? 'активен' : 'не активен'"
        hide-details
      />
    </div>
  </CustomForm>
  <div v-if="showChildForm" class="mt-4 mb-4">
        <div class="page-wrapper">
          <h3 class="mb-3">Добавить ребенка</h3>
          <v-autocomplete
                    v-model="journalChildren"
                    :items="journalChildrenset"
                      outlined
                      dense
                      item-value="id"
                      label="Дети из группы (подгруппы)"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.lastname + ' ' + item.raw.firstname + ' ' + item.raw.secname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.lastname + ' ' + item.raw.firstname + ' ' + item.raw.secname"
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
            </v-autocomplete>
            <div class="text-right">
              <v-btn @click="showChildForm=false" class="bordered-item ma-1">Отмена</v-btn>
              <v-btn @click="addChildToJournal" class="bordered-item ma-1">Добавить</v-btn>
            </div>
        </div>
      </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref, watch, defineProps } from "vue";
import { useVuelidate } from "@vuelidate/core";

import { required, maxLength, helpers } from "@vuelidate/validators";
import { useSubjectsJournalStore } from "@/store/subjects-journal";

import { usePopupStore } from "@/store/popup";
import { useRoute, useRouter } from "vue-router";
import { customAxios } from "@/service/customAxios";

import CustomForm from "@/components/CustomForm.vue";
import { debounce } from "@/util/common";
import { useAuthStore } from "@/store/auth";

//<!--add childs-->
const journalChildrenset = ref([]);
const journalChildren = ref('');
const showChildForm = ref(false);

async function getChildSet() {
  return customAxios.get("/teacher/log/children_for_log/" + route.query.filterByJournalId);
}
async function addChild() {
  return customAxios.post("/foreign_dictation/add_child", {
    listId: Number(route.query.listId),
    childId: journalChildren.value
  });
}
async function addChildToJournal() {
  try {
    const res =await addChild();
    console.log("res:" + res.data)
   
    showChildForm.value=false;
    journalChildren.value='';
    
  } catch (e) {
    showError(e);
    showChildForm.value=false;
    journalChildren.value='';
  }
}

async function getChilds() {
  try {
    const res =await getChildSet();
    console.log("res:" + res.data)
    journalChildrenset.value=res.data;
    if(journalChildrenset.value.length>0){
      journalChildren.value=journalChildrenset.value[0].id
    }
    showChildForm.value=true;
  } catch (e) {
    showError(e);
  }
}
//

const rules = {
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
};
const props = defineProps({ sheet: { type: Object, required: false } });

const { userId } = storeToRefs(useAuthStore());

const route = useRoute();
const router = useRouter();

const { journal, dateFromJournal } = storeToRefs(useSubjectsJournalStore());

const dateTitle = computed(() => dateFromJournal.value(route.query.dayId));

const name = ref("");
const isActive = ref(false);

watch(() => props.sheet, setCurrentData, { immediate: true });

function setCurrentData() {
  if (!props.sheet) {
    return;
  }

  name.value = props.sheet.dictationName;
  isActive.value = props.sheet.isActive;
}

const popupStore = usePopupStore();
const {
  showWarningPopup,
  showToastPopup,
  showError,
  showConfirmPopup,
  closePopup,
} = popupStore;

const v$ = useVuelidate(rules, {
  name: name,
});

const saveIsDisable = computed(() => {
  v$.value.$touch;
  return v$.value.$invalid;
});

async function create() {
  if (!route.query.dayId) {
    return;
  }
  try {
    await createHandler();
    showToastPopup("Диктант создан");
    back();
  } catch (e) {
    showError(e);
  }
}

async function createHandler() {
  return customAxios.post("/foreign_dictation/", {
    dictationName: name.value,
    scheduleClassId: journal.value.scheduleClassId,
    personGroupId: journal.value.personGroupId,
    isActive: isActive.value ? 1 : 0,
    teacherLogId: journal.value.id,
    teacherLogDayId: Number(route.query.dayId),
  });
}

async function remove() {
  if (!props.sheet.creatorId !== userId.value) {
    showWarningPopup("Только автор может удалить диктант");
    return;
  }

  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();
      await removeList();
      showToastPopup("диктант удален");
      back();
    } else {
      closePopup();
    }
  } catch (e) {
    showError(e);
  }
}

async function customConfirm() {
  return showConfirmPopup("Удалить диктант?");
}

async function removeList() {
  return customAxios.delete(`/foreign_dictation/${route.query.listId}`);
}

function back() {
  router.push({
    name: "dictations",
    query: {
      filterByJournalId: route.query.filterByJournalId,
      dayId: route.query.dayId,
    },
  });
}

watch(isActive, async (v) => {
  if (props.sheet && v !== props.sheet.isActive) {
    updateList();
  }
});

const debounceUpdate = debounce(updateList, 1000);

watch(name, async (v) => {
  if (props.sheet && v !== props.sheet.dictationName) {
    debounceUpdate();
  }
});

async function updateList() {
  if (!route.query.listId) {
    return;
  }
  try {
    await updateListHandler();
    showToastPopup("Успешно сохранено");
  } catch (e) {
    showError(e);
  }
}

async function updateListHandler() {
  return customAxios.put(`/foreign_dictation/${route.query.listId}`, {
    isActive: isActive.value,
    dictationName: name.value,
  });
}
</script>
