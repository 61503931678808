<template>
  <v-autocomplete
    v-if="categories.length"
    v-model="category"
    :items="categories"
    variant="outlined"
    placeholder="Категория достижения"
    item-value="id"
    item-title="name"
    density="compact"
    hide-details
    :clearable="true"
  ></v-autocomplete>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { useCognations } from "@/composables/cognations";
import { pushFilterToURL, removeFilterFromURL } from "@/service/router";
import { usePopupStore } from "@/store/popup";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

const { showError } = usePopupStore();
const { getCategoriesHandler } = useCognations();

const route = useRoute();

const categories = ref([]);
const category = ref(null);

watch(category, (id) =>
  id
    ? pushFilterToURL({ name: "filterByCategory", value: id })
    : removeFilterFromURL("filterByCategory")
);

watch(() => route.query.filterByCategory, setCategory);

function setCategory() {
  if (!categories.value.length || !route.query.filterByCategory) {
    return;
  }

  category.value =
    categories.value.findIndex(
      (v) => v.id === Number(route.query.filterByCategory)
    ) >= 0
      ? Number(route.query.filterByCategory)
      : null;
}

onMounted(async () => {
  await getCategories();
  setCategory();
});

async function getCategories() {
  try {
    const r = await getCategoriesHandler();
    categories.value = r.data;
  } catch (error) {
    showError(error);
  }
}
</script>
