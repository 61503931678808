<template>
  <tr>
    <td>{{ item.id }}</td>
    <td>{{ getDateFormatWithoutTime(item.consultationDate) }}</td>
    <td>{{ getTimeFromDate(item.consultationDate) }}</td>
    <td>
      <p v-if="item.parent">
        {{ item.parent.lastname }}
        {{ item.parent.firstname }}
        {{ item.parent.sectname }}
      </p>
      <p v-else>не указан</p>
    </td>
    <td>{{ item.consultationDuration }}</td>
    <td>
      <p>{{ consultationStatuses[item.parentConsultationStatus] }}</p>
    </td>
    <td class="w-100 cell-input">
      <div v-if="canEdit">
        <div v-if="item.parentConsultationStatus === 'CONSULTATION_BOOKED'">
          <AppButton
            @click="status = 'CONSULTATION_BOOKED_CONFIRMED'"
            class="bordered-item ma-1"
            >Подтвердить</AppButton
          >
          <AppButton
            @click="status = 'CONSULTATION_BOOKED_CONFIRMED'"
            class="bordered-item ma-1 bordered-item--danger"
            >Отклонить</AppButton
          >
        </div>

        <div
          v-else-if="
            item.parentConsultationStatus === 'CONSULTATION_BOOKED_CONFIRMED'
          "
        >
          <AppButton
            @click="status = 'CONSULTATION_COMPLETED'"
            class="bordered-item ma-2"
            >Завершить</AppButton
          >
        </div>

        <p v-else>Не доступна</p>
      </div>

      <p v-else>Нет доступа</p>
    </td>
    <td>
      <router-link
        v-if="item.parentConsultationStatus === 'CONSULTATION_COMPLETED'"
        :to="{
          name: 'employee-consultations-messages',
          params: { id: item.id },
        }"
        class="bordered-item"
        >Протоколы</router-link
      >
      <p v-else>Нет доступа</p>
    </td>
  </tr>
</template>

<script setup>
import { computed, defineProps, ref, watch } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { consultationStatuses } from "@/util/common";

import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import { useConsultationsData } from "@/composables/consultations";

import AppButton from "@/elements/AppButton.vue";

const props = defineProps({ item: { type: Object, required: true } });

const { getTimeFromDate, getDateFormatWithoutTime } = useDatesChanger();
const { mainRole, userId } = storeToRefs(useAuthStore());
const { changeStatus } = useConsultationsData();

const status = ref(props.item.parentConsultationStatus);

const canEdit = computed(() => {
  if (
    ["ROLE_ADMIN", "ROLE_TUTOR", "ROLE_TEACHER", "ROLE_MASTER"].indexOf(
      mainRole.value
    ) === -1
  ) {
    return false;
  }

  if (
    props.item.consultantId !== userId.value &&
    mainRole.value !== "ROLE_ADMIN"
  ) {
    return false;
  }

  return true;
});

watch(status, async (v) => {
  if (!canEdit.value) {
    return;
  }

  changeStatus({
    status: v,
    id: props.item.id,
  });
});
</script>
