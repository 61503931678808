<template>
  <div>
    <PageTitle title="Мои записи на консультации" />

    <div class="d-flex justify-center">
      <router-link
        :to="{ name: 'consultations-parent-booking' }"
        class="bordered-item"
        >Записаться на консультацию</router-link
      >
    </div>

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>

      <template v-slot:content>
        <p class="mb-3">Здесь отобразятся ваши записи на консультации.</p>

        <v-label> Обязательно выставьте фильтры</v-label>
        <ol class="pl-5">
          <li><strong>период</strong></li>
        </ol>
      </template>
    </TogglerOfWrapper>

    <div class="g-col g-col--2-1fr">
      <FilterByPeriod />
    </div>

    <StatusWrapper
      :isDataExisted="consultations.length > 0"
      :loadingStatus="loadingStatus"
    >
      <div class="table-wrapper my-3">
        <table class="table-custom">
          <thead>
            <tr class="table-custom__headers">
              <th>Дата и Время</th>
              <th>Консультант</th>
              <th>Статус</th>
              <th>Действия</th>
              <th>Протоколы</th>
            </tr>
          </thead>
          <tbody>
            <CRow
              v-for="(s, i) in consultations"
              :key="i"
              :item="s"
              @showMessages="showMessages"
            />
          </tbody>
        </table>
      </div>
    </StatusWrapper>

    <v-dialog v-model="visible">
      <v-card class="pa-2">
        <h3 class="text-center mb-3">Протокол консультации</h3>
        <ul v-if="messages.length">
          <li v-for="m in messages" :key="m.id">{{ m.protocolBody }}</li>
        </ul>
        <p v-else>Пусто</p>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import FilterByPeriod from "@/components/filtersElements/FilterByPeriod.vue";
import PageTitle from "@/elements/PageTitle.vue";

import CRow from "./CRow.vue";

import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { ref, watch } from "vue";

import { useRoute } from "vue-router";
import { useConsultationsData } from "@/composables/consultations";
import { useConsultationsStore } from "@/store/consultations";
import { storeToRefs } from "pinia";
import StatusWrapper from "@/components/StatusWrapper.vue";

const { consultations, loadingStatus } = storeToRefs(useConsultationsStore());
const { getMyBookings } = useConsultationsData();
const { showError } = usePopupStore();
const route = useRoute();

watch(() => route.query.startDate, getMyBookings, { immediate: true });
watch(() => route.query.endDate, getMyBookings, { immediate: true });

const messages = ref([]);
const visible = ref(false);

async function showMessages(id) {
  try {
    const r = await showMessagesHandler(id);
    messages.value = r.data;
    visible.value = true;
  } catch (error) {
    showError(error);
  }
}

async function showMessagesHandler(id) {
  return customAxios.get(
    `/parent_consultation/consultant/protocol/get_protocols_by_consultation_id/${id}`
  );
}
</script>
