<template>
  <LoaderRing v-if="loadingStatus === 'loading'" />
  <router-view v-else />
</template>

<script setup>
import { usePopupStore } from "@/store/popup";
import { useCognations } from "@/composables/cognations";
import { useCognationsStore } from "@/store/cognations";

import { onMounted, ref } from "vue";

import LoaderRing from "@/elements/LoaderRing.vue";

const { showError } = usePopupStore();
const { getCognationsHandler } = useCognations();
const { setCognations } = useCognationsStore();

const loadingStatus = ref("nothing");

onMounted(getCognations);

async function getCognations() {
  loadingStatus.value = "loading";

  try {
    const r = await getCognationsHandler();
    setCognations(r.data);
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}
</script>
