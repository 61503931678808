<template>
  <div>
    <PageTitle title="Отчёт: Запись на мастерские" />

    <div class="g-col g-col--2-1fr">
      <FilterByMaster />
      <FilterByPeriod />
    </div>

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь отобразится отчёт.</p>

        <v-label> Выставьте фильтры</v-label>
        <ol class="pl-5">
          <li><strong>мастер</strong></li>
          <li><strong>четверть</strong></li>
        </ol>
      </template>
    </TogglerOfWrapper>

    <StatusWrapper :loadingStatus="loading" :isDataExisted="reports.length > 0">
      <div class="d-flex justify-end my-3">
        <AppButton class="accent-button" @click="download"
          >Скачать отчёт</AppButton
        >
      </div>

      <div class="page-wrapper">
        <div class="table-wrapper">
          <table id="workshop-booking-table" class="table-static">
            <thead>
              <tr>
                <th>Мастерская</th>
                <th>Дети</th>
                <th>Кол-во</th>
              </tr>
            </thead>

            <tbody v-for="w in reports" :key="w.id">
              <tr>
                <td>{{ w.workshopName }}</td>
                <td>
                  <ol class="pl-5">
                    <li
                      v-for="c in sortByLastName(w.workshopChildrens)"
                      :key="c.id"
                    >
                      {{ c.lastname }} {{ c.firstname }}
                    </li>
                  </ol>
                </td>
                <td class="text-right">
                  {{ w.workshopChildrens.length }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { ref, watch } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

import { usePopupStore } from "@/store/popup";
import { sortByLastName } from "@/util/common";
import { useUserData } from "@/composables/userData";

import { useRoute } from "vue-router";

import AppButton from "@/elements/AppButton.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";
import FilterByMaster from "@/components/filtersElements/FilterByMaster.vue";

import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import PageTitle from "@/elements/PageTitle.vue";
import FilterByPeriod from "@/components/filtersElements/FilterByPeriod.vue";

const { downloadExcel } = useUserData();
const { showError } = usePopupStore();
const { getDateFormatWithoutTime } = useDatesChanger();
const route = useRoute();

const loading = ref("nothing");
const reports = ref([]);

watch(() => route.query.filterByMaster, getData, { immediate: true });
watch(() => route.query.startDate, getData, { immediate: true });
watch(() => route.query.endDate, getData, { immediate: true });

async function getData() {
  if (
    !route.query.filterByMaster ||
    !route.query.startDate ||
    !route.query.endDate
  ) {
    return;
  }
  loading.value = "loading";

  try {
    const r = await getReports();
    reports.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getReports() {
  return await customAxios.post("/workshop/master_report", {
    masterId: Number(route.query.filterByMaster),
    beginPeriodDateTime: route.query.startDate + "T00:00:00.000Z",
    endPeriodDateTime: route.query.endDate + "T00:00:00.000Z",
  });
}

function download() {
  const title =
    "отчёт_мастер_" +
    getDateFormatWithoutTime(route.query.startDate) +
    "-" +
    getDateFormatWithoutTime(route.query.endDate);

  downloadExcel(title, "workshop-booking-table");
}
</script>
