<template>
  <div>
    <PageTitle title="Администрирование консультаций" />

    <div class="d-flex justify-center flex-wrap">
      <router-link
        :to="{ name: 'consultation-create' }"
        class="bordered-item ma-1"
        >Создать одиночный слот</router-link
      >
      <router-link
        :to="{ name: 'consultation-multiple-create' }"
        class="bordered-item ma-1"
        >Создать несколько слотов</router-link
      >
    </div>

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь появится список созданных консультаций</p>
        <v-label>Выставьте:</v-label>
        <ol class="pl-5">
          <li>Период</li>
        </ol>
      </template>
    </TogglerOfWrapper>

    <div class="g-col g-col--2-1fr mb-3">
      <FilterByPeriod />
    </div>

    <StatusWrapper
      :is-data-existed="consultations.length > 0"
      :loading-status="loadingStatus"
    >
      <CConsultationsTable />
    </StatusWrapper>
  </div>
</template>

<script setup>
import { useConsultationsStore } from "@/store/consultations";
import { storeToRefs } from "pinia";

import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import FilterByPeriod from "@/components/filtersElements/FilterByPeriod.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";

import PageTitle from "@/elements/PageTitle.vue";
import CConsultationsTable from "./CConsultationsTable.vue";

const { consultations, loadingStatus } = storeToRefs(useConsultationsStore());
</script>
