<template>
  <div>
    <PageTitle title="Расписание" />

    <div class="page-wrapper mb-3">
      <div class="g-col g-col--3-1fr">
        <FilterByChild v-if="isParent" />
        <FilterByEventType />
        <WeekRangeFilter v-model="range" />
      </div>
    </div>

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь отобразится расписание.</p>
        <p>
          Выставьте: <strong>неделю</strong>, <strong>событие</strong
          ><span v-if="isParent">&nbsp;и<strong> ребёнка</strong></span
          >.
        </p>
      </template>
    </TogglerOfWrapper>

    <StatusWrapper
      :loadingStatus="loadingStatus"
      :isDataExisted="weekSchedule.length > 0"
    >
      <div class="page-wrapper">
        <TimeTable :headers="daysHeaders" :weekSchedule="weekSchedule" />
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useAuthStore } from "@/store/auth";
import { useRoute } from "vue-router";

import { useChildSchedueData } from "@/composables/childScheduleData";
import { usePopupStore } from "@/store/popup";
import { useDatesChanger } from "@/composables/datesChanger";

import PageTitle from "@/elements/PageTitle.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";
import TimeTable from "@/components/TimeTable.vue";

import WeekRangeFilter from "@/components/WeekRangeFilter";

import FilterByChild from "@/components/filtersElements/FilterByChild.vue";
import FilterByEventType from "@/components/filtersElements/FilterByEventType.vue";
import { pushFilterToURL } from "@/service/router";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";

const { showError } = usePopupStore();
const { getCurrentWeek } = useDatesChanger();

const {
  weekSchedule,
  daysHeaders,
  getChildCurrentWeekEvents,
  getChildEventsByRange,
} = useChildSchedueData();

const { isParent, isChild, userId } = useAuthStore();
const route = useRoute();
const range = ref(getCurrentWeek());
const loadingStatus = ref("nothing");

onMounted(async () => {
  if (isChild) {
    pushFilterToURL({ name: "filterByChildId", value: userId });
  }
});
watch(range, updateTable, { immediate: true });
watch(() => route.query.filterByChildId, updateTable, { immediate: true });
watch(() => route.query.filterByEventType, updateTable, { immediate: true });

async function updateTable() {
  if (!route.query.filterByChildId || !route.query.filterByEventType) {
    return;
  }

  loadingStatus.value = "loading";

  try {
    if (range.value.length) {
      await getChildEventsByRange({
        classTypes: Number(route.query.filterByEventType),
        childId: Number(route.query.filterByChildId),
        range: range.value,
      });
    } else {
      await getChildCurrentWeekEvents({
        classTypes: Number(route.query.filterByEventType),
        childId: Number(route.query.filterByChildId),
      });
    }
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}
</script>
