import { employes } from "./employes-links";

export const admin = [
  { title: "Админ", type: "title" },
  {
    type: "link",
    title: "Консультации",
    icon: "chat-icon",
    name: "admin-consultations-list",
  },
  {
    type: "list",
    title: "Пользователи",
    icon: "users-icon",
    links: [
      {
        type: "link",
        title: "Пользователи",
        icon: "circle-icon",
        name: "users",
      },
      {
        type: "link",
        title: "Группы пользователей",
        icon: "circle-icon",
        name: "user_group",
      },
      {
        type: "link",
        title: "Дети и родители",
        icon: "circle-icon",
        name: "child",
      },
    ],
  },

  {
    type: "list",
    title: "Расписание",
    icon: "table-icon",
    name: "admin-schedule",
    links: [
      {
        type: "link",
        title: "События",
        icon: "circle-icon",
        name: "events",
      },
      {
        type: "link",
        title: "Расписания",
        icon: "circle-icon",
        name: "schedules",
      },
      {
        type: "link",
        title: "Периоды",
        icon: "circle-icon",
        name: "study_years",
      },
      {
        type: "link",
        title: "Помещения",
        icon: "circle-icon",
        name: "location",
      },
      {
        type: "link",
        title: "Оснащение",
        icon: "circle-icon",
        name: "location_option",
      },
      {
        type: "link",
        title: "Занятия",
        icon: "circle-icon",
        name: "class",
      },
      {
        type: "link",
        title: "Типы занятий",
        icon: "circle-icon",
        name: "class_types",
      },
      {
        type: "link",
        title: "Заявки",
        icon: "circle-icon",
        name: "ticket",
      },
    ],
  },

  {
    type: "link",
    title: "Чеклисты",
    icon: "file-icon",
    name: "checklists",
  },

  {
    type: "link",
    title: "Компетенции",
    icon: "fire-icon",
    name: "сompetence",
  },

  {
    type: "link",
    title: "Метрики",
    icon: "chart-pie",
    name: "child-metrics-admin",
  },

  {
    type: "link",
    title: "Достижения",
    icon: "star-icon",
    name: "admin-cognation",
  },

  {
    type: "list",
    title: "Редакторы медиа",
    icon: "screwdriver-icon",
    links: [
      {
        type: "link",
        title: "Альбомы",
        icon: "photo-icon",
        name: "admin-albums",
      },
      {
        type: "link",
        title: "Новости",
        icon: "circle-icon",
        name: "admin-news",
      },
    ],
  },
  { title: "Сотрудник", type: "title" },
  ...employes
];
