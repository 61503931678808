<template>
  <div>
    <PageTitle title="Галерея" />
    <StatusWrapper :loading-status="loading" :is-data-existed="albums.length">
      <router-view></router-view>
    </StatusWrapper>
    <PhotosEmpty v-if="albums.length === 0" />
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { computed, onMounted } from "vue";

import { useGalleryStore } from "@/store/gallery";
import { watch } from "vue";
import { useRoute } from "vue-router";

import { ref } from "vue";

import PageTitle from "@/elements/PageTitle.vue";
import { useAuthStore } from "@/store/auth";
import StatusWrapper from "@/components/StatusWrapper.vue";
import { storeToRefs } from "pinia";
import PhotosEmpty from "@/elements/PhotosEmpty.vue";

const { isChild, isParent } = useAuthStore();
const route = useRoute();
const loading = ref("loading");
const { setAlbumbs, setPhotos } = useGalleryStore();
const { albums } = storeToRefs(useGalleryStore());
const { showError } = usePopupStore();

onMounted(getAlbums);
const isUser = computed(() => isChild || isParent);

async function getAlbums() {
  try {
    loading.value = "loading";

    if (isUser.value) {
      const r = await getMyAlbumsHandler();
      setAlbumbs(r.data);

      if (route.params.albumId) {
        const currentPhotos = r.data.find(
          (v) => v.id === Number(route.params.albumId)
        ).albumPhotos;
        setPhotos(currentPhotos);
      }
    } else {
      const r = await getAlbumsHandler();
      setAlbumbs(r.data);
    }
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getAlbumsHandler() {
  return customAxios.get("/album");
}

async function getMyAlbumsHandler() {
  return customAxios.get("/album/my");
}

onMounted(async () => {
  if (route.params.albumId) {
    isUser.value ? getAlbums() : getAlbumPhotos();
  }
});

watch(
  () => route.params.albumId,
  async (albumId) => {
    if (albumId) {
      isUser.value ? getAlbums() : getAlbumPhotos();
    }
  }
);

async function getAlbumPhotos() {
  try {
    loading.value = "loading";
    const r = await getAlbumPhotosHandler();
    setPhotos(r.data);
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getAlbumPhotosHandler() {
  return customAxios.get("/album/photo/" + route.params.albumId);
}
</script>
