<template>
  <v-autocomplete
    v-if="employeesSelector.length"
    :items="employeesSelector"
    variant="outlined"
    placeholder="Сотрудник"
    density="compact"
    hide-details
    item-value="value"
    bg-color="white"
    @update:model-value="$emit('update:modelValue', $event)"
    :modelValue="modelValue"
  ></v-autocomplete>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { useEmployeeData } from "@/composables/employeeData";
import { onMounted, defineProps, defineEmits } from "vue";

defineProps(["modelValue"]);
defineEmits(["update:modelValue"]);

const { getEmployees, employeesSelector } = useEmployeeData();

onMounted(getEmployees);
</script>
