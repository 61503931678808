import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";
import { useConsultationsStore } from "@/store/consultations";
import { useRoute } from "vue-router";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/auth";
import { setCurrentMonth } from "@/util/common";

export function useConsultationsData() {
  const { showError, showToastPopup } = usePopupStore();
  const { setConsultations, setLoadingStatus } = useConsultationsStore();
  const { mainRole } = storeToRefs(useAuthStore());

  const route = useRoute();

  const statuses = computed(() => {
    const common = [
      { title: "Принята", value: "CONSULTATION_BOOKED_CONFIRMED" },
      { title: "Отклонена", value: "CONSULTATION_BOOKED_REJECTED" },
      { title: "Завершена", value: "CONSULTATION_COMPLETED" },
    ];

    const forAdmin = [
      { title: "Создана", value: "CONSULTATION_CREATED" },
      { title: "Забронирована", value: "CONSULTATION_BOOKED" },
      { title: "Протокол добавлен", value: "CONSULTATION_PROTOCOL_CREATED" },
    ];

    return mainRole.value === "ROLE_ADMIN" ? [...common, ...forAdmin] : common;
  });

  function displayPopup(v) {
    switch (statuses[v]) {
      case "CONSULTATION_BOOKED_CONFIRMED":
        showToastPopup("Заявка подтверждена");
        break;
      case "CONSULTATION_BOOKED_REJECTED":
        showToastPopup("Заявка отклонена");
        break;
      case "CONSULTATION_COMPLETED":
        showToastPopup("Консультация завершена");
        break;
      default:
        showToastPopup("Статус изменён");
    }
  }

  async function getConsultationsForEmployee() {
    if (!route.query.startDate || !route.query.endDate) {
      return;
    }

    try {
      const r = await getConsultationsForEmployeeHandler();
      setConsultations(r.data);
    } catch (error) {
      showError(error);
    }
  }

  function getConsultationsForEmployeeHandler() {
    return customAxios.post(
      `/parent_consultation/consultant/get_slots_on_period`,
      {
        beginDate: route.query.startDate + "T00:00:00.000Z",
        endDate: route.query.endDate + "T00:00:00.000Z",
      }
    );
  }

  async function getMyNewBookings() {
    const start = new Date();
    const end = new Date(start);

    end.setDate(end.getDate() + 60);

    try {
      const r = await getMyBookingsHandler({
        beginDate: start,
        endDate: end,
      });
      setConsultations(r.data);
    } catch (error) {
      showError(error);
    }
  }

  async function getMyBookings() {
    if (!route.query.startDate || !route.query.endDate) {
      return;
    }
    setLoadingStatus("loading");

    try {
      const r = await getMyBookingsHandler({
        beginDate: route.query.startDate + "T00:00:00.000Z",
        endDate: route.query.endDate + "T00:00:00.000Z",
      });

      setConsultations(r.data);
    } catch (error) {
      showError(error);
    } finally {
      setLoadingStatus("loaded");
    }
  }

  async function getMyBookingsHandler(options) {
    return customAxios.post(
      "/parent_consultation/parent/get_parent_slots_on_period",
      options
    );
  }

  async function changeStatus(data) {
    try {
      await changeStatusHandler(data);
      displayPopup(data);
      getConsultationsForEmployee();
    } catch (error) {
      showError(error);
    }
  }

  async function changeStatusHandler(data) {
    return customAxios.post(
      `/parent_consultation/consultant/consider_slot_book/${data.id}`,
      {
        parentConsultationStatus: data.status,
      }
    );
  }

  async function getSlotsByEmployee() {
    if (!route.query.filterByEmployee) {
      return;
    }

    try {
      const r = await getSlotsByEmployeeHandler();
      setConsultations(r.data);
    } catch (error) {
      showError(error);
    }
  }

  function getSlotsByEmployeeHandler() {
    const period = setCurrentMonth();

    return customAxios.post(
      `/parent_consultation/parent/get_slots_on_period_by_employee/${route.query.filterByEmployee}`,
      {
        beginDate: period[0],
        endDate: period[1],
      }
    );
  }

  return {
    getSlotsByEmployee,
    getConsultationsForEmployee,
    getMyBookings,
    getMyNewBookings,
    statuses,
    displayPopup,
    changeStatus,
  };
}
