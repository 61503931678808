<template>
  kjkk
  <div v-if="currentChild" class="wrapper">
    <div class="page-wrapper">
      <h3 class="mb-4">
        Профиль ученика: {{ currentChild.firstname }}
        {{ currentChild.lastname }} {{ currentChild.secname }}
      </h3>

      <AvatarWithCropper
        :avatar="currentChild.personAvatar"
        :id="currentChild.id"
      />
    </div>

    <div class="page-wrapper mt-4">
      <AppButton class="gradient-button" @click="startSwotCreating">
        Создать SWOT
      </AppButton>
      <h4 class="my-4 text-center">SWOT анализы ученика:</h4>

      <StatusWrapper
        :loadingStatus="loadingStatus"
        :isDataExisted="currentSwots.length > 0"
      >
        <div class="table-default">
          <table>
            <thead>
              <th>ID</th>
              <th>Название</th>
              <th colspan="2"></th>
            </thead>
            <tbody>
              <TableRowActions
                v-for="cs in currentSwots"
                :key="cs.id"
                :id="cs.id"
                :item="cs"
                @editItem="startSwotEditing"
                @removeItem="removeSwot"
              >
                <td>{{ cs.id }}</td>
                <td class="text-left w-100">
                  Анализ за
                  {{ getDateFormatWithoutTime(cs.createDateTime) }}
                </td>
                <td class="table-default__btns-cell">
                  <AppButton @click="openObjects(cs)" class="bordered-item">
                    Предметы
                  </AppButton>
                  <AppButton @click="openNotes(cs)" class="bordered-item">
                    Заметки
                  </AppButton>
                </td>
              </TableRowActions>
            </tbody>
          </table>
        </div>
      </StatusWrapper>
    </div>

    <v-dialog v-model="isObjectsShowed" scrollable persistent>
      <SwotObjects
        @saveObject="saveObject"
        @close="isObjectsShowed = false"
        @removeObject="removeObject"
        @editObject="editObject"
      />
    </v-dialog>

    <v-dialog v-model="isEditorShowed" scrollable persistent>
      <SwotEditor @close="isEditorShowed = false" @saveSwot="saveSwot" />
    </v-dialog>

    <v-dialog v-model="isNotesShowed" scrollable persistent>
      <SwotNotes
        @close="isNotesShowed = false"
        @saveNote="saveNote"
        @editNote="editNote"
        @removeNote="removeNote"
      />
    </v-dialog>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { ref, onMounted, onUnmounted } from "vue";
import { customAxios } from "@/service/customAxios";

import { useTutorStore } from "@/store/tutor";
import { storeToRefs } from "pinia";

import AppButton from "@/elements/AppButton.vue";
import SwotEditor from "./SwotEditor.vue";
import SwotNotes from "./SwotNotes.vue";

import SwotObjects from "./SwotObjects.vue";
import TableRowActions from "@/components/TableRowActions.vue";

import StatusWrapper from "../StatusWrapper.vue";
import { useDatesChanger } from "@/composables/datesChanger";
import AvatarWithCropper from "@/components/AvatarWithCropper.vue";
export default {
  emits: ["close"],
  setup() {
    const tutorStore = useTutorStore();
    const {
      setCurrentChild,
      clearCurrentChild,
      setCurrentSwot,
      clearCurrentSwot,
    } = tutorStore;

    const { getDateFormatWithoutTime } = useDatesChanger();
    const { currentChild, currentSwot } = storeToRefs(tutorStore);

    const route = useRoute();
    const isClassLevelsEditing = ref(true);
    const tab = ref(1);

    const loadingStatus = ref("loading");
    const isEditorShowed = ref(false);
    const currentSwots = ref([]);

    const isNotesShowed = ref(false);
    const isObjectsShowed = ref(false);

    // const commonOptions = computed(() => {
    //   if (!currentChild.value) {
    //     return null;
    //   }
    //   return {
    //     child: currentChild.value,
    //     childId: currentChild.value.id,
    //   };
    // });

    function startSwotCreating() {
      clearCurrentSwot();
      isEditorShowed.value = true;
    }

    async function saveSwot(options) {
      console.log(options);
      try {
        if (currentSwot.value) {
          await customAxios.put(`/tutor/swot/${currentSwot.value.id}`, {
            ...options,
            // ...commonOptions.value,
            tutorSwotAnalysisId: currentSwot.value.id,
          });
        } else {
          await customAxios.post(`/tutor/swot`, {
            ...options,
            // ...commonOptions.value,
            createDateTime: new Date(),
            analysisDateTime: new Date(),
            childId: currentChild.value.id,
          });
          clearCurrentSwot();
        }

        isEditorShowed.value = false;
        await getSwotsById();
      } catch (e) {
        console.log(e);
      }
    }


    async function getChild() {
      console.log("childId:" + route.params.id)
      try {
        const r = await customAxios.get(`/user/${route.params.id}`);
        setCurrentChild(r.data);
      } catch (e) {
        console.log(e);
      }
    }

    async function getSwotsById() {
      loadingStatus.value = "loading";
      try {
        const r = await customAxios.get(
          `/tutor/swot/by_child/${route.params.id}`
        );
        currentSwots.value = r.data;
        loadingStatus.value = "loaded";
      } catch (e) {
        console.log(e);
      }
    }

    function startSwotEditing(swot) {
      setCurrentSwot(swot);
      isEditorShowed.value = true;
    }

    async function removeSwot(id) {
      try {
        if (confirm("Вы точно хотите удалить запись?")) {
          await customAxios.delete(`/tutor/swot/${id}`);
          await getSwotsById();
          alert("Анализ удален");
        }
      } catch (e) {
        console.log(e);
      }
    }

    function openNotes(swot) {
      setCurrentSwot(swot);
      isNotesShowed.value = true;
    }

    function openObjects(swot) {
      setCurrentSwot(swot);
      isObjectsShowed.value = true;
    }

    async function removeNote(id) {
      try {
        await customAxios.delete(`/tutor/swot/note/${id}`);
        isNotesShowed.value = false;
        alert("Успешно удалено");
        await getSwotsById();
      } catch (e) {
        alert("Что-то пошло не так");
      }
    }

    async function editNote(data) {
      try {
        await customAxios.put(`/tutor/swot/note/${data.id}`, {
          ...data.options,
          // ...commonOptions.value,
        });
        isNotesShowed.value = false;
        alert("Успешно изменено");
        await getSwotsById();
      } catch (e) {
        alert("Что-то пошло не так");
      }
    }

    async function saveNote(data) {
      try {
        await customAxios.post(`/tutor/swot/note`, {
          ...data,
          // ...commonOptions.value,
          tutorSwotAnalysisId: currentSwot.value.id,
        });
        alert("Заметка добавлена");
        isNotesShowed.value = false;
        await getSwotsById();
      } catch (e) {
        alert("Что-то пошло не так");
      }
    }

    async function saveObject(data) {
      try {
        await customAxios.post(`/tutor/swot/level`, {
          ...data,
          // ...commonOptions.value,
          tutorSwotAnalysisId: currentSwot.value.id,
        });
        alert("Предмет добавлен");
        isObjectsShowed.value = false;
        await getSwotsById();
      } catch (e) {
        alert("Что-то пошло не так");
      }
    }

    async function removeObject(id) {
      try {
        await customAxios.delete(`/tutor/swot/level/${id}`);
        isObjectsShowed.value = false;
        alert("Успешно удалено");
        await getSwotsById();
      } catch (e) {
        alert("Что-то пошло не так");
      }
    }

    async function editObject(data) {
      try {
        await customAxios.put(`/tutor/swot/level/${data.id}`, {
          ...data.options,
          // ...commonOptions.value,
          tutorSwotAnalysisId: currentSwot.value.id,
        });
        isObjectsShowed.value = false;
        alert("Успешно изменено");
        await getSwotsById();
      } catch (e) {
        alert("Что-то пошло не так");
      }
    }

    onMounted(getChild);
    onMounted(getSwotsById);
    onUnmounted(clearCurrentChild);

    return {
      editObject,
      removeObject,
      removeNote,
      editNote,
      openNotes,
      openObjects,
      isObjectsShowed,
      saveNote,
      startSwotEditing,
      getDateFormatWithoutTime,
      currentChild,
      currentSwots,
      isEditorShowed,
      loadingStatus,
      tab,
      isClassLevelsEditing,
      saveSwot,
      removeSwot,
      isNotesShowed,
      saveObject,
      startSwotCreating,
    };
  },
  components: {
    AppButton,
    SwotEditor,
    AvatarWithCropper,
    TableRowActions,
    SwotNotes,
    SwotObjects,
    StatusWrapper,
  },
};
</script>
