<template>
  <div>
    <AppButton @click="$router.back"  class="bordered-item mb-3">Назад</AppButton>
    <PageTitle
      :title="`Все SWOT анализы ${
        child !== null
          ? `для ${child.lastname} ${child.firstname}`
          : ''
      }`"
    />
    <div v-if="childSwots.length > 0">
      <TogglerOfWrapper>
        <template v-slot:name>Подсказка</template>
        <template v-slot:content>
          <p class="my-2">Это список анализов ребёнка</p>
          <p>Для того, чтобы перейти к определенной копии:</p>
          <ol class="pl-4">
            <li>
              Нажмите на <strong>Показать копии анализа</strong> у нужного вам
              SWOT-а
            </li>
            <li>Нажмите на <strong>элемент из списка</strong></li>
          </ol>
        </template>
      </TogglerOfWrapper>

      <SListPreview v-for="s in childSwots" :item="s" :key="s.id" class="my-3" />
    </div>
    <NothingFound v-else />
  </div>
</template>

<script setup>


import SListPreview from "./SListPreview.vue";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import NothingFound from "@/elements/NothingFound.vue";
import { ref, onMounted } from "vue";
import { customAxios } from "@/service/customAxios";
import { useRoute } from "vue-router";
const route = useRoute();
import PageTitle from "@/elements/PageTitle.vue";
import AppButton from "@/elements/AppButton.vue";

onMounted(async () => {
  await getChildSwots();
});

async function getChildSwotsHandler(){
  return customAxios.get("/tutor/swot/by_child/" + route.params.childId);
}

const child = ref({});
const childSwots = ref([]);


async function getChildSwots() {
  const r = await getChildSwotsHandler();
  childSwots.value = r.data;
  childSwots.value.map(sw =>{
    if(sw.child){
      child.value = sw.child;
      return
    }
  })
}

</script>
