<template>
  <div>
    <AppButton @click="$router.back"  class="bordered-item mb-3">Назад</AppButton>
   
    <PageTitle :title="`SWOT ${selectedSwot && selectedSwot.child ? `для ${selectedSwot.child.lastname} ${selectedSwot.child.firstname}`: ''}`" />
    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <div class="my-3">
          <label>Создание анализа:</label>
          <ol class="pl-5">
            <li>
              Если у ребёнка уже <strong>есть</strong> SWOT анализ - открывается текущая актуальная версия. При нажатии на кнопку "Создать новый" - создастся
              <strong>дополнительный</strong> на основе открытого, при этом все значения полей переносятся в создаваемый анализ.
            </li>
            <li>
              Если у ребёнка <strong>не было </strong> SWOT анализа -
              при открытии этого окна создается
              <strong>пустой новый</strong>.
            </li>
          </ol>

    
        </div>
        <div class="my-3">
          <label>Редактирование анализа:</label>
          <ol class="pl-5">
            <li>
              <strong>Описание анализа</strong> - сохраняется автоматически
              после редактирования
            </li>
            <li>
              <strong>Ярлыки разделов</strong> (Сильные стороны/Слабые
              стороны...) - сохраняются по нажатию на кнопку Сохранить в
              модальном окне. Справочник ярлыков можно отредактировать, нажав на кнопку "Справочник ярлыков"
            </li>
          </ol>
        </div>
        <div class="my-3">
          <label>Удаление анализа:</label>
          <ol class="pl-5">
            <li>
              Кнопка <strong>Удалить анализ</strong> - внизу страницы (под
              анализом). После удаления будет создан пустой актуальный SWOT анализ
            </li>
          </ol>
        </div>
      </template>
    </TogglerOfWrapper>
    <div class="d-flex justify-end mb-2">
      <div class="ma-1">
        <router-link
        :to="{name: 'child-swot-tags'}"
        class="bordered-item"
        >Справочник ярлыков</router-link
      >
      </div>
      <div class="ma-1">
        <router-link
        :to="{
          name: 'child-swots',
          params: { childId: $route.params.childId },
        }"
        class="bordered-item"
        >Список анализов</router-link
      >
      </div>
      
   
      
    </div>

    <div class="s-report">
      <div class="s-report__tags my-3">
        <STag
          :tag-id="1"
          :tagName="'Strengths'"
          :tagPoints="strengthsTagSet"
          @show-points="editStrengths()"
          
        />
        <STag
         :tag-id="2"
          :tagName="'Weaknesses'"
          :tagPoints="weaknessesTagSet"
          @show-points="editWeaknesses()"
        />
        <STag
        :tag-id="3"
          :tagName="'Opportunities'"
          :tagPoints="opportunitiesTagSet"
          @show-points="editOpportunities()"
        />
        <STag
        :tag-id="4"
          :tagName="'Threats'"
          :tagPoints="threatsTagSet"
          @show-points="editThreats()"
        />
      </div>

      <div class="page-wrapper my-3">
        <h4>Виден родителям</h4>
        <v-switch
          v-model="showForParent"
          :label="`${showForParent ? ` Да` : `Нет`}`"
          color="success"
          hide-details
        />
      </div>

      <div class="page-wrapper my-3">
        <h4>Описание анализа</h4>
        <v-textarea
          v-model="noteBody"
          maxlength="2550"
          variant="outlined"
          counter="2550"
          hide-details
        ></v-textarea>
      </div>

      <div class="page-wrapper my-3" v-if="childSwots!=undefined && selectedSwot!=undefined">
        <h4>Информация об анализе</h4>
        <SPreview :item="selectedSwot"  />
      </div>

      <div class="d-flex justify-center flex-wrap mt-5">
        <AppButton 
          @click="create" 
          class="bordered-item ma-1"
          >Создать новый анализ
        </AppButton>
        <AppButton 
          @click="saveSwot" 
          class="bordered-item ma-1"
          >Сохранить
        </AppButton>
        <AppButton
          @click="removeSwot"
          class="bordered-item bordered-item--danger ma-1"
          >Удалить анализ
        </AppButton>
      </div>
    </div>

    <v-dialog v-model="strengthsModalShow" persistent scrollable>
      <div class="page-wrapper my-3">
        <div class="ma-1">
            <h4>STRENGTHS TAGS:</h4>
            <v-autocomplete
                v-model="strengthsTagSet"
                :items="strengthsTagSourceSet"
                :filter="tagFilter"
                variant="outlined"
                placeholder="Тип"
                return-object=""
                item-title="name"
                density="compact"
                hide-detail
                small-chips
                closable-chips

                multiple
            > <template v-slot:item="{ props, item }">
                <v-list-item
                    v-bind="props"
                    :text="item.raw.name"
                    :subtitle="item.raw.section.sectionName"
                ></v-list-item>
              </template>
              <template v-slot:chip="{ props, item }">
                <v-chip
                    v-bind="props"
                     :text="item.raw.name"
                ></v-chip>
              </template>
          </v-autocomplete>
        </div>
        <div class="d-flex justify-center flex-wrap mt-5">
          <AppButton
          @click="strengthsModalShow=false"
           v-on:click="saveSwot"
          class="bordered-item bordered-item--danger ma-1"
          >Закрыть
        </AppButton>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="weaknessesModalShow" persistent scrollable>
      <div class="page-wrapper my-3">
        <div class="ma-1">
            <h4>WEAKNESSES TAGS:</h4>
            <v-autocomplete
                v-model="weaknessesTagSet"
                :items="weaknessesTagSourceSet"
                :filter="tagFilter"
                variant="outlined"
                placeholder="Тип"
                return-object=""
                item-title="name"
                density="compact"
                hide-detail
                multiple
                small-chips
                closable-chips
            >
            <template v-slot:item="{ props, item }">
                <v-list-item
                    v-bind="props"
                    :text="item.raw.name"
                    :subtitle="item.raw.section.sectionName"
                ></v-list-item>
              </template>
              <template v-slot:chip="{ props, item }">
                <v-chip
                    v-bind="props"
                     :text="item.raw.name"
                ></v-chip>
              </template>
          </v-autocomplete>
        </div>
        <div class="d-flex justify-center flex-wrap mt-5">
          <AppButton
          @click="weaknessesModalShow=false"
          v-on:click="saveSwot"
          class="bordered-item bordered-item--danger ma-1"
          >Закрыть
        </AppButton>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="opportunitiesModalShow" persistent scrollable>
      <div class="page-wrapper my-3">
        <div class="ma-1">
            <h4>OPPORTUNITIES TAGS:</h4>
            <v-autocomplete
                v-model="opportunitiesTagSet"
                :items="opportunitiesTagSourceSet"
                :filter="tagFilter"
                variant="outlined"
                placeholder="Тип"
                return-object=""
                item-title="name"
                density="compact"
                hide-detail
                multiple
                small-chips
                closable-chips
            >
            <template v-slot:item="{ props, item }">
                <v-list-item
                    v-bind="props"
                    :text="item.raw.name"
                    :subtitle="item.raw.section.sectionName"
                ></v-list-item>
              </template>
              <template v-slot:chip="{ props, item }">
                <v-chip
                    v-bind="props"
                     :text="item.raw.name"
                ></v-chip>
              </template>
          </v-autocomplete>
        </div>
        <div class="d-flex justify-center flex-wrap mt-5">
          <AppButton
          @click="opportunitiesModalShow=false"
          v-on:click="saveSwot"
          class="bordered-item bordered-item--danger ma-1"
          >Закрыть
        </AppButton>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="threatsModalShow" persistent scrollable>
      <div class="page-wrapper my-3">
        <div class="ma-1">
            <h4>THREATS TAGS:</h4>
            <v-autocomplete
                v-model="threatsTagSet"
                :items="threatsTagSourceSet"
                :filter="tagFilter"
                variant="outlined"
                placeholder="Тип"
                return-object=""
                item-title="name"
                density="compact"
                hide-detail
                multiple
                small-chips
                closable-chips
            >
            <template v-slot:item="{ props, item }">
                <v-list-item
                    v-bind="props"
                    :text="item.raw.name"
                    :subtitle="item.raw.section.sectionName"
                ></v-list-item>
              </template>
          </v-autocomplete>
        </div>
        <div class="d-flex justify-center flex-wrap mt-5">
          <AppButton
          @click="threatsModalShow=false"
          v-on:click="saveSwot"
          class="bordered-item bordered-item--danger ma-1"
          >Закрыть
        </AppButton>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";
import { useRoute } from "vue-router";
import AppButton from "@/elements/AppButton.vue";
import STag from "./STag.vue";

import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import SPreview from "./SPreview.vue";
import PageTitle from "@/elements/PageTitle.vue";
import { id } from "date-fns/locale";



const selectedSwot = ref({});
const childSwots = ref([]);
const showForParent = ref(false);
const strengthsTagSet = ref([]);
const strengthsModalShow = ref(false);
const strengthsTagSourceSet = ref([]);
const weaknessesTagSet = ref([]);
const weaknessesModalShow = ref(false);
const weaknessesTagSourceSet = ref([]);
const opportunitiesTagSet = ref([]);
const opportunitiesModalShow = ref(false);
const opportunitiesTagSourceSet = ref([]);
const threatsTagSet = ref([]);
const threatsModalShow = ref(false);
const threatsTagSourceSet = ref([]);
const noteBody = ref('');




const { showConfirmPopup, closePopup, showError, showToastPopup } = usePopupStore();
const route = useRoute();
// const loadingStatus = ref("loading");
//watch(() => route.query.noteId, setCurrentData);
////watch(currentNote, setCurrentData);

onMounted(async () => {
  await getChildSwots();
  await getTags();
});
async function saveSwot(){
  const r = await saeChildSwotsHandler();
  if (r.status==202){
    showToastPopup("SWOT сохранен");
    selectedSwot.value = r.data;
    updateData(selectedSwot.value);
   }
}
function tagFilter(item, queryText, itemText) {
      console.log("filter item: " + item);
      const textTagName = itemText.raw.tagName.toLowerCase();
      const textTagName2 = item.tagName.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textTagName.indexOf(searchText) > -1 ||
        textTagName2.indexOf(searchText) > -1 
      );
}

async function saeChildSwotsHandler(){
  return customAxios.put("/tutor/swot/" + selectedSwot.value.id, {
    showForParent: showForParent.value,
    strengthsTagSet: strengthsTagSet.value,
    weaknessesTagSet: weaknessesTagSet.value,
    opportunitiesTagSet: opportunitiesTagSet.value,
    threatsTagSet: threatsTagSet.value,
    noteBody: noteBody.value,
    childId: selectedSwot.value.childId,
    analysisDateTime: selectedSwot.value.analysisDateTime,
    isLastVersion: true
  });
}
async function editStrengths(){
  const r = await getStrengthsTagSourceSetHandler();
  strengthsTagSourceSet.value = r.data;
  strengthsModalShow.value=true;
  console.log("strengthsTagSourceSet size:" + strengthsTagSourceSet.value.length)

}
async function getStrengthsTagSourceSetHandler(){
  return customAxios.get("/tutor/swot/tag/STRENGTHS");
}

async function editWeaknesses(){
  const r = await getWeaknessesTagSourceSetHandler();
  weaknessesTagSourceSet.value = r.data;
  weaknessesModalShow.value=true;
  console.log("strengthsTagSourceSet size:" + weaknessesTagSourceSet.value.length)

}
async function getWeaknessesTagSourceSetHandler(){
  return customAxios.get("/tutor/swot/tag/WEAKNESSES");
}

async function editOpportunities(){
  const r = await getOpportunitiesTagSourceSetHandler();
  opportunitiesTagSourceSet.value = r.data;
  opportunitiesModalShow.value=true;
  console.log("strengthsTagSourceSet size:" + opportunitiesTagSourceSet.value.length)

}
async function getOpportunitiesTagSourceSetHandler(){
  return customAxios.get("/tutor/swot/tag/OPPORTUNITIES");
}

async function editThreats(){
  const r = await getThreatsTagSourceSetHandler();
  threatsTagSourceSet.value = r.data;
  threatsModalShow.value=true;
  console.log("strengthsTagSourceSet size:" + threatsTagSourceSet.value.length)

}
async function getThreatsTagSourceSetHandler(){
  return customAxios.get("/tutor/swot/tag/THREATS");
}


async function getChildSwotsHandler(){
  return customAxios.get("/tutor/swot/by_child/" + route.params.childId);
}



async function getChildSwots() {
  const r = await getChildSwotsHandler();
  childSwots.value = r.data;
  childSwots.value.map(sw =>{
    if(sw.isLastVersion===true){
      console.log("setting swot")
      selectedSwot.value = sw;
      updateData(sw);
    }
  })
}
function updateData(sw){
  id.value = sw.id;
  showForParent.value=sw.showForParent;
  strengthsTagSet.value=sw.strengthsTagSet;
  weaknessesTagSet.value=sw.weaknessesTagSet;
  opportunitiesTagSet.value=sw.opportunitiesTagSet;
  threatsTagSet.value=sw.threatsTagSet;
  noteBody.value=sw.noteBody;
}


async function getTags() {
  const r = await getTagsHandler();
  console.log("log:" + r);
}

async function getTagsHandler() {
  return customAxios.get("/tutor/swot/tag");
}


async function removeSwot() {
  try {
    const res = await customConfirm();

    if (res === "no") {
      closePopup();
    } else {
      closePopup();
      const r =await removeSwotHandler();
      if(r.status ==202){
        showToastPopup("Запись удалена");
        location.reload() 
      }
    }
  } catch (error) {
    showError(error);
  }
}

async function customConfirm() {
  return showConfirmPopup("Удалить SWOT?");
}

async function create() {
  try {
   const r = await createHandler();
   if (r.status==201){
    showToastPopup("Сздан новый SWOT");
    selectedSwot.value = r.data;
    updateData(selectedSwot.value);
   }
 
  } catch (error) {
    showError(error);
  }
}


async function createHandler() {
  return customAxios.post("/tutor/swot", {
    childId: Number(route.params.childId),
    showForParent: false,
  });
}

async function removeSwotHandler() {
  return customAxios.delete("/tutor/swot/" + selectedSwot.value.id);
}
</script>

<style lang="scss">
.s-report {
  &__tags {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}
</style>
