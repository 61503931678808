import { defineStore } from "pinia";
import { useErrorsData } from "@/composables/errorsData";

export const usePopupStore = defineStore("popup", {
  state: () => ({
    text: "",
    isVisible: false,
    popupType: null, // error toast confirm warning
    confirmPromise: null,
  }),

  actions: {
    closePopup() {
      this.text = "";
      this.isVisible = false;
      this.confirmPromise = null;
      this.popupType = null;
    },
    showToastPopup(text) {
      this.popupType = "toast";
      this.text = text;
      this.isVisible = true;
    },
    showInfoPopup(text) {
      this.popupType = "info";
      this.text = text;
      this.isVisible = true;
    },

    showErrorWithCustomText(text) {
      this.popupType = "error";
      this.text = text;
      this.isVisible = true;
    },

    showWarningPopup(text) {
      this.popupType = "warning";
      this.text = text;
      this.isVisible = true;
    },
    showConfirmPopup(text) {
      this.popupType = "confirm";
      this.text = text;
      this.isVisible = true;

      this.confirmPromise = new Promise(
        (resolve) => (this.confirmResolve = resolve)
      );
      return this.confirmPromise;
    },
    setConfirmRemoving(result) {
      this.confirmResolve(result === true ? "yes" : "no");
    },
    showError(error) {
      const { getErrorMessage } = useErrorsData();
      const errorMsg = getErrorMessage(error);

      if (errorMsg === "Токен не существует") {
        return;
      }

      this.showErrorWithCustomText(errorMsg);
    },
  },
});
