<template>
  <div class="page-wrapper my-5">
    <p class="mb-2 text-center">
      {{ getDateFormatWithTime(item.createDate) }} (ID: {{ item.id }})
    </p>
    <v-textarea
      v-model="message"
      hide-details
      variant="outlined"
      placeholder="Введите текст протокола"
    />
  </div>
</template>

<script setup>
import { defineProps, ref, watch } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { customAxios } from "@/service/customAxios";

import { usePopupStore } from "@/store/popup";
import { debounce } from "@/util/common";

const { showError, showToastPopup } = usePopupStore();
const { getDateFormatWithTime } = useDatesChanger();

const props = defineProps({ item: { tyoe: Object, required: true } });

const message = ref(props.item.protocolBody);

const debouncedSave = debounce(save, 1000);

watch(message, debouncedSave);

async function save() {
  if (message.value.trim().lenght === 0) {
    return;
  }
  try {
    await saveHandler();
    showToastPopup("Протокол изменён");
  } catch (error) {
    showError(error);
  }
}

async function saveHandler() {
  return customAxios.put(
    `/parent_consultation/consultant/protocol/${props.item.id}`,
    {
      protocolBody: message.value.trim(),
    }
  );
}
</script>
