<template>
  <router-link
    class="gradient-list-item__link-with-period gradient-list-item__content"
  >
    <div>
      <v-label
        >{{ journal.scheduleClass.scheduleClassName }} ({{
          journal.personGroup.personGroupName
        }})</v-label
      >
      <h4>
        {{ journal.teacherLogName }}
      </h4>
      <h5 v-if="!!journal.foreignChecklistCount">
        Ин. оценочных: {{ journal.foreignChecklistCount }}
      </h5>
      <h5 v-if="!!journal.checklistCount">
        Оценочных: {{ journal.checklistCount }}
      </h5>
      <h5 v-if="!!journal.foreignDictationCount">
        Ин. диктантов: {{ journal.foreignDictationCount }}
      </h5>
      <h5 v-if="!!journal.dictationCount">
        Диктантов: {{ journal.dictationCount }}
      </h5>
      <h5 v-if="!!journal.cheatingCount">
        Списываний: {{ journal.cheatingCount }}
      </h5>
    </div>

    <div class="gradient-list-item__period">
      <CIcon icon="calendar-icon" />
      <span
        >{{ getDateFormatWithoutTime(journal.beginDateTime) }} -
        {{ getDateFormatWithoutTime(journal.endDateTime) }}
        (ID года: {{ journal.studyYearPeriodId }})
      </span>
    </div>
  </router-link>
</template>

<script setup>
import { defineProps } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

import CIcon from "@/elements/CIcon.vue";

const { getDateFormatWithoutTime } = useDatesChanger();

defineProps({ journal: { type: Object, required: true } });
</script>

<style lang="scss"></style>
