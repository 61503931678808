<template>
  <div class="c-cognations">
    <PageTitle title="Доска достижений" />

    <div class="d-flex justify-center flex-wrap mb-3">
      <div v-if="isChild">
        <router-link
          :to="{ name: 'cognation-create' }"
          class="accent-button ma-1"
          >Добавить достижение</router-link
        >
        <router-link
          :to="{ name: 'cognations-list-mine' }"
          class="bordered-item ma-1"
          >Мои достижения</router-link
        >
      </div>
      <div v-if="mainRole === 'ROLE_PARENT'">
        <router-link
          :to="{ name: 'my-children-cognations-list' }"
          class="bordered-item ma-1"
          >Достижения моего ребенка</router-link
        >
      </div>
    </div>

    <SEmpty v-if="cognations.length === 0" />

    <div v-else>
      <CTop />

      <FilterToggler>
        <div class="g-col g-col--3-1fr">
          <FilterByGroupId />
          <FilterByCognationCategories />
        </div>
      </FilterToggler>

      <ComponentWithPagination
        :list="filteredCognaitons"
        @changeList="paginatedList = $event"
      >
        <div class="c-cognations__previews">
          <router-link
            v-for="c in paginatedList"
            :key="c.id"
            :to="{
              name: 'common-cognations-page',
              params: { cognationId: c.id },
            }"
            class="text-decoration-none link"
          >
            <CCognationPreview :item="c" />
          </router-link>
        </div>
      </ComponentWithPagination>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

import { useCognationsStore } from "@/store/cognations";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import PageTitle from "@/elements/PageTitle.vue";
import CCognationPreview from "./CCognationPreview";
import FilterByGroupId from "@/components/filtersElements/FilterByGroupId.vue";

import CTop from "./CTop.vue";
import FilterByCognationCategories from "@/components/filtersElements/FilterByCognationCategories.vue";

import SEmpty from "@/swot/SEmpty.vue";
import FilterToggler from "@/components/elements/FilterToggler.vue";
import ComponentWithPagination from "@/components/ComponentWithPagination.vue";
import { useAuthStore } from "@/store/auth";

const route = useRoute();
const { isChild, mainRole } = storeToRefs(useAuthStore());
const { cognations } = storeToRefs(useCognationsStore());

const paginatedList = ref([]);

const filteredCognaitons = computed(() => {
  if (!cognations.value.length) {
    return [];
  }

  return cognations.value.filter((v) => {
    if (route.query.filterByGroupId && !filterByGroup(v)) {
      return false;
    }

    if (route.query.filterByCategory && !filterByCategory(v)) {
      return false;
    }

    return true;
  });
});

function filterByGroup(v) {
  return (
    v.child.personGroups.findIndex(
      (g) => g.id === Number(route.query.filterByGroupId)
    ) >= 0
  );
}

function filterByCategory(v) {
  return v.cognationCategory.id === Number(route.query.filterByGroupId);
}
</script>

<style lang="scss">
.c-cognations {
  &__previews {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
  }

  @media (max-width: 1200px) {
    &__previews {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 480px) {
    &__previews {
      grid-template-columns: 1fr;
    }
  }
}
</style>
