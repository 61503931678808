<template>
  <div>
    <PageTitle title="Все журналы" />

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>

      <template v-slot:content>
        <p class="mb-3">Здесь отобразиться список журналов.</p>
        <p class="mb-3">Выставьте: <strong>год</strong> (это обязательно).</p>

        <p class="mb-3">
          Так же вы можете пофильтровать список по <strong>классу</strong> и
          <strong>предмету</strong>
        </p>
      </template>
    </TogglerOfWrapper>

    <div v-if="isAdmin" class="d-flex justify-center my-3">
      <router-link
        :to="{ name: 'teacher-journal-create' }"
        class="accent-button"
        >Создать Журнал</router-link
      >
    </div>

    <div class="g-col g-col--2-1fr mb-3">
      <FilterByYear />
    </div>

    <FilterToggler>
      <div class="g-col g-col--2-1fr">
        <FilterByGroupId />
        <FilterBySubject />
      </div>
    </FilterToggler>

    <StatusWrapper
      :loadingStatus="loadingStatus"
      :isDataExisted="journals.length > 0"
    >
      <ComponentWithPagination
        :list="filteredJournals"
        @changeList="paginatedData = $event"
      >
        <ul v-if="isAdmin">
          <li v-for="j in paginatedData" :key="j.id" class="gradient-list-item">
            <JLink
              :journal="j"
              :to="
                j.isActive
                  ? {
                      name: 'teacher-journal',
                      query: {
                        filterByGroupId: j.personGroupId,
                        filterBySubjectId: j.scheduleClassId,
                        filterByYear: j.studyYearPeriodId,
                        filterByJournalId: j.id,
                      },
                    }
                  : {
                      name: 'teacher-journal-edit',
                      query: { filterByJournalId: j.id },
                    }
              "
            />
          </li>
        </ul>

        <ul v-else>
          <li v-for="j in paginatedData" :key="j.id" class="gradient-list-item">
            <JLink
              :journal="j"
              :to="{
                name: 'teacher-journal',
                query: {
                  filterByGroupId: j.personGroupId,
                  filterBySubjectId: j.scheduleClassId,
                  filterByJournalId: j.id,
                  filterByYear: j.studyYearPeriodId,
                },
              }"
            />
          </li>
        </ul>
      </ComponentWithPagination>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router";

import { usePopupStore } from "@/store/popup";
import { useJournalData } from "@/composables/journalData";
import { useAuthStore } from "@/store/auth";

import { storeToRefs } from "pinia";
import { customAxios } from "@/service/customAxios";

import StatusWrapper from "@/components/StatusWrapper.vue";
import ComponentWithPagination from "@/components/ComponentWithPagination.vue";

import PageTitle from "@/elements/PageTitle.vue";
import FilterByGroupId from "@/components/filtersElements/FilterByGroupId.vue";
import FilterBySubject from "@/components/filtersElements/FilterBySubject.vue";

import FilterByYear from "@/components/filtersElements/FilterByYear.vue";
import FilterToggler from "@/components/elements/FilterToggler.vue";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import JLink from "./JLink.vue";

const { parseLocalStoreSearchingData } = useJournalData();
const { showError } = usePopupStore();

const { isAdmin } = storeToRefs(useAuthStore());
const route = useRoute();

const loadingStatus = ref("nothing");
const journals = ref([]);
const paginatedData = ref([]);

watch(() => route.query.filterByYear, getAllJournals, { immediate: true });

async function getAllJournals() {
  if (!route.query.filterByYear) {
    return;
  }

  loadingStatus.value = "loading";

  try {
    const r = await getAllJournalsHandler();
    journals.value = r.data;
    parseLocalStoreSearchingData();
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}

const filteredJournals = computed(() => {
  if (!journals.value.length) {
    return [];
  }

  return journals.value.filter((j) => {
    if (!filterByActivity(j)) {
      return false;
    }

    if (route.query.filterByGroupId && !filterByGroup(j)) {
      return false;
    }

    if (route.query.filterBySubjectId && !filterBySubject(j)) {
      return false;
    }

    return true;
  });
});

function filterByActivity(j) {
  if (j.isActive) {
    return true;
  }

  if (!j.isActive && isAdmin.value) {
    return true;
  }

  return false;
}

function filterByGroup(j) {
  return j.personGroupId === Number(route.query.filterByGroupId);
}

function filterBySubject(j) {
  return j.scheduleClassId === Number(route.query.filterBySubjectId);
}

async function getAllJournalsHandler() {
  return customAxios.post(`/teacher/log/all_by_study_year`, {
    studyYearId: Number(route.query.filterByYear),
  });
}
</script>
