<template>
  <div>

    <v-dialog v-model="logDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Создать журналы на период</h5>
        </v-card-title>
        <v-card-text>
          <v-form ref="cloneForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-label>Период*</v-label>
                  <DatePicker
                      v-model="logDateRange"
                      inline
                      range
                      textInput
                      inlineWithInput
                      autoApply
                      locale="ru"
                  />
                  <v-alert
                      v-if="logDateRangeAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Укажите дату!
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-card-actions class="card_ligth_actions d-flex flex-wrap">
         
          <v-btn
              rounded
              variant="outlined"
              @click="closeLogDialog"
          >
            Отменить
          </v-btn>
          <v-btn
              rounded
              variant="outlined"
              @click="createLogs"
              :disabled="createLogAnimation"
          >
            <font-awesome-icon
                v-if="createLogAnimation === false"
                icon="fas fa-floppy-disk"
            />
            Создать журналы
            <v-progress-circular
                v-if="createLogAnimation"
                indeterminate
                color="white"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCloneDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Клонирование событий на следующую неделю</h5>
        </v-card-title>
        <v-card-text>
          <v-form ref="cloneForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-label>Дата начала периода*</v-label>
                  <DatePicker
                      v-model="cloneBeginDate"
                      inline
                      textInput
                      inlineWithInput
                      autoApply
                      locale="ru"
                  />
                  <v-alert
                      v-if="cloneBeginDateAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Укажите дату!
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-label>Дата окончания периода*</v-label>
                  <DatePicker
                      v-model="cloneEndDate"
                      inline
                      textInput
                      inlineWithInput
                      autoApply
                      locale="ru"
                  />
                  <v-alert
                      v-if="cloneEndDateAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Окончание раньше начала!
                  </v-alert>
                  <v-alert
                      v-if="cloneEndNoDateAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Укажите дату!
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn
              rounded
              variant="outlined"
              @click="closeCloneEventDialog"
              :disabled="cloneAnimation"
          >
            <font-awesome-icon
                v-if="cloneAnimation === false"
                icon="fa fa-undo"
            />
            Отменить
          </v-btn>
          <v-btn
              rounded
              variant="outlined"
              @click="cloneEvents"
              :disabled="cloneAnimation"
          >
            <font-awesome-icon
                v-if="cloneAnimation === false"
                icon="fas fa-floppy-disk"
            />
            Клонировать
            <v-progress-circular
                v-if="cloneAnimation"
                indeterminate
                color="white"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEventDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h3 v-if="!!scheduleId">Новое событие</h3>
          <h3 v-else>Редактирование события</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="eventForm">
            <v-container>
              <v-row>
                <!--  Предмет -->
                <v-col cols="12">
                  <v-autocomplete
                      v-model="scheduleClass"
                      :items="activeClasses"
                      :filter="eventScheduleClassFilter"
                      outlined
                      clearable
                      label="Тип"
                      :rules="scheduleEventClassRule"
                      item-title="scheduleClassName"
                      v-on:change="getSelectedClass(scheduleClass)"
                      item-value="id"
                  >

                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.scheduleClassName"
                          :subtitle="item.raw.scheduleClassDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--  описание -->
                <v-col cols="12">
                  <v-textarea
                      label="Описание*"
                      v-model="eventDescription"
                      :counter="255"
                      hint="Укажите описание"
                  ></v-textarea>
                </v-col>
                <!--  дата начала -->
                <v-col cols="12">
                  <v-label>Дата начала*</v-label>
                  <DatePicker
                      v-model="eventBeginDateTime"
                      locale="ru"
                      class="w-100"
                      autoApply
                      inline
                      textInput
                      inlineWithInput
                      v-on:change="generateEndDate"
                  />
                  <v-alert
                      v-if="eventBeginNoDateAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Укажите дату!
                  </v-alert>
                </v-col>
                <!--  дата окончания -->
                <v-col cols="12">
                  <v-label>Дата окончания*</v-label>
                  <DatePicker
                      v-model="eventEndDateTime"
                      autoApply
                      locale="ru"
                      class="w-100"
                      inline
                      textInput
                      inlineWithInput
                  />
                  <v-alert
                      v-if="eventDateAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Окончание раньше начала!
                  </v-alert>
                  <v-alert
                      v-if="eventEndNoDateAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Укажите дату!
                  </v-alert>
                </v-col>
                <!--  организатор -->
                <v-col cols="12" v-if="selectedScheduleClass!==null" >
                  <v-autocomplete
                      v-model="eventOrganizer"
                      :items="selectedScheduleClass.classTeachers"
                      :filter="eventOrganizerFilter"
                      outlined
                      dense
                      chips
                      small-chips
                      closable-chips
                      :rules="eventOrganizerRule"
                      item-title="lastname"
                      item-value="id"
                      label="Организатор*"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.lastname + ' ' + item.raw.firstname + ' '+item.raw.secname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.lastname + ' ' + item.raw.firstname + ' '+item.raw.secname"
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--   расписание -->
                <v-col cols="12">
                  <v-autocomplete
                      v-model="schedule"
                      :items="activeSchedules"
                      outlined
                      dense
                      :rules="eventScheduleRule"
                      item-title="scheduleName"
                      return-object
                      label="Расписание*"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.scheduleName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.scheduleName"
                          :subtitle="item.raw.scheduleDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--
                <v-col cols="12">
                  <v-autocomplete
                      v-model="eventLocation"
                      :items="activeLocations"
                      :filter="eventLocationFilter"
                      outlined
                      dense
                      item-title="locationName"
                      item-value="id"
                      label="Помещение*"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.locationName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.locationName"
                          :subtitle="item.raw.locationDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                -->
                <!--   одиночные участники -->
                <v-col cols="12" >
                  <v-autocomplete
                      v-model="eventParticipants"
                      :items="users"
                      outlined
                      dense
                      chips
                      small-chips
                      return-object
                      closable-chips
                      multiple
                      label="Участники"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.firstname + ' ' + item.raw.lastname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.firstname + ' ' + item.raw.lastname"
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--   группы -->
                <v-col cols="12">
                  <v-autocomplete
                      v-model="eventParticipantGroups"
                      :items="activeChildGroups"
                      outlined
                      dense
                      chips
                      small-chips
                      closable-chips
                      multiple
                      label="Группы участников"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.personGroupName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.personGroupName"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--   запись на занятие -->
                <v-col cols="12">
                  <v-row class="px-6" fluid>
                    <v-switch
                        v-model="divideIntoGroups"
                        :label="'Делить на подгруппы'"
                        color="success"
                    >
                    </v-switch>
                    <v-chip v-if="divideIntoGroups">Да</v-chip
                    ><v-chip v-else>Нет</v-chip>
                  </v-row>
                </v-col>
                <!--   подгруппа -->
                <v-col cols="12"   v-if="!!eventParticipantGroups && eventParticipantGroups.length===1 && !!divideIntoGroups" >
                  <v-autocomplete
                      v-model="subgroupId"
                      :items="!!eventParticipantGroups && eventParticipantGroups[0].subgroups && eventParticipantGroups[0].subgroups.length>0 ? eventParticipantGroups[0].subgroups : []"
                      outlined
                      item-title="subgroupName"
                      item-text="subgroupName"
                      item-value="id"
                      dense
                      chips
                      small-chips
                      closable-chips
                      label="Подгруппа"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.subgroupName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.subgroupName"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--   запись на занятие -->
                <v-col cols="12">
                  <v-row class="px-6" fluid>
                    <v-switch
                        v-model="userEnrollment"
                        :label="'Запись на занятие'"
                        color="success"
                    >
                    </v-switch>
                    <v-chip v-if="userEnrollment">Да</v-chip
                    ><v-chip v-else>Нет</v-chip>
                  </v-row>
                </v-col>
                <!--   мест для записи -->
                <v-col cols="12" v-if="userEnrollment">
                  <div class="text-caption">Мест для записи:</div>
                  <v-slider
                      v-model="placeCount"
                      thumb-label="always"
                      class="align-center"
                      :max="30"
                      :min="1"
                      step="1"
                      show-ticks="always"
                  ></v-slider>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined" @click="closeEventDialog">
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveEvent">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rangeEventsCreateDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h3>Новая пачка событий</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="eventForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="scheduleClass"
                      :items="sortedEventsForList"
                      :filter="eventScheduleClassFilter"
                      outlined
                      clearable
                      append-icon="mdi-file-search"
                      label="Тип"
                      :rules="scheduleEventClassRule"
                      item-title="scheduleClassName"
                      v-on:change="getSelectedClass(scheduleClass)"
                      v-on:click:clear="clearClass()"
                      v-on:click:append="getSelectedClass(scheduleClass)"

                      item-value="id"
                  >

                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.scheduleClassName"
                          :subtitle="item.raw.scheduleClassDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <div v-if="showClassOptionsLoader" class="w-100">
                  <v-col cols="12">
                    <loader-ring></loader-ring>
                  </v-col>
                </div>
                <div v-if="showClassOptions && !showClassOptionsLoader" class="w-100">
                  <v-row>
                    <v-col cols="6"  >
                      <v-label>Дата начала*</v-label>
                      <DatePicker
                          v-model="eventBeginDateTime"
                          locale="ru"
                          class="w-100"
                          autoApply
                          inline
                          textInput
                          inlineWithInput
                          v-on:change="generateEndDate"
                      />
                      <v-alert
                          v-if="eventBeginNoDateAlert === true"
                          border="top"
                          color="red lighten-2"
                          dark
                      >
                        Укажите дату!
                      </v-alert>
                    </v-col>
                    <v-col cols="6"  >
                      <v-label>Дата окончания*</v-label>
                      <DatePicker
                          v-model="eventEndDateTime"
                          autoApply
                          locale="ru"
                          class="w-100"
                          inline
                          textInput
                          inlineWithInput
                      />
                      <v-alert
                          v-if="eventDateAlert === true"
                          border="top"
                          color="red lighten-2"
                          dark
                      >
                        Окончание раньше начала!
                      </v-alert>
                      <v-alert
                          v-if="eventEndNoDateAlert === true"
                          border="top"
                          color="red lighten-2"
                          dark
                      >
                        Укажите дату!
                      </v-alert>
                    </v-col>
                    <v-col cols="12"  >
                      <v-label>Время начала*</v-label>
                    </v-col>
                    <v-col cols="12"  >
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(8,0)">8:00</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(8,20)">8:20</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(8,50)">8:50</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(9,30)">9:30</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(9,50)">9:50</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(10,40)">10:40</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(11,30)">11:30</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(12,10)">12:10</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(13,10)">13:10</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(14,0)">14:00</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(14,50)">14:50</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(15,30)">15:30</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(16,30)">16:30</v-btn>
                      <v-btn rounded variant="outlined" class="ma-2" @click="setBeginTime(17,0)">17:00</v-btn>

                    </v-col>
                    <v-col cols="12"  >
                      <v-btn rounded variant="outlined" @click="addEventToList()">Добавить в список</v-btn>
                    </v-col>

                    <v-col cols="12" >
                      <v-chip-group>
                        <v-chip
                            closable

                            @click:close="removeRange(index)"
                            v-for="(r, index) in this.rangeList" :key="index">{{formatDate(r.begin)}} {{formatDate(r.end)}}</v-chip>
                      </v-chip-group>
                    </v-col>



                    <v-col cols="12"  >
                      <v-autocomplete
                          v-model="eventOrganizer"
                          :items="(!!selectedScheduleClass && selectedScheduleClass.classTeachers && selectedScheduleClass.classTeachers.length>0) ? selectedScheduleClass.classTeachers : eventOrganizers"
                          :filter="eventOrganizerFilter"
                          outlined
                          clearable
                          dense
                          :rules="eventOrganizerRule"
                          item-title="lastname"
                          item-value="id"
                          label="Организатор*"
                      >

                        <template v-slot:item="{ props, item }">
                          <v-list-item
                              v-bind="props"
                              :title="item.raw.lastname + ' ' + item.raw.firstname + ' ' + item.raw.secname"
                          ></v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" >
                      <v-autocomplete
                          v-model="schedule"
                          :items="activeSchedules"
                          outlined
                          dense
                          clearable
                          :rules="eventScheduleRule"
                          item-title="scheduleName"
                          item-value="id"
                          label="Расписание*"
                      >

                        <template v-slot:item="{ props, item }">
                          <v-list-item
                              v-bind="props"
                              :title="item.raw.scheduleName"
                              :subtitle="item.raw.scheduleDescription"
                          ></v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12"  >
                      <v-autocomplete
                          v-model="eventParticipantGroups"
                          :items="activeChildGroups"
                          outlined
                          dense
                          chips
                          small-chips
                          closable-chips
                          multiple
                          label="Группы участников"
                      >
                        <template v-slot:chip="{ props, item }">
                          <v-chip
                              v-bind="props"
                              :text="item.raw.personGroupName"
                          ></v-chip>
                        </template>
                        <template v-slot:item="{ props, item }">
                          <v-list-item
                              v-bind="props"
                              :title="item.raw.personGroupName"
                          ></v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12"  >
                      <v-autocomplete
                          v-if="!!eventParticipantGroups && eventParticipantGroups.length===1"
                          v-model="subgroupId"
                          :items="!!eventParticipantGroups && eventParticipantGroups[0].subgroups && eventParticipantGroups[0].subgroups.length>0 ? eventParticipantGroups[0].subgroups : []"
                          outlined
                          item-title="subgroupName"
                          item-text="subgroupName"
                          item-value="id"
                          dense
                          chips
                          small-chips
                          closable-chips
                          label="Подгруппа"
                      >
                        <template v-slot:chip="{ props, item }">
                          <v-chip
                              v-bind="props"
                              :text="item.raw.subgroupName"
                          ></v-chip>
                        </template>
                        <template v-slot:item="{ props, item }">
                          <v-list-item
                              v-bind="props"
                              :title="item.raw.subgroupName"
                          ></v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>


                </div>


              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined" @click="closeEventListDialog">
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveEventList">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showLoader"><LoaderRing ></LoaderRing></v-dialog>

    <v-card class="ma-2">
      <v-card-title class="card_ligth_header">
        <h3 class="ma-2">Параметры событий</h3>
      </v-card-title>
      <v-row class="ma-2"  >
        <v-col class="v-col-12">
          <v-autocomplete
              v-model="selectedSchedule"
              :items="schedules"
              filled
              variant="outlined"
              label="Расписание"
              return-object
              item-title="scheduleName"
              class="w-100"
              density="comfortable"

              @click:clear="selectedSchedule=undefined"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item
                  v-bind="props"
                  :title="item.raw.scheduleName"
              ></v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="ma-2">
        <v-col cols="11">
          <v-label>Период</v-label>
          <DatePicker
              v-model="selectedScheduleRange"
              range
              autoApply
              locale="ru"
              class="w-100"
          />
        </v-col>
        <v-col cols="1" class="mt-4">
          <v-btn
              icon="mdi-file-search"
              size="large"
              variant="text"
              @click="getEvents()"
          ></v-btn>
        </v-col>
      </v-row>


    </v-card>
    <v-card v-if="showEventsList"  class="ma-2">
      <v-card-title class="card_ligth_header" style="min-height: 5vh">
        <v-row>
          <v-col cols="12">
            <h3 class="ma-2">События</h3>
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model="eventSearchBody"
                label="Найти"
                variant="outlined"
                clearable
                hint="Введите ключевое слово для поиска"
                rounded
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
                v-model="scheduleFilterClass"
                :items="activeClasses"
                :filter="eventScheduleClassFilter"
                outlined
                dense
                chips
                small-chips
                label="Тип"
                clearable
                item-title="scheduleClassName"
                item-value="id"
            >
              <template v-slot:chip="{ props, item }">
                <v-chip
                    v-bind="props"
                    :text="item.raw.scheduleClassName"
                ></v-chip>
              </template>
              <template v-slot:item="{ props, item }">
                <v-list-item
                    v-bind="props"
                    :title="item.raw.scheduleClassName"
                    :subtitle="item.raw.scheduleClassDescription"
                ></v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
                v-model="filterEventOrganizer"
                :items="eventOrganizers"
                :custom-filter="eventOrganizerFilter"
                outlined
                dense
                chips
                small-chips
                clearable
                item-title="lastname"
                item-value="id"
                label="Организатор"
            >
              <template v-slot:chip="{ props, item }">
                <v-chip
                    v-bind="props"
                    :text="item.raw.firstname + ' ' + item.raw.lastname"
                ></v-chip>
              </template>
              <template v-slot:item="{ props, item }">
                <v-list-item
                    v-bind="props"
                    :title="item.raw.firstname + ' ' + item.raw.lastname"
                    :subtitle="item.raw.username"
                ></v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="d-flex flex-wrap">
            <v-btn
                @click="showEventDialog = true"
                variant="outlined"
                color="primary"
                rounded
                class="ma-2"
                size="small"
            >
              Добавить
            </v-btn>
            <v-btn
                @click="rangeEventsCreateDialog = true"
                variant="outlined"
                color="primary"
                rounded
                class="ma-2"
                size="small"
            >
              Добавить пачкой
            </v-btn>
            <v-btn
                @click="showClone"
                variant="outlined"
                color="primary"
                class="ma-2"
                size="small"
                rounded
            >
              Клонировать период
            </v-btn>
            <v-btn
                @click="this.logDialog=true;"
                variant="outlined"
                color="primary-dark"
                class="ma-2"
                size="small"
                rounded
            >
              Создать журналы на период
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="min-height: 80vh" >

       <div class="d-sm-none d-flex align-center flex-column">
          <v-card
              class="ma-4 mx-4 w-100"
              v-for="item in eventsPaginatedData"
              :key="item"
          >
            <v-card-title class="text-pink-lighten-2 bg-pink-lighten-5 ">
              <h5>
                Событие № {{ eventsPaginatedData.indexOf(item) + 1 }}
              </h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-list-item lines="two">
                <v-list-item-title>Название:</v-list-item-title>
                <v-list-item-subtitle>{{
                    item.scheduleClass.scheduleClassName
                  }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item lines="two">
                <v-list-item-title>Начало:</v-list-item-title>
                <v-list-item-subtitle>{{
                    item.eventBeginDateTime.replace("T", " ")
                  }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item lines="two">
                <v-list-item-title>Завершение:</v-list-item-title>
                <v-list-item-subtitle>{{
                    item.eventEndDateTime.replace("T", " ")
                  }}</v-list-item-subtitle>
              </v-list-item>

              <v-list-item lines="two">
                <v-list-item-title>Организатор:</v-list-item-title>
                <v-list-item-subtitle
                >{{ item.eventOrganizer.firstname }}
                  {{
                    item.eventOrganizer.lastname
                  }}</v-list-item-subtitle
                >
              </v-list-item>

              <v-list-item lines="two">
                <v-list-item-title>Участники:</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip-group active-class="primary--text" column>
                    <v-chip
                        size="small"
                        v-for="pp in item.eventParticipants"
                        :key="pp"
                    >{{ pp.firstname }} {{ pp.lastname }}</v-chip
                    >
                  </v-chip-group>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item lines="two">
                <v-list-item-title
                >Группы участников:</v-list-item-title
                >
                <v-list-item-subtitle>
                  <v-chip-group active-class="primary--text" column>
                    <v-chip
                        size="small"
                        v-for="pg in item.eventParticipantGroups"
                        :key="pg"
                    >{{ pg.personGroupName }}</v-chip
                    >
                  </v-chip-group>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item lines="two">
                <v-list-item-title>Делить на подгруппы:</v-list-item-title>
                <v-list-item-subtitle v-if="!!item.divideIntoGroups">Да</v-list-item-subtitle>
                <v-list-item-subtitle v-else>Нет</v-list-item-subtitle>
              </v-list-item>
              <v-list-item lines="two" v-if="!!item.divideIntoGroups">
                <v-list-item-title>Подгруппа:</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip size="small" v-if= "!!item.subgroup">{{ item.subgroup.subgroupName}}</v-chip>
                  <v-chip size="small" v-else>Подгруппа не выбрана</v-chip>
                </v-list-item-subtitle>
              </v-list-item>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions
                class="text-pink-lighten-2 bg-pink-lighten-5 "
                style="min-height: 5vh"
            >

              <v-btn
                  @click="editEvent(item)"
                  class="ma-1"
                  color="primary"
                  variant="outlined"
                  rounded
              >
                Редактировать
              </v-btn>
              <v-btn
                  @click="deleteEvent(item)"
                  class="ma-1"
                  color="warning"
                  variant="outlined"
                  rounded
              >
                Удалить
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div class="d-none d-sm-block">
          <v-table fixed-header class="h-100">
            <thead>
            <tr>
              <th class="text-left">
                <b>№</b>
              </th>
              <th class="text-left" v-on:click="byEventName">
                <b>Название</b>
                <font-awesome-icon icon="fa-solid fa-sort" />
              </th>
              <th class="text-left" v-on:click="byEventOrganizer">
                <b>Организатор</b>
                <font-awesome-icon icon="fa-solid fa-sort" />
              </th>
              <th class="text-left" v-on:click="byEventBeginDateTime">
                <b>Время</b>
                <font-awesome-icon icon="fa-solid fa-sort" />
              </th>
              <th class="text-left">
                <b>Группы</b>
              </th>

              <th class="text-left">
                <b>Подгруппы</b>
              </th>
              <th class="text-left hidden-lg-and-down">
                <b>Участники</b>
              </th>
              <th class="text-left hidden-lg-and-down">
                <b>Группы</b>
              </th>

              <th class="text-left">
                <b>Действие</b>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in eventsPaginatedData"
                :key="item"
                :class="item.userEnrollment ? 'bg-success' : ''"
            >
              <td>
                <p>
                  {{ filteredEventsByEventClass.indexOf(item) + 1 }}
                </p>
              </td>
              <td>
                <p>{{ item.scheduleClass.scheduleClassName }}</p>
              </td>
              <td>
                <v-chip size="small"
                >{{ item.eventOrganizer.firstname }}
                  {{ item.eventOrganizer.lastname }}
                </v-chip>
              </td>
              <td>
                <p>{{ item.eventBeginDateTime.replace("T", " ") }} - {{ item.eventEndDateTime.replace("T", " ") }}</p>
              </td>
              <td>
                <v-chip-group active-class="primary--text" column>
                  <v-chip
                      size="small"
                      v-for="pg in item.eventParticipantGroups"
                      :key="pg"
                  >{{ pg.personGroupName }}</v-chip
                  >
                </v-chip-group>
              </td>

              <td class="hidden-md-and-down">

               <div v-if="!!item.divideIntoGroups">
                 <v-chip size="small" v-if= "!!item.subgroup">{{ item.subgroup.subgroupName}}</v-chip>
                 <v-chip size="small" v-else>Подгруппа не выбрана</v-chip>
               </div>
                <div v-else>
                  <v-chip size="small">Нет</v-chip>
                </div>
              </td>
              <td class="hidden-lg-and-down">
                <v-chip-group active-class="primary--text" column>
                  <v-chip

                      size="small"
                      v-for="pp in item.eventParticipants"
                      :key="pp"
                  >{{ pp.firstname }} {{ pp.lastname }}</v-chip
                  >
                </v-chip-group>
              </td>
              <td class="hidden-lg-and-down">
                <v-chip-group active-class="primary--text" column>
                  <v-chip
                      size="small"
                      v-for="pg in item.eventParticipantGroups"
                      :key="pg"
                  >{{ pg.personGroupName }}</v-chip
                  >
                </v-chip-group>
              </td>
              <td>
                <p>

                  <v-btn
                      @click="editEvent(item)"
                      class="ma-1"
                      color="primary"
                      variant="outlined"
                      rounded
                  >
                    Редактировать
                  </v-btn>
                  <v-btn
                      @click="deleteEvent(item)"
                      class="ma-1"
                      color="warning"
                      variant="outlined"
                      rounded
                  >
                    Удалить
                  </v-btn>
                </p>
              </td>
            </tr>
            </tbody>
          </v-table>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions align-self-end">
        <v-container class="text-center">
          <v-pagination
              v-model="eventPage"
              :length="eventsPageCount"
              :total-visible="3"
              size="small"
          ></v-pagination>
        </v-container>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
import LoaderRing from "@/elements/LoaderRing.vue";
import _ from "lodash";
import {customAxios} from "@/service/customAxios";

export default {
  name: "ScheduleEventLIst",
  props: [],
  components: {LoaderRing },
  data: () => ({
    eventPage: 1,
    showLoader: false,
    noteCount:10,
    schedules:[],
    scheduleClasses:[],
    events:[],
    users:[],
    userGroups: [],
    logDateRange:[],
    logDateRangeAlert:false,
    createLogAnimation:false,
    showCloneDialog: false,
    divideIntoGroups: false,

    cloneBeginDate:undefined,
    cloneEndDate:undefined,
    cloneBeginDateAlert:false,
    cloneEndDateAlert:false,
    cloneEndNoDateAlert:false,
    cloneAnimation:false,
    showClassOptionsLoader:false,
    showClassOptions:false,




    selectedScheduleRange:[],
    selectedSchedule:undefined,
    showEventsList: false,
    eventSearchBody:"",
    scheduleFilterClass: undefined,
    filterEventOrganizer: undefined,
    showEventDialog:false,
    rangeEventsCreateDialog:false,
    showClone:false,
    logDialog:false,

    eventName:'',
    eventDescription:'',
    eventBeginDateTime: new Date(),
    eventEndDateTime: null,
    eventOrganizer:null,
    eventParticipants:[],
    eventParticipantGroups: [],
    eventIsActive:true,
    scheduleId:null,
    eventId: null,

    eventLocation: null,
    eventCollisions: [],
    scheduleClassId:null,
    placeCount:0,
    userEnrollment: false,

    eventSortKey: "eventBeginDateTime",
    eventLastKey: "eventBeginDateTime",
    eventSortOrder: 1,

    eventFilterBeginDate: "",
    eventFilterEndDate: "",


    scheduleClass:'',
    scheduleEventClassRule: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
    ],
    eventBeginNoDateAlert:false,
    eventDateAlert:false,
    eventEndNoDateAlert:false,
    selectedScheduleClass:'',
    eventOrganizerRule: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
    ],
    schedule:null,
    eventScheduleRule: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
    ],

    rangeList:[],

    subgroupId:null,



  }),
  computed: {

    sortedEventsForList(){
      let source = this.activeClasses;
      return source.sort(
          dynamicSort(
              "scheduleClassName",
              1
          )
      );
    },
    user() {
      return this.$store.state.authentication.user;
    },
    activeLocations() {
      let sourceLocations = this.locations;
      return sourceLocations.filter((item) => item.isActive === true);
    },
    activeSchedules() {
      let sourceSchedules = this.schedules;
      return sourceSchedules.filter((item) => item.isActive === true);
    },
    activeClasses() {
      let classes = this.scheduleClasses;
      return classes.filter((item) => item.isActive === true);
    },
    filteredEvents() {
      const ss = this.eventSearchBody.toLowerCase();
      let tempEvents = this.events;

      return tempEvents.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );
    },
    filteredEventsByEventOrganizer() {
      let tempEvents = this.filteredEvents;
      if (this.filterEventOrganizer) {
        return tempEvents.filter((sortedEvent) => {
          if (sortedEvent.eventOrganizerId === this.filterEventOrganizer) {
            return sortedEvent;
          }
        });
      } else {
        return tempEvents;
      }
    },
    filteredEventsByEventClass() {
      let tempEvents = this.filteredEventsByEventOrganizer;
      if (this.scheduleFilterClass) {
        return tempEvents.filter((sortedEvent) => {
          if (sortedEvent.scheduleClassId === this.scheduleFilterClass) {
            return sortedEvent;
          }
        });
      } else {
        return tempEvents;
      }
    },
    sortedEvents() {
      let tempEvents = this.filteredEventsByEventClass;
      console.log("unsortedEvents count : " + tempEvents.length)
      return tempEvents.sort(
          dynamicSort(this.eventSortKey, this.eventSortOrder)
      );
    },
    eventsPageCount() {
      let l = this.filteredEventsByEventClass.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },
    eventsPaginatedData() {
      const start = (this.currentEventsPage - 1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);
      return this.sortedEvents.slice(start, end);
    },
    currentEventsPage() {
      if (this.eventsPageCount < this.eventPage) {
        return 1;
      } else {
        return this.eventPage;
      }
    },
    eventOrganizers(){
      let  source = this.users;

      let result = [];
      source.forEach(item =>{
        if(item.roles.includes("ROLE_TEACHER") ||
            item.roles.includes("ROLE_TUTOR") ||
            item.roles.includes("ROLE_MASTER") ){
          result.push(item);
        }
      })
      return result.sort(
          dynamicSort(
              "lastname",
              1
          )
      );

    },
    activeChildGroups(){
      let tempUsers = this.userGroups;
      let  filteredGroups = [];
      tempUsers.forEach(item =>{
        if(item.isChilds && !item.isLocked){
          filteredGroups.push(item);
        }
      })
      return filteredGroups;
    },
  },
  methods: {
    objectListSterilize(sourceList){
      let resultList = [];
      sourceList.forEach(item =>{
        let sterilized = {id:item.id};
        resultList.push(sterilized);
      })
      return resultList;
    },
    removeRange(index) {
      console.log("removing index: " + index)
      this.rangeList.splice(index, 1)
      console.log("this.rangeList: " + this.rangeList)
    },
    formatDate(dateString) {
      let formatter = new Intl.DateTimeFormat("ru", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      });

      return capitalizeFirstLetter(formatter.format(Date.parse(dateString)));

    },

    saveEventList() {
    
      if (this.rangeList.length > 0) {
        const userTimezoneOffset = this.eventBeginDateTime.getTimezoneOffset() * 60000;
        let newRangeList = [];
        this.rangeList.forEach(item => {
          let newRange = {
            begin: new Date(item.begin.getTime() - userTimezoneOffset),
            end: new Date(item.end.getTime() - userTimezoneOffset)
          }
          newRangeList.push(newRange);
        })

        let event = {
          scheduleClassId: (this.scheduleClass instanceof Object) ? this.scheduleClass.id : this.scheduleClass,
          scheduleId: this.schedule.id,
          eventParticipantGroups: this.objectListSterilize(this.eventParticipantGroups),
          eventOrganizerId: (this.eventOrganizer instanceof Object) ? this.eventOrganizer.id : this.eventOrganizer,
          rangeList: newRangeList,
          subgroupId: this.subgroupId
        };
        customAxios
            .post("/schedule/event/create_by_list", event)
            .then((response) => {
              if (response.status === 201) {

                this.events.concat(response.data);
                this.rangeEventsCreateDialog = false;
                this.scheduleClassId = null;
                this.scheduleClass = "";
                this.eventBeginDateTime = new Date();
                this.eventEndDateTime = null;
                this.rangeList = [];
                this.showClassOptions = false;
                this.subgroupId= null;

              } else {
                alert("Что-то пошло не так");
                console.log(response);
              }
            })
            
      } else {
        alert("Не все поля заполнены!")
      }


    },
    closeEventListDialog() {
      this.rangeEventsCreateDialog = false;
      this.scheduleClassId = null;
      this.scheduleClass = "";
      this.eventBeginDateTime = new Date();
      this.eventEndDateTime = null;
      this.rangeList = [];
      this.showClassOptions = false;
      this.subgroupId= null;


    },
    setBeginTime(h, m){
      if(this.eventBeginDateTime===null){
        this.eventBeginDateTime = new Date();
      }
      let eventBeginDate = new Date(this.eventBeginDateTime);
      eventBeginDate.setHours(h,m);
      let beginDateInMs = Date.parse(eventBeginDate);

      let durationInMs = 0;
      if(this.scheduleClass.scheduleClassDuration){
        durationInMs = this.scheduleClass.scheduleClassDuration * 60000;
      } else {
        durationInMs = 40 * 60000;
      }
      console.log("duration: " +durationInMs +" begindatetime in ms: " + beginDateInMs);

      let endDateInMs = beginDateInMs + durationInMs;
      this.eventEndDateTime = new Date(endDateInMs);
      this.eventBeginDateTime = eventBeginDate;
      console.log("dates: " + this.eventBeginDateTime +" to "+ this.eventBeginDateTime);

      let range = {
        begin: this.eventBeginDateTime,
        end: this.eventEndDateTime
      }
      this.rangeList.push(range);

    },
    addEventToList() {

      let range = {
        begin: this.eventBeginDateTime,
        end: this.eventEndDateTime
      }
      this.rangeList.push(range);
    },


    eventOrganizerFilter(item, queryText, itemText) {
      console.log("filter item: " + item);
      const textFirstnameText = itemText.raw.firstname.toLowerCase();
      const textLastnameText = itemText.raw.lastname.toLowerCase();
      const textSecnameText = itemText.raw.secname.toLowerCase();

      const textFirstname = item.firstname.toLowerCase();
      const textLastname = item.lastname.toLowerCase();
      const textSecname = item.secname.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
          textFirstnameText.indexOf(searchText) > -1 ||
          textLastnameText.indexOf(searchText) > -1 ||
          textSecnameText.indexOf(searchText) > -1 ||
          textFirstname.indexOf(searchText) > -1 ||
          textLastname.indexOf(searchText) > -1 ||
          textSecname.indexOf(searchText) > -1
      );
    },

    byEventName: function () {
      if (this.eventLastKey.includes("eventName")) {
        this.eventSortOrder = this.eventSortOrder * -1;
      }
      this.eventSortKey = "eventName";
      this.eventLastKey = this.eventSortKey;

      console.log("eventName, sortOrder:" + this.sortOrder);
    },
    byEventBeginDateTime: function () {
      if (this.eventLastKey.includes("eventBeginDateTime")) {
        this.eventSortOrder = this.eventSortOrder * -1;
      }
      this.eventSortKey = "eventBeginDateTime";
      this.eventLastKey = this.eventSortKey;
      console.log("eventBeginDateTime, sortOrder:" + this.sortOrder);
    },
    byEventEndDateTime: function () {
      if (this.eventLastKey.includes("eventEndDateTime")) {
        this.eventSortOrder = this.eventSortOrder * -1;
      }
      this.eventSortKey = "eventEndDateTime";
      this.eventLastKey = this.eventSortKey;
    },
    byEventLocation: function () {
      if (this.eventSortKey.includes("locationId")) {
        this.eventSortOrder = this.eventSortOrder * -1;
      }
      this.eventSortKey = "locationId";
      this.eventLastKey = this.eventSortKey;
      console.log("locationId, sortOrder:" + this.sortOrder);
    },
    byEventOrganizer: function () {
      if (this.eventSortKey.includes("eventOrganizer")) {
        this.eventSortOrder = this.eventSortOrder * -1;
      }
      this.eventSortKey = "eventOrganizer";
      this.lastKey = this.eventSortKey;
      console.log("creatorId, sortOrder:" + this.sortOrder);
    },


    clearClass(){
      this.showClassOptions=false;
    },
    saveEvent() {
      this.eventBeginNoDateAlert =
          this.eventBeginDateTime === null ||
          this.eventBeginDateTime === undefined;

      this.eventEndNoDateAlert =
          this.eventEndDateTime === null || this.eventEndDateTime === undefined;

      if (!this.eventEndNoDateAlert && !this.eventBeginNoDateAlert) {
        this.eventDateAlert =
            Date.parse(this.eventBeginDateTime) >=
            Date.parse(this.eventEndDateTime);
      }
      this.$refs.eventForm.validate().then((result) => {
        if (
            result.valid &&
            !this.eventEndNoDateAlert &&
            !this.eventBeginNoDateAlert &&
            !this.eventDateAlert
        ) {

          if (this.eventOrganizer instanceof Object) {
            this.eventOrganizer = this.eventOrganizer.id;
          }
          if (this.eventLocation instanceof Object) {
            this.eventLocation = this.eventLocation.id;
          }
          if (this.schedule instanceof Object) {
            this.schedule = this.schedule.id;
          }
          if (this.scheduleClass instanceof Object) {
            this.scheduleClass = this.scheduleClass.id;
          }

          const userTimezoneOffset =
              this.eventBeginDateTime.getTimezoneOffset() * 60000;

          let event = {
            scheduleClassId: this.scheduleClass,
            eventDescription: this.eventDescription ? capitalizeFirstLetter(this.eventDescription) : "",
            eventBeginDateTime: new Date(this.eventBeginDateTime.getTime() - userTimezoneOffset),
            eventEndDateTime: new Date(this.eventEndDateTime.getTime() - userTimezoneOffset),
            eventOrganizerId: this.eventOrganizer,
            scheduleId: this.schedule,
            eventParticipants: this.objectListSterilize(this.eventParticipants),
            eventParticipantGroups: this.objectListSterilize(this.eventParticipantGroups),
            subgroupId: this.subgroupId,
            divideIntoGroups: this.divideIntoGroups,

            userEnrollment: this.userEnrollment,
            placeCount: this.placeCount,
          };

          if (this.eventId) {
            customAxios
                .put(
                    "/schedule/event/" + this.eventId,
                    event
                )
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.events, response.data.id);
                    this.events.splice(index, 1, response.data);
                    this.closeEventDialog()
                  } else if (response.status === 208) {
                    this.eventCollisions = response.data;
                    this.showEventCollisions = true;
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
               
          } else {
            customAxios
                .post("/schedule/event", event)
                .then((response) => {
                  if (response.status === 201) {
                    this.events.push(response.data);
                    this.closeEventDialog()
                  } else if (response.status === 208) {
                    this.eventCollisions = response.data;

                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
               
          }
        }
      });
    },
    closeEventDialog() {
      this.eventName = "";
      this.eventDescription = "";
      this.eventBeginDateTime = new Date();
      this.eventEndDateTime = null;
      this.eventOrganizer = {};
      this.eventParticipants = [];
      this.eventParticipantGroups = [];
      this.subgroupId = null;
      this.eventIsActive = false;
      this.schedule = {};
      //this.scheduleId = item.scheduleId;
      this.eventId = null;
      //this.eventLocation = item.eventLocation;
      this.scheduleClassId = null;
      this.scheduleClass = {};
      this.placeCount = 0;
      this.userEnrollment = false;
      this.eventEndNoDateAlert = false;
      this.eventBeginNoDateAlert = false;
      this.eventDateAlert = false;
      this.showEventDialog = false;
      this.divideIntoGroups = false;
    },
    eventLocationFilter(item, queryText) {
      return (
          item.locationName
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.locationDescription
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    getSelectedClass(classId) {
     
      this.showClassOptionsLoader= true;
      customAxios
          .get(
              "/schedule/class/" + classId
          )
          .then((response) => {
            if (response.status === 200) {
              if (this.activeSchedules.length === 1) {
                this.schedule = this.activeSchedules[0];
              }
              if (response.data) {
                if (response.data.classTeachers && response.data.classTeachers.length>0) {
                  this.eventOrganizer = response.data.classTeachers[0];
                }
                if (response.data.personGroups && response.data.personGroups.length>0) {
                  this.eventParticipantGroups.push(response.data.personGroups[0]);
                }
                this.selectedScheduleClass = response.data;
                console.log("events count: " + this.events.length)
              }
              this.showClassOptionsLoader= false;
              this.showClassOptions =true;


            } else {
              this.showClassOptionsLoader= false;
              alert("Что-то пошло не так");
              console.log(response);
            }
          })

    },
    generateEndDate() {
      let beginDateInMs = Date.parse(this.eventBeginDateTime);
      if (this.scheduleClass !== null) {
        if (this.scheduleClass.scheduleClassDuration > 0) {
          let durationInMs = this.scheduleClass.scheduleClassDuration * 60000;
          let endDateInMs = beginDateInMs + durationInMs;
          this.eventEndDateTime = new Date(endDateInMs);
        } else {
          let durationInMs = 40 * 60000;
          let endDateInMs = beginDateInMs + durationInMs;
          this.eventEndDateTime = new Date(endDateInMs);
        }
      } else {
        let durationInMs = 40 * 60000;
        let endDateInMs = beginDateInMs + durationInMs;
        this.eventEndDateTime = new Date(endDateInMs);
      }
    },
    cloneEvents() {
      this.cloneBeginDateAlert =
          this.cloneBeginDate === null || this.cloneBeginDate === undefined;

      this.cloneEndDateAlert =
          this.cloneEndDate === null || this.cloneEndDate === undefined;

      if (!this.cloneBeginDateAlert && !this.cloneEndDateAlert) {
        this.cloneEndNoDateAlert =
            Date.parse(this.cloneBeginDate) >= Date.parse(this.cloneEndDate);
      }

      if (
          !this.cloneBeginDateAlert &&
          !this.cloneEndDateAlert &&
          !this.cloneEndNoDateAlert
      ) {
        this.cloneAnimation = true;

        const userTimezoneOffset =
            this.cloneBeginDate.getTimezoneOffset() * 60000;

        let eventsClone = {
          sourceBeginDate: new Date(
              this.cloneBeginDate.getTime() - userTimezoneOffset
          ),
          sourceEndDate: new Date(
              this.cloneEndDate.getTime() - userTimezoneOffset
          ),
          weekCount: 1,
        };

        customAxios
            .post("/schedule/clone", eventsClone)
            .then((response) => {
              if (response.status === 202) {
                this.events.push(response.data);
                this.cloneBeginDate = "";
                this.cloneEndDate = "";
                this.cloneBeginDateAlert = false;
                this.cloneEndDateAlert = false;
                this.cloneEndNoDateAlert = false;
                this.showCloneDialog = false;
                alert("События успешно склонированы!");
                this.cloneAnimation = false;
              } else {
                this.cloneAnimation = false;
                alert("Что-то пошло не так");
                console.log(response);
              }
            })
          
      }
    },
    closeLogDialog(){
      this.logDialog = false;
      this.logDateRangeAlert = [];

    },
    createLogs() {
     
      if (this.logDateRange.length > 1) {
        this.createLogAnimation = true;
        const userTimezoneOffset = this.logDateRange[0].getTimezoneOffset() * 60000;
        let newRange = {
          beginDate: new Date(this.logDateRange[0].getTime() - userTimezoneOffset),
          endDate: new Date(this.logDateRange[1].getTime() - userTimezoneOffset)
        }

        customAxios
            .post("/teacher/log/on_week", newRange)
            .then((response) => {
              if (response.status === 201) {
                alert("Журналы успешно созданы!")
                this.createLogAnimation = false;
                this.closeLogDialog();

              } else {
                alert("Что-то пошло не так");
                console.log(response);
              }
            })
           
      } else {
        alert("Не все поля заполнены!")
      }


    },
    closeCloneEventDialog() {
      this.showCloneDialog = false;
    },
    closeDayCloneEventDialog() {
      this.showCloneDayDialog = false;
    },
    getEvents() {
      
      let req = {
        beginDateTime: this.selectedScheduleRange[0],
        endDateTime: this.selectedScheduleRange[1],
        scheduleId: this.selectedSchedule.id,
      };
      if (this.selectedSchedule && this.selectedScheduleRange.length > 1) {
        console.log("begin")
        this.showEventsList = false;
        this.showLoader = true;
        customAxios
            .post(
                "/schedule/event/by_filters",
                req
            )
            .then((response) => {
              if (response.status === 200) {
                this.events = response.data;
                this.showLoader = false;
                this.showEventsList = true;
              } else {
                alert("Что-то пошло не так");
                this.showLoader = false;
              }
            })
            .catch((error) => {
              this.showLoader = false;
              console.log("error: " + error);
            });
      } else {
        alert("Не все поля заполнены!")
      }
    },
    eventScheduleClassFilter(item, queryText) {
      return (
          item.scheduleClassName
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.scheduleClassDescription
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    editEvent(item) {
      this.eventName = item.eventName;
      this.eventDescription = item.eventDescription;
      this.eventBeginDateTime = new Date(item.eventBeginDateTime);
      this.eventEndDateTime = new Date(item.eventEndDateTime);
      this.eventOrganizer = item.eventOrganizer;
      this.eventParticipants = item.eventParticipants;
      this.eventParticipantGroups = item.eventParticipantGroups;
      this.subgroupId = item.subgroupId;
      this.schedule = item.schedule;
      //this.scheduleId = item.scheduleId;
      this.eventId = item.id;
      //this.eventLocation = item.eventLocation;
      this.scheduleClassId = item.scheduleClassId;
      this.scheduleClass = item.scheduleClass;
      this.placeCount = item.placeCount;
      this.userEnrollment =  item.userEnrollment ===null ? false : item.userEnrollment;
      this.divideIntoGroups = item.divideIntoGroups ===null ? false : item.divideIntoGroups;
      this.showEventDialog = true;
    },
    deleteEvent(item) {
     
      if (confirm("Вы точно хотите удалить событие?")) {
        customAxios
            .delete("/schedule/event/" + item.id)
            .then((response) => {
              if (response.status === 202) {
                this.events.splice(this.events.indexOf(item), 1);
              } else {
                console.log(response);
              }
            })
           
      }
    },
  },
  created: function () {
    this.showLoader = true;
   
    function getUsers(customAxios) {
      return customAxios.get("/user/minimal");
    }
    function getUserGroups(customAxios) {
      return customAxios.get("/user_group");
    }
    function getScheduleClasses(customAxios) {
      return customAxios.get("/schedule/class");
    }

    function getSchedule(customAxios) {
      return customAxios.get("/schedule");
    }

    Promise.all([
      getUsers(customAxios),
      getUserGroups(customAxios),
      getScheduleClasses(customAxios),
      getSchedule(customAxios),
    ]).then((results) => {
      if (results[0].status === 200) {
        this.users = results[0].data;
      }
      if (results[1].status === 200) {
        this.userGroups = results[1].data;
      }
      if (results[2].status === 200) {
        this.scheduleClasses = results[2].data;
      }
      if (results[3].status === 200) {
        this.schedules = results[3].data;
        if(!!this.schedules && this.schedules.length>0){
          this.schedules.forEach(s => {
            if(s.isActive){
              this.selectedSchedule = s;
            }})
        }
      }
      this.showLoader = false;
    })
    
  },
  setup() {
    return {};
  },
};
function dynamicSort(property, sortOrder) {
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result;
    if (property === "eventName") {
      result =
          a.scheduleClass.scheduleClassName < b.scheduleClass.scheduleClassName
              ? -1
              : a.scheduleClass.scheduleClassName >
              b.scheduleClass.scheduleClassName
                  ? 1
                  : 0;
      return result * sortOrder;
    } else if (property === "eventOrganizer") {
      result =
          a.eventOrganizer.firstname < b.eventOrganizer.firstname
              ? -1
              : a.eventOrganizer.firstname > b.eventOrganizer.firstname
                  ? 1
                  : 0;
      return result * sortOrder;
    } else {
      result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    }
  };
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

</script>

<style lang="scss">
.form-element {
  box-shadow: $shadow;
  display: grid;
  grid-template-columns: 40% 1fr;
  overflow: hidden;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: $gradient-1;
  }

  &__card.v-card {
    box-shadow: none;
    padding: 30px;
  }

  &__title.v-card-title {
    color: $main;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
  }

  .v-text-field__input {
    input::placeholder {
      font-size: 18px;
      line-height: 22px;
    }

    label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #6e6d94;
    }

    .v-field__outline {
      color: rgba(#6e6d94, 0.5);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;

    &__img {
      display: none;
    }
  }
}
</style>
