
import { authHeader } from '../util/auth-headers'
import {router} from "@/service/router";
export const apiUrl = 'https://school-backend.eurekacenter.ru/api'
//export const apiUrl = 'https://school-dev.geekbase.ru/api'
//export const apiUrl = 'http://localhost:666/api'
export const userService = {
  getAll,
  login,
  handleResponse,
  login_by_uuid
}



function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  console.log('auth headers: ' + requestOptions.headers)
  return fetch(`${apiUrl}/user`, requestOptions).then(handleResponse)
}
function handleResponse (response) {

      if (response.status === 401) {
        this.$store.dispatch('authentication/logout')
        this.$store.dispatch('alert/clear')
        localStorage.clear()
          console.log("fu")
        router.push('/').then(r => {console.log("error: " + r)})

    } else {
      return response
    }
}

function loginHandleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      console.log("login error: "+  error)
      return Promise.reject(error)
    } else {
      return data
    }
  })
}

function login(username, password) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  };

  return fetch(`${apiUrl}/auth/signin`, requestOptions)

.then(loginHandleResponse)
      .then(user => {
       if (user.token) {
          localStorage.setItem('user', JSON.stringify(user));
        }
       return user;
      });
}
function login_by_uuid(uuid) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uuid })
    };
    let url = apiUrl + "/auth/ololo"
    console.log("url: " + url);

    return fetch(url, requestOptions)
        .then(loginHandleResponse)
        .then(user => {
            console.log("user: " + JSON.stringify(user))
            if (user.token) {
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}
