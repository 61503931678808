<template>
  <tr>
    <td class="text-center">
      {{ getDateFormatWithTime(item.consultationDate) }}
    </td>
    <td>
      <p v-if="item.consultant">
        {{ item.consultant.lastname }}
        {{ item.consultant.firstname }}
        {{ item.consultant.sectname }}
      </p>
    </td>
    <td>
      <p v-if="item.parent">
        {{ item.parent.lastname }}
        {{ item.parent.firstname }}
        {{ item.parent.sectname }}
      </p>
      <p v-else>не указан</p>
    </td>
    <td class="text-center">
      <p>{{ item.consultationDuration }}</p>
    </td>
    <td>
      <v-select
        v-model="status"
        :items="statuses"
        placeholder="Статус"
        class="cell-input"
        hide-details
        variant="outlined"
        density="compact"
        bg-color="white"
      />
    </td>

    <td>
      <router-link
        :to="{
          name: 'consultation-edit',
          params: { id: item.id },
          query: {
            startDate: $route.query.startDate,
            endDate: $route.query.endDate,
          },
        }"
        class="bordered-item ma-1"
        >Редактировать</router-link
      >
    </td>
  </tr>
</template>

<script setup>
import { defineProps, ref, watch } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { useConsultationsData } from "@/composables/consultations";
const { getDateFormatWithTime } = useDatesChanger();
const { statuses, changeStatus } = useConsultationsData();

const props = defineProps({ item: { type: Object, required: true } });
const status = ref(props.item.parentConsultationStatus);

watch(status, async (v) => {
  changeStatus({
    status: v,
    id: props.item.id,
  });
});
</script>
