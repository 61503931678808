<template>
  <div>
    <AppButton @click="$router.back"  class="bordered-item mb-3">Назад</AppButton>

    <PageTitle
      :title="`Оценочные листы ${isForeignJournal ? 'Английский' : ''}`"
    />

    <FilterByDay />

    <div v-if="$route.query.dayId" class="d-flex my-3 justify-end">
      <router-link
        :to="{
          name: isForeignJournal ? 'foreign-sheet-create' : 'sheet-create',
          query: {
            filterByJournalId: $route.query.filterByJournalId,
            dayId: $route.query.dayId,
          },
        }"
        class="accent-button"
        >Создать оценочный
      </router-link>
    </div>
    <StatusWrapper
      :loading-status="loadingStatus"
      :is-data-existed="ratingSheets.length > 0"
    >
      <ul class="list">
        <li
          v-for="l in ratingSheets"
          :key="l.id"
          class="gradient-list-item mt-4"
        >
          <router-link
            class="gradient-list-item__content"
            :to="{
              name: isForeignJournal ? 'foreign-sheet-edit' : 'sheet-edit',

              query: {
                filterByJournalId: $route.query.filterByJournalId,
                dayId: $route.query.dayId,
                listId: l.id,
              },
            }"
          >
            <h4>
              {{
                isForeignJournal ? l.listDescription : l.skillListDescription
              }}
            </h4>
          </router-link>
        </li>
      </ul>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { useJournalData } from "@/composables/journalData";
import { storeToRefs } from "pinia";

import { onUnmounted, watch } from "vue";
import { useRoute } from "vue-router";

import StatusWrapper from "@/components/StatusWrapper.vue";
import PageTitle from "@/elements/PageTitle.vue";
import FilterByDay from "./FilterByDay.vue";
import AppButton from "@/elements/AppButton.vue";

const { ratingSheets, isForeignJournal } = storeToRefs(
  useSubjectsJournalStore()
);
const { setRatingSheets } = useSubjectsJournalStore();
const { updateRatingSheets, loadingStatus } = useJournalData();
const route = useRoute();

watch(() => route.query.dayId, updateRatingSheets, { immediate: true });

onUnmounted(() => {
  setRatingSheets([]);
});
</script>
