import { progress, schoolLife, schedule, usersReports } from "./common-links";

const consultations = {
  type: "link",
  title: "Консультации",
  icon: "chat-icon",
  name: "consultations-parent-list",
};

export const parent = [
  consultations,
  schedule,
  ...progress,
  ...schoolLife,
  ...usersReports,
];
