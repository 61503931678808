<template>
  <div v-if="children.length > 1">
    <v-label>Ваш основной ребёнок</v-label>
    <v-select
      v-model="child"
      :items="children"
      variant="outlined"
      placeholder="Ребенок"
      item-value="value"
      item-title="title"
      density="compact"
      hide-details
      bg-color="white"
    ></v-select>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useParentData } from "@/composables/parent";
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";

const { getUser, children } = useParentData();
const { mainChild } = storeToRefs(useAuthStore());
const { setMainChild } = useAuthStore();

const child = ref(mainChild.value);

watch(child, setMainChild);
watch(children, setChild, { deep: true });

onMounted(getUser);

function setChild() {
  if (!children.value.length) {
    return;
  }

  const isExisted =
    mainChild.value &&
    children.value.findIndex((v) => v.value === Number(mainChild.value));

  child.value = isExisted ? Number(mainChild.value) : children.value[0].value;
}
</script>
