<template>
  <div class="c-editor-consultations">
    <router-link
      :to="{ name: 'admin-consultations-list' }"
      class="bordered-item mb-3"
      >Назад</router-link
    >
    <CustomForm
      title="слота для консультаций"
      @create="create"
      @remove="remove"
      :save-is-disable="isDisable"
    >
      <div
        :class="{
          error: v$.consultant.$errors.length,
          success:
            !v$.consultant.$errors.length &&
            v$.consultant.$model &&
            v$.consultant.$dirty,
          'd-flex flex-wrap align-end': slotById !== null,
        }"
        class="form__item"
      >
        <v-label :class="slotById === null ? 'mb-2' : 'mr-2'"
          >Консультирующий сотрудник</v-label
        >
        <SelectorOfEmployees
          v-model="v$.consultant.$model"
          :readonly="!!slotById"
          :variant="!!slotById ? 'plain' : 'outlined'"
          class="hide-arrow"
        />

        <div
          class="input-errors"
          v-for="error of v$.consultant.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div
        :class="{
          error: v$.date.$errors.length,
          success: !v$.date.$errors.length && v$.date.$model && v$.date.$dirty,
        }"
        class="form__item"
      >
        <v-label class="mb-2">Дата слота консультации</v-label>

        <DatePicker
          v-model="v$.date.$model"
          autoApply
          locale="ru"
          class="w-100"
          :disabled-week-days="[6, 0]"
          :format="getOneDayUIFormat"
          :enable-time-picker="false"
          placeholder="Введите дату"
          :min-date="new Date()"
        />
        <div
          class="input-errors"
          v-for="error of v$.date.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div
        :class="{
          error: v$.time.$errors.length,
          success: !v$.time.$errors.length && v$.time.$model && v$.time.$dirty,
        }"
        class="form__item"
      >
        <v-label class="mb-2">Время начала</v-label>

        <TimeSelector v-model="time" />
        <div
          class="input-errors"
          v-for="error of v$.time.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div
        :class="{
          error: v$.duration.$errors.length,
          success:
            !v$.duration.$errors.length &&
            v$.duration.$model &&
            v$.duration.$dirty,
        }"
        class="form__item"
      >
        <v-label class="mb-2">Продолжительность (в минутах)</v-label>
        <v-select
          :items="[15, 30, 60]"
          v-model="v$.duration.$model"
          density="compact"
          variant="outlined"
          placeholder="Выберите продолжительность"
        ></v-select>
        <div
          class="input-errors"
          v-for="error of v$.duration.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </CustomForm>
  </div>
</template>

<script setup>
import { required, helpers } from "@vuelidate/validators";
import { computed, ref, watch } from "vue";
import { customAxios } from "@/service/customAxios";

import { usePopupStore } from "@/store/popup";
import { useDatesChanger } from "@/composables/datesChanger";
import { useRoute, useRouter } from "vue-router";

import { storeToRefs } from "pinia";
import { useConsultationsStore } from "@/store/consultations";
import { areTwoDatesEqual } from "@/util/common";

import CustomForm from "@/components/CustomForm.vue";
import SelectorOfEmployees from "@/components/inputs/SelectorOfEmployees.vue";
import useVuelidate from "@vuelidate/core";
import TimeSelector from "../TimeSelector.vue";

const rules = {
  date: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  duration: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  consultant: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  time: {
    required: helpers.withMessage("Обязательное поле", required),
  },
};

const route = useRoute();
const router = useRouter();

const { showError, showToastPopup, showConfirmPopup, closePopup } =
  usePopupStore();
const { createDateWithTime, getOneDayUIFormat, getTimeFromDate } =
  useDatesChanger();
const { slotById } = storeToRefs(useConsultationsStore());

const date = ref(null);
const time = ref(null);
const duration = ref(30);
const consultant = ref(null);

const dateWithTime = computed(() => {
  if (date.value === null || time.value === null) {
    return null;
  }
  const d = date.value;

  return createDateWithTime({ date: d, time: time.value });
});

const v$ = useVuelidate(rules, {
  date: date,
  duration: duration,
  consultant: consultant,
  time: time,
});

const isDisable = computed(() => {
  v$.value.$touch();
  return v$.value.$invalid;
});

watch(slotById, setCurrentData, { immediate: true });

function setCurrentData() {
  if (slotById.value === null) {
    return;
  }

  duration.value = slotById.value.consultationDuration;
  consultant.value = slotById.value.consultantId;
  date.value = slotById.value.consultationDate;
  time.value = getTimeFromDate(slotById.value.consultationDate);
}

watch(dateWithTime, (v) => {
  if (!slotById.value) {
    return;
  } else if (
    !areTwoDatesEqual(v, slotById.value.consultationDate) ||
    time.value !== getTimeFromDate(slotById.value.consultationDate)
  ) {
    save();
  }
});

watch(duration, (v) => {
  if (!slotById.value) {
    return;
  } else if (v !== slotById.value.consultationDuration) {
    save();
  }
});

async function save() {
  if (v$.value.$invalid) {
    return;
  }
  try {
    await saveHandler();
    showToastPopup("Слот изменен");
  } catch (error) {
    showError(error);
  }
}

function saveHandler() {
  return customAxios.put(`/parent_consultation/admin/${slotById.value.id}`, {
    consultationDate: dateWithTime.value,
    consultationDuration: duration.value,
    consultantId: consultant.value,
    parentConsultationStatus: slotById.value.parentConsultationStatus,
  });
}

async function create() {
  try {
    await createHandler();
    showToastPopup("Слот создан");
    router.push({ name: "admin-consultations-list" });
  } catch (error) {
    showError(error);
  }
}

function createHandler() {
  return customAxios.post("/parent_consultation/admin/create_single_slot", {
    consultationDate: dateWithTime.value,
    consultationDuration: duration.value,
    consultantId: consultant.value,
  });
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();
      await removeHandler();
      showToastPopup("Консультация удалена");
      router.push({ name: "admin-consultations-list" });
    } else {
      closePopup();
    }
  } catch (error) {
    showError(error);
  }
}

function removeHandler() {
  return customAxios.delete(`/parent_consultation/admin/${route.params.id}`);
}

async function customConfirm() {
  return showConfirmPopup("Удалить слот?");
}
</script>

<style lang="scss">
.c-editor-consultations .v-field__append-inner {
  display: none;
}
</style>
