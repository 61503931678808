<template>
  <div>
    <router-link
      :to="{ name: 'admin-consultations-list' }"
      class="bordered-item mb-3"
      >Назад</router-link
    >

    <PageTitle title="Добавление нескольких слотов для консультаций" />

    <div class="page-wrapper my-3">
      <v-label class="mb-2">Консультирующий сотрудник</v-label>
      <SelectorOfEmployees v-model="consultantId" />

      <v-label class="mb-2 mt-5">Продолжительность (в минутах)</v-label>
      <v-select
        :items="[15, 30, 60]"
        v-model="duration"
        density="compact"
        variant="outlined"
        placeholder="Выберите продолжительность"
      ></v-select>
    </div>

    <div class="page-wrapper">
      <h3 class="mb-3">Добавление свободного слота</h3>
      <v-label class="mb-2">Дата слота консультации</v-label>

      <DatePicker
        v-model="date"
        autoApply
        inline
        locale="ru"
        class="w-100"
        :disabled-week-days="[6, 0]"
        :format="getOneDayUIFormat"
        :enable-time-picker="false"
        placeholder="Введите дату"
        :min-date="new Date()"
        focus-start-date
      />

      <v-label class="mb-2 mt-5">Время начала</v-label>

      <TimeSelector v-model="time" />
    </div>

    <div class="d-flex justify-center flex-wrap my-3">
      <AppButton
        :disabled="isDisabled"
        @click="addNewSlot"
        class="bordered-item ma-1"
        >Добавить слот</AppButton
      >
    </div>

    <CSlotsTable class="my-3" />

    <div class="d-flex justify-center flex-wrap my-3">
      <AppButton
        :disabled="slots.length === 0"
        @click="create"
        class="bordered-item ma-1"
        >Сохранить и закончить</AppButton
      >
    </div>
  </div>
</template>

<script setup>
import { useDatesChanger } from "@/composables/datesChanger";
import { useConsultationsStore } from "@/store/consultations";
import { computed, ref } from "vue";

import { storeToRefs } from "pinia";
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";

import { useRouter } from "vue-router";

import SelectorOfEmployees from "@/components/inputs/SelectorOfEmployees.vue";
import AppButton from "@/elements/AppButton.vue";
import PageTitle from "@/elements/PageTitle.vue";

import CSlotsTable from "./CSlotsTable.vue";
import TimeSelector from "../TimeSelector.vue";

const { showToastPopup, showError } = usePopupStore();
const { getOneDayUIFormat, createDateWithTime } = useDatesChanger();
const { addToSlots } = useConsultationsStore();
const { slots } = storeToRefs(useConsultationsStore());

const router = useRouter();

const consultantId = ref(null);
const duration = ref(30);
const time = ref(null);
const date = ref(null);

const isDisabled = computed(() => {
  return !time.value || !date.value || !duration.value || !consultantId.value;
});

const dateWithTime = computed(() => {
  if (date.value === null || time.value === null) {
    return null;
  }
  return createDateWithTime({ date: date.value, time: time.value });
});

async function create() {
  try {
    await createHandler();
    showToastPopup("Слоты созданы");
    router.push({ name: "admin-consultations-list" });
  } catch (error) {
    showError(error);
  }
}

async function createHandler() {
  return customAxios.post(
    "/parent_consultation/admin/create_slots_by_data_set",
    {
      consultationDateSet: slots.value.map((v) => v.consultationDate),
      consultantId: consultantId.value,
      consultationDuration: duration.value,
    }
  );
}

function addNewSlot() {
  addToSlots({
    consultantId: consultantId.value,
    consultationDate: dateWithTime.value,
    consultationDuration: duration.value,
  });
  reset();
}

function reset() {
  time.value = null;
  date.value = null;
}
</script>
