<template>
  <div>
    <ol v-if="modelValue.length" class="pl-5">
      <li v-for="c in props.modelValue" :key="c.id" class="my-3">
        <ClosableChip
          :data="c.competenceName"
          @closeChip="deleteCompetence(c.id)"
          class="white-bg"
        />
      </li>
    </ol>

    <AppButton @click="isCompetenceAddingShowed = true" class="accent-button">
      Добавить
    </AppButton>

    <v-dialog v-model="isCompetenceAddingShowed">
      <CompetenceList
        @editChildsCompetence="editChildsCompetence"
        :addedItems="modelValue"
      />
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";

import CompetenceList from "./CompetenceList.vue";
import AppButton from "@/elements/AppButton.vue";
import ClosableChip from "./ClosableChip.vue";

const props = defineProps(["modelValue", "title"]);
const emits = defineEmits(["update:modelValue"]);

const isCompetenceAddingShowed = ref(false);

function editChildsCompetence(data) {
  emits("update:modelValue", data);
  isCompetenceAddingShowed.value = false;
}

function deleteCompetence(id) {
  emits(
    "update:modelValue",
    props.modelValue.filter((v) => v.id !== id)
  );
}
</script>
