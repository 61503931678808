import { useUserData } from "@/composables/userData";
import { customAxios } from "@/service/customAxios";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    profile: JSON.parse(localStorage.getItem("user-profile")),
    mainRole: JSON.parse(localStorage.getItem("user-role")),
    mainChild: JSON.parse(localStorage.getItem("ss-main-child")),
  }),
  getters: {
    roles(state) {
      if (!state.user) {
        return [];
      }

      const dict = {
        ROLE_PARENT: "Родитель",
        ROLE_ADMIN: "Админ",
        ROLE_I_STUDIO_MASTER: "Я-студия мастер",
        ROLE_MASTER: "Мастер",
        ROLE_TUTOR: "Тьютор",
        ROLE_TEACHER: "Учитель",
        ROLE_CHILD: "Ребёнок",
      };

      const roles = [];

      state.user.roles.forEach((v) => {
        if (v.toUpperCase().includes("USER")) {
          return;
        } else if (dict[v] !== undefined) {
          roles.push({
            title: dict[v],
            value: v,
          });
        }
      });

      return roles;
    },
    userId(state) {
      if (!state.user) {
        return null;
      }
      return state.user.id;
    },
    isTutor() {
      if (!this.user) {
        return false;
      }

      return this.mainRole === "ROLE_TUTOR";
    },
    isAdmin() {
      if (!this.user) {
        return false;
      }

      return this.mainRole === "ROLE_ADMIN";
    },
    isParent() {
      if (!this.user) {
        return false;
      }
      return this.mainRole === "ROLE_PARENT"
    },

    isChild() {
      if (!this.user) {
        return false;
      }

      return  this.mainRole === "ROLE_CHILD"
    },
    isMaster() {
      if (!this.user) {
        return false;
      }

      return this.mainRole === "ROLE_MASTER";
    },

    userGlobalRole(state) {
      if (!state.user) {
        return null;
      }

      const isUser = ["ROLE_PARENT", "ROLE_CHILD"].indexOf(this.mainRole) >= 0;

      return isUser ? "user" : "employee";
    },
  },
  actions: {
    setMainChild(id) {
      console.log(id);

      this.mainChild = id;
      localStorage.setItem("ss-main-child", this.mainChild);
    },
    setMainRole(role) {
      this.mainRole = role;
      localStorage.setItem("user-role", JSON.stringify(this.mainRole));
    },
    logout() {
      const { resetUserLocalStorage } = useUserData();

      customAxios.setUser(null);
      this.reset();
      resetUserLocalStorage();
    },
    reset() {
      this.user = null;
      this.mainRole = null;
      this.profile = null;
    },
    setUser(data) {
      this.user = data;
      localStorage.setItem("user", JSON.stringify(this.user));
      customAxios.setUser(data);
    },
    setProfile(data) {
      this.profile = data;
      localStorage.setItem("user-profile", JSON.stringify(this.profile));
    },
    setNewTokens(newAccessToken, newRefreshToken) {
      this.user.token = newAccessToken;
      this.user.refreshToken = newRefreshToken;
    },
  },
});
