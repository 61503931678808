<template>
  <div class="closable-chip ma-1">
    <div class="closable-chip__content">
      <span>{{ data }}</span>
      <AppButton @click="$emit('closeChip')">
        <CIcon icon="cross-icon" />
      </AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";

export default {
  emits: ["closeChip"],
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  setup() {},
  components: { CIcon, AppButton },
};
</script>

<style lang="scss">
.closable-chip {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 4px;

  button {
    border: 2px solid $error;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba($error, 0.3);
    }
  }

  svg {
    width: 25px;
    height: 25px;
    margin: 0;
    stroke: $error;
    stroke-width: 2px;
  }

  &__content {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 8px;
    align-items: center;
    justify-content: space-between;
  }

  span {
    white-space: wrap;
  }
}
</style>
