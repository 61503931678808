<template>
  <div>
    <h2 class="text-center mb-5">Записи на консультации</h2>

    <router-link :to="{ name: 'consultations-parent-list' }" class="link">
      <div>
        <ul v-if="onlyConfirmed.length" class="pl-5 list">
          <li v-for="s in onlyConfirmed" :key="s.id" class="my-2 page-wrapper">
            <strong
              >{{ getDateFormatWithTime(s.consultationDate) }} ({{
                s.consultationDuration
              }}
              мин)</strong
            >
            -

            {{ s.consultant.lastname }} {{ s.consultant.firstname }}
            {{ s.consultant.secname }}
          </li>
        </ul>
        <h3 v-else class="text-center page-wrapper">Записей не ожидается</h3>
      </div>
    </router-link>
  </div>
</template>

<script setup>
import { useConsultationsData } from "@/composables/consultations";
import { useDatesChanger } from "@/composables/datesChanger";
import { computed, onMounted } from "vue";

const { getMyNewBookings, list } = useConsultationsData();
const { getDateFormatWithTime } = useDatesChanger();

onMounted(getMyNewBookings);

const onlyConfirmed = computed(() => {
  if (list.value.length === 0) {
    return [];
  }

  return list.value.filter(
    (v) => v.parentConsultationStatus === "CONSULTATION_BOOKED_CONFIRMED"
  );
});
</script>
