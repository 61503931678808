<template>
  <div>
    <div v-if="$route.path.includes('/metrics')">
      <PageTitle :title="title" />

      <div v-if="isParent" class="page-wrapper mb-3">
        <div class="g-col g-col--3-1fr">
          <FilterByChild />
        </div>
      </div>
    </div>

    <StatusWrapper :loadingStatus="loading" :isDataExisted="chartData !== null">
      <div class="page-wrapper">
        <div v-if="visible" class="py-1 text-center">
          <p class="text-deep-purple-lighten-3 font-weight-bold">
            Уровни ребёнка: {{ fetched[0].child.firstname }}
            {{ fetched[0].child.lastname }}
          </p>
        </div>
        <h3 v-if="!$route.path.includes('/metrics')" class="mb-3">
          {{ title }}
        </h3>
        <OnlyOnDesktop>
          <div class="mt-3">
            <Bar :data="chartData"></Bar>
          </div>
        </OnlyOnDesktop>

        <div class="mt-5">
          <div class="d-flex justify-space-between flex-wrap mb-4">
            <p class="text-deep-purple-lighten-3 font-weight-bold mr-2">
              Данные графика в форме таблицы:
            </p>
            <AppButton v-if="!editMode" v-on:click="editMode=!editMode" class="gradient-button">
              <p >Редактировать</p>
            </AppButton>
            <AppButton v-else @click="updatechartData"  class="gradient-button">
              <p>Закончить редактирование</p>
            </AppButton>
          </div>

          <div class="table-default">
            <table>
              <thead>
                <th></th>
                <th v-for="(s, i) in tableChartData[0].results" :key="i">
                  {{ s.label }}
                </th>
              </thead>
              <tbody v-if="editMode">
                <LevelsChartTableRow
                  v-for="(l, i) in fetched"
                  :key="i"
                  :item="l"
                />
              </tbody>
              <tbody v-else>
                <tr v-for="(l, i) in fetched" :key="i">
                  <td>{{ l.scheduleClass.scheduleClassName }}</td>
                  <td>{{ l.myLevel }}</td>
                  <td>
                    <p class="text-deep-purple font-weight-bold text-right">
                      {{ l.teacherLevel }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { Bar } from "vue-chartjs";
import { customAxios } from "@/service/customAxios";
import { computed, onMounted, ref, watch, defineProps } from "vue";

import { useAuthStore } from "@/store/auth";
import { usePopupStore } from "@/store/popup";
import { useRoute } from "vue-router";

import { pushFilterToURL } from "@/service/router";

import PageTitle from "@/elements/PageTitle.vue";
import OnlyOnDesktop from "@/components/OnlyOnDesktop.vue";

import AppButton from "@/elements/AppButton.vue";
import LevelsChartTableRow from "./LevelsChartTableRow.vue";
import FilterByChild from "@/components/filtersElements/FilterByChild.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";

const title = "Ожидаемые и текущие уровни освоения предметов";

const props = defineProps({ childId: { type: String, required: false }  });

const route = useRoute();
const { isParent, userId, isChild } = useAuthStore();
const { showError, showWarningPopup } = usePopupStore();

const editMode = ref(false);
const chartData = ref(null);
const fetched = ref([]);
const loading = ref("nothing");

const id = computed(() => {
  return props.childId ? props.childId : route.query.filterByChildId;
});


const visible = computed(
  () => fetched.value.length && isParent && route.path.includes("/metrics")
);

const tableChartData = computed(() => {
  if (!chartData.value) {
    return;
  }

  const titles = chartData.value.labels.map((v) => ({ title: v }));
  const titlesWithData = titles.map((t, i) => {
    const results = chartData.value.datasets.map((r) => ({
      ...r,
      data: r.data[i],
    }));

    return {
      ...t,
      results,
    };
  });
  return titlesWithData;
});
function updatechartData(){
  editMode.value = false;
   getMetrics();

}

watch(() => id.value, getMetrics);

onMounted(async () => {
  if (isChild) {
    pushFilterToURL({ name: "filterByChildId", value: userId });
  }
  getMetrics();
});

async function getMetrics() {
  if (!id.value) {
    return;
  }
  loading.value = "loading";

  try {
    const r = await getMetricsHandler();

    if (r.status === 207) {
      showWarningPopup(r.data.message);
    } else {
      setMetrics(r.data);
      fetched.value = r.data;
    }
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getMetricsHandler() {
  return customAxios.get("/child/metric/by_child/" + id.value);
}

function formatDate(dateString){
  if(formatDate){
    let formatter = new Intl.DateTimeFormat("ru", {
        // weekday: "long",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      return formatter.format(Date.parse(dateString));
  } else {
    return
  }
}
     

function setMetrics(data) {
  if (!data) {
    return;
  }

  const labelSet = [];
  const childDataSet = [];
  const teacherDataSet = [];
  let lastDate = "";

  data.forEach((v) => {
    labelSet.push(v.scheduleClass.scheduleClassName);
    childDataSet.push(v.myLevel);
    teacherDataSet.push(v.teacherLevel);
    lastDate = v.lastUpdateDateTime;
  });

  chartData.value = {
    labels: labelSet,
    datasets: [
      {
        label:
          "Планируемый уровень на" + " " + formatDate(lastDate) + "",
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        pointBackgroundColor: "rgba(255,99,132,1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(255,99,132,1)",
        data: childDataSet,
      },
      {
        label:
          "Фактический уровень на" + " " + formatDate(lastDate) + "",
        backgroundColor: "rgba(104,99,255,0.2)",
        borderColor: "rgb(115,99,255)",
        pointBackgroundColor: "rgb(99,208,255)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(99,107,255)",
        data: teacherDataSet,
      },
    ],
  };
}
</script>
