import {
  workshopsBookingList,
  schoolLife,
  russian,
  literature,
  workshopTimetable,
} from "./common-links";

const subjectsJournal = {
  type: "list",
  title: "Уч. журнал",
  icon: "book-icon",
  links: [
    {
      type: "link",
      title: "Таблица журнала",
      icon: "circle-icon",
      name: "teacher-journal",
    },
    {
      type: "link",
      title: "Все журналы",
      icon: "circle-icon",
      name: "teacher-journal-list",
    },
    {
      type: "link",
      title: "Все оценочные листы",
      icon: "circle-icon",
      name: "common-rating-sheets",
    },
  ],
};

const schedule = {
  type: "list",
  title: "Расписания",
  icon: "calendar-icon",

  links: [
    {
      type: "link",
      title: "Общее",
      icon: "circle-icon",
      name: "common-schedule",
    },
    {
      type: "link",
      title: "Сотрудника",
      icon: "circle-icon",
      name: "employee-schedule",
    },
    { ...workshopTimetable, title: "Мастерских" },
  ],
};

const iStudio = {
  type: "list",
  title: "Я - студия",
  icon: "table-icon",
  links: [
    {
      type: "link",
      title: "Таблица",
      icon: "circle-icon",
      name: "studio-table",
    },
    {
      type: "link",
      title: "Список",
      icon: "circle-icon",
      name: "studios",
    },
  ],
};

const workshops = {
  type: "list",
  title: "Мастерские",
  icon: "flask",
  links: [
    {
      type: "link",
      title: "Для мастера",
      icon: "circle-icon",
      name: "workshops-master-list",
    },
    {
      ...workshopsBookingList,
      title: "Бронирование",
    },
    { ...workshopTimetable, title: "Расписание" },
  ],
};

export const tutor = [
  {
    type: "title",
    title: "Тьютор",
  },
  {
    type: "link",
    title: "Мой класс",
    icon: "users-icon",
    name: "tutor-group",
  },
  {
    type: "link",
    title: "Журналы посещения",
    icon: "doc-text-icon",
    name: "attendance-journal-list",
  },
  {
    title: "Заметки",
    type: "list",
    icon: "note-icon",

    links: [
      {
        type: "link",
        title: "По детям",
        name: "tutor-child-notes",
        icon: "circle-icon",
      },
      {
        type: "link",
        title: "По классам",
        name: "group-notes-list",
        icon: "circle-icon",
      },
      {
        type: "link",
        title: "Из журналов",
        name: "child-journals-comments",
        icon: "circle-icon",
      },
      {
        type: "link",
        title: "Создание индивидуальной",
        name: "note-create",
        icon: "circle-icon",
      },
      {
        type: "link",
        title: "Создание общеклассовой",
        name: "group-note-create",
        icon: "circle-icon",
      },
    ],
  },
];

const reports = [
  { title: "Отчёты", type: "title" },
  {
    type: "list",
    title: "По мастерским",
    icon: "files-icon",
    links: [
      {
        type: "link",
        title: "Мастерские по классу",
        icon: "circle-icon",
        name: "tutor-workshop-reports",
      },
      {
        type: "link",
        title: "Запись на мастерские",
        icon: "circle-icon",
        name: "workshop-booking-report",
      },
      {
        type: "link",
        title: "По детям",
        icon: "circle-icon",
        name: "workshop-child-report",
      },
    ],
  },
  {
    type: "list",
    title: "По предметам",
    icon: "files-icon",
    links: [
      {
        type: "link",
        title: "Диктанты",
        icon: "circle-icon",
        name: "teacher_dictation_report",
      },
    ],
  },
  russian,
  literature,

  {
    type: "list",
    title: "По Я-студиям",
    icon: "files-icon",
    links: [
      {
        type: "link",
        title: "По студии",
        icon: "circle-icon",
        name: "i-studio-report-by-studio",
      },
      {
        type: "link",
        title: "По месяцу",
        icon: "circle-icon",
        name: "i-studio-report-by-month",
      },
      {
        type: "link",
        title: "По ребенку",
        icon: "circle-icon",
        name: "i-studio-report-by-child",
      },
    ],
  },

  {
    type: "list",
    title: "По Английскому",
    icon: "files-icon",
    links: [
      {
        type: "link",
        title: "Диктанты (по классу)",
        icon: "circle-icon",
        name: "english-dictations-by-group",
      },
      {
        type: "link",
        title: "Диктанты (по ребёнку)",
        icon: "circle-icon",
        name: "english-dictations-by-child",
      },
      {
        type: "link",
        title: "Контрольные (по критерию)",
        icon: "circle-icon",
        name: "english-by-skill-reports",
      },
      {
        type: "link",
        title: "Контрольные (по работе)",
        icon: "circle-icon",
        name: "english-by-work-reports",
      },
      {
        type: "link",
        title: "Контрольные (по ребенку)",
        icon: "circle-icon",
        name: "english-by-child-reports",
      },
    ],
  },
];

export const employes = [
  {
    type: "link",
    title: "Консультации",
    icon: "chat-icon",
    name: "employee-consultations-list",
  },
  schedule,
  subjectsJournal,
  iStudio,
  workshops,
  ...schoolLife,
  ...tutor,
  ...reports,
];
