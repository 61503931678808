export const workshopsBookingList = {
  type: "link",
  title: "Бронирование",
  icon: "circle-icon",
  name: "workshops-booking",
};

export const schoolLife = [
  { type: "title", title: "Школьная жизнь" },
  { type: "link", title: "Новости", icon: "news-icon", name: "news" },
  {
    type: "link",
    title: "Галерея",
    icon: "album-icon",
    name: "gallery-albums",
  },
  {
    type: "link",
    title: "Доска достижений",
    name: "common-cognations",
    icon: "trophy",
  },
];

export const russian = {
  type: "list",
  title: "Русский язык",
  icon: "files-icon",
  links: [
    {
      type: "link",
      title: "Уровень усвоения",
      icon: "circle-icon",
      name: "russian-literature-report",
      params: { subjectId: 53 },
    },

    {
      type: "link",
      title: "Диктанты",
      icon: "circle-icon",
      name: "russian-literature-dictations-report",
      params: { subjectId: 53 },
    },
  ],
};

export const literature = {
  type: "list",
  title: "Литература",
  icon: "files-icon",
  links: [
    {
      type: "link",
      title: "Уровень усвоения",
      icon: "circle-icon",
      name: "russian-literature-report",
      params: { subjectId: 25 },
    },

    {
      type: "link",
      title: "Диктанты",
      icon: "circle-icon",
      name: "russian-literature-dictations-report",
      params: { subjectId: 25 },
    },
  ],
};

export const workshopTimetable = {
  type: "link",
  name: "workshops-timetable",
  icon: "circle-icon",
};

const notes = {
    title: "Заметки",
    type: "list",
    icon: "note-icon",
  
    links: [
      {
        type: "link",
        title: "От тьютора",
        name: "user-notes-list",
        icon: "circle-icon",
      },
      {
        type: "link",
        title: "Из журналов",
        name: "child-journals-comments",
        icon: "circle-icon",
      },
    ],
  };

export const progress = [
  { type: "title", title: "Прогресс" },
  { type: "link", title: "Дневник", icon: "book-icon", name: "diary" },
  {
    type: "link",
    title: "Уровни в динамике",
    icon: "chart-bar",
    name: "metrics_by_subject",
  },
  {
    type: "link",
    title: "Ожидаемые и текущие уровни",
    icon: "chart-pie",
    name: "metrics",
  },
  notes,
];



const englishReports = {
  type: "list",
  title: "По Английскому",
  icon: "files-icon",
  links: [
    {
      name: "users-english-report",
      path: "/english-report",
      icon: "circle-icon",
      title: "Контрольные",
    },
    {
      type: "link",
      title: "Диктанты",
      icon: "circle-icon",
      path: "/english-report-dictations",
      name: "english-dictations-by-child",
    },
  ],
};

export const usersReports = [
  { type: "title", title: "Отчёты" },
  {
    type: "link",
    title: "Отчёт мастерские",
    icon: "files-icon",
    name: "workshop-child-report",
  },
  englishReports,
  russian,
  literature,
  {
    type: "link",
    title: "Я-студия",
    icon: "files-icon",
    name: "i-studio-report-by-child",
  },
];

export const schedule = {
    type: "list",
    title: "Расписания",
    icon: "calendar-icon",
  
    links: [
      {
        type: "link",
        title: "Школьное",
        icon: "circle-icon",
        name: "schedule",
      },
      { ...workshopTimetable, title: "Мастерских" },
    ],
  };
