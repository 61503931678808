<template>
  <div>
    <PageTitle title="Консультации" />

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь отобразятся ваши окошки для записи.</p>
        <v-label> Обязательно выставьте фильтры</v-label>
        <ol class="pl-5">
          <li><strong>период</strong></li>
        </ol>

        <p class="my-3">
          Доступ к <strong>протоколам</strong> появится после того как вы
          переведете консультацию в статус Завершена.
        </p>
      </template>
    </TogglerOfWrapper>

    <div class="g-col g-col--2-1fr">
      <FilterByPeriod />
    </div>

    <StatusWrapper
      :is-data-existed="consultations.length > 0"
      :loading-status="loadingStatus"
    >
      <div class="page-wrapper my-3">
        <div class="table-wrapper">
          <table class="table-custom">
            <thead>
              <tr class="table-custom__headers">
                <th>ID</th>
                <th>Дата</th>
                <th>Время</th>
                <th>Родитель</th>
                <th>Длительность (мин)</th>
                <th>Статус</th>
                <th>Смена статуса</th>
                <th>Протоколы</th>
              </tr>
            </thead>
            <tbody>
              <CRow v-for="(s, i) in consultations" :key="i" :item="s" />
            </tbody>
          </table>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { useConsultationsStore } from "@/store/consultations";
import { storeToRefs } from "pinia";

import FilterByPeriod from "@/components/filtersElements/FilterByPeriod.vue";
import PageTitle from "@/elements/PageTitle.vue";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import CRow from "./CRow.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";

const { consultations,loadingStatus } = storeToRefs(useConsultationsStore());
</script>
