<template>
  <LoaderRing v-if="loading" />
  <router-view v-else></router-view>
</template>

<script setup>
import { ref, watch } from "vue";
import { useCognationsStore } from "@/store/cognations";
import { customAxios } from "@/service/customAxios";

import { usePopupStore } from "@/store/popup";
import { useRoute } from "vue-router";

import LoaderRing from "@/elements/LoaderRing.vue";

const route = useRoute();
const loading = ref(false);
const { showError } = usePopupStore();
const { setCognations } = useCognationsStore();

watch(() => route.query.filterByChildId, getChildrenCognations, {
  immediate: true,
});

async function getChildrenCognations() {
  if (!route.query.filterByChildId) {
    return;
  }
  loading.value = true;

  try {
    const r = await getChildrenCognationsHandler();
    setCognations(r.data);
  } catch (error) {
    showError(error);
  } finally {
    loading.value = false;
  }
}

async function getChildrenCognationsHandler() {
  return customAxios.get("/cognation/my/childs");
}
</script>
