<template>
  <div>
    <router-link
      :to="{ name: 'employee-consultations-list' }"
      class="bordered-item mb-3"
      >К консультациям</router-link
    >
    <PageTitle title="Протоколы консультации" />
    <div class="c-messages__editor page-wrapper">
      <h3 class="mb-3">Добавление протокола</h3>
      <v-textarea
        v-model="message"
        hide-details
        variant="outlined"
        placeholder="Введите текст протокола"
        bg-color="white"
      />
      <div class="d-flex justify-end">
        <AppButton @click="create" class="bordered-item mt-2"
          >Сохранить протокол</AppButton
        >
      </div>
    </div>

    <div v-if="messages.length > 0" class="c-messages__content my-5">
      <h3>Добавленные протоколы</h3>
      <CMessage v-for="c in messages" :key="c.id" :item="c" />
    </div>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { ref, watch } from "vue";

import { useRoute } from "vue-router";

import CMessage from "./CMessage.vue";
import AppButton from "@/elements/AppButton.vue";
import PageTitle from "@/elements/PageTitle.vue";

const { showError } = usePopupStore();
const route = useRoute();

const message = ref("");
const messages = ref([]);

watch(() => route.params.id, getMessages, { immediate: true });

async function getMessages() {
  if (!route.params.id) {
    return;
  }
  try {
    const r = await getMessagesHandler();
    messages.value = r.data;
  } catch (error) {
    showError(error);
  }
}

function getMessagesHandler() {
  return customAxios.get(
    `/parent_consultation/consultant/protocol/get_protocols_by_consultation_id/${route.params.id}`
  );
}

async function create() {
  try {
    await createHandler();
    getMessages();
    message.value = "";
  } catch (error) {
    showError(error);
  }
}

async function createHandler() {
  return customAxios.post(`/parent_consultation/consultant/protocol`, {
    parentConsultationId: Number(route.params.id),
    protocolBody: message.value,
  });
}
</script>

<style lang="scss">
// .c-messages {
//   position: relative;
//   height: 100%;

//   &__content {
//     overflow: scroll;
//   }

//   &__editor {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     z-index: 10;
//     // background-color: $main-1;
//     padding: 8px;
//     border-radius: 8px;
//   }
// }
</style>
