<template>
  <router-view></router-view>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { useConsultationsStore } from "@/store/consultations";

import { usePopupStore } from "@/store/popup";
import { watch } from "vue";
import { useRoute } from "vue-router";

const { setConsultations, setLoadingStatus } = useConsultationsStore();
const { showError } = usePopupStore();

const route = useRoute();

watch(() => route.query.startDate, getConsultations, { immediate: true });
watch(() => route.query.endDate, getConsultations, { immediate: true });

async function getConsultations() {
  if (!route.query.endDate || !route.query.startDate) {
    return;
  }
  setLoadingStatus("loading");
  
  try {
    const r = await getConsultationsHandler();
    setConsultations(r.data);
  } catch (error) {
    showError(error);
  } finally {
    setLoadingStatus("loaded");
  }
}

async function getConsultationsHandler() {
  return customAxios.post("/parent_consultation/admin/on_period", {
    beginDate: route.query.startDate + "T00:00:00.000Z",
    endDate: route.query.endDate + "T00:00:00.000Z",
  });
}
</script>
