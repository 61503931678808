//import axios from "axios";
import {router} from "@/service/router";

//const signUrl = `${apiUrl}/auth/signin`
import { userService } from '@/service/user.service';

let user = JSON.parse(localStorage.getItem('user'))

const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null }

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ dispatch, commit }, { username, password }) {
      commit('loginRequest', { username });

      userService.login(username, password)
          .then(
              user => {
                commit('loginSuccess', user);
                router.push('/');
              },
              error => {
                commit('loginFailure', error);
                dispatch('alert/error', error, { root: true });
              }
          );
    },

    logout ({ commit }) {
      localStorage.removeItem('user')
      commit('logout')
    }
  },
  mutations: {
    loginRequest (state, user) {
      state.status = { loggingIn: true }
      state.user = user
    },
    loginSuccess (state, user) {
      state.status = { loggedIn: true }
      state.user = user
      localStorage.ProfileSidebarItem = 0
      localStorage.AdminSidebarItem = 0
    },
    loginFailure (state) {
      state.status = false
      state.user = null
    },
    logout (state) {
      state.status = false
      state.user = null
      localStorage.ProfileSidebarItem = null
      localStorage.AdminSidebarItem = null
    }
  }
}
