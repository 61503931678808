import { defineStore } from "pinia";

function getTitle(list, item) {
  const existed = list.find((v) => v.value === item);
  return existed !== undefined ? existed.title : "";
}

export const useFiltersStore = defineStore("filters", {
  state: () => ({
    year: null,
    group: null,
    child: null,
    skillLevel:null,
  }),
  actions: {
    setFilterYear(list, item) {
      this.year = getTitle(list, item);
    },
    setFilterGroup(list, item) {
      this.group = getTitle(list, item);
    },
    setFilterChild(list, item) {
      this.child = getTitle(list, item);
    },
    setFilterSkillLevel(list, item) {
      this.skillLevel = getTitle(list, item);
    },
  },
});
