import { ref } from "vue";
import { customAxios } from "../service/customAxios";
import { usePopupStore } from "@/store/popup";
import { useAuthStore } from "@/store/auth";
import { utils, write } from "xlsx";

export function useUserData() {
  const { showError, showInfoPopup } = usePopupStore();
  const activeUserGroups = ref([]);

  function getUserHandler() {
    return customAxios.get("/user/i");
  }

  async function updateUserInfo() {
    const { setProfile, userId } = useAuthStore();
    if (!userId) {
      return;
    }

    const r = await getUserHandler();
    setProfile(r.data);

    return r.data;
  }

  async function getActiveUserGroups() {
    try {
      const r = await customAxios.get("/user_group/active");
      activeUserGroups.value = r.data;
    } catch (e) {
      console.log(e);
    }
  }

  function downloadExcel(title, tableId) {
    const table = document.querySelector("#" + tableId);
    const wb = utils.table_to_book(table, { sheet: "Sheet1" });
    const about = write(wb, { bookType: "xlsx", type: "binary" });

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    const blob = new Blob([s2ab(about)], { type: "application/octet-stream" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = title + ".xlsx";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function downloadReport(title, table) {
    function tableToCSV(table) {
      const rows = table.querySelectorAll("tr");
      const csv = [];

      rows.forEach((row) => {
        const cells = Array.from(row.querySelectorAll("td,th"));

        const csvrow = [];

        cells.forEach((cell) => {
          csvrow.push(escapeCSV(cell.innerText));
        });
        csv.push(csvrow.join(","));
      });

      return csv.join("\n");
    }

    function escapeCSV(text) {
      if (text.includes(",") || text.includes('"')) {
        return `"${text.replace(/"/g, '""')}"`;
      }

      return text;
    }

    const csv_data = tableToCSV(table);

    const blob = new Blob([csv_data], {
      type: "text/csv;charset=windows-1251;",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = title + ".csv";

    a.click();
    window.URL.revokeObjectURL(url);
  }

  async function getUserById(id) {
    return customAxios.get("/user/" + id);
  }

  async function getLoginLink(id) {
    try {
      const r = await getLoginLinkHandler(id);
      if (r.status === 200) {
        let loginUrl = "https://school.eurekacenter.ru/login/" + r.data.uuid;
        await navigator.clipboard.writeText(loginUrl);

        showInfoPopup(
          "Ссылка для входа: " + loginUrl + " (скопирована в буфер обмена!)"
        );
      }
    } catch (e) {
      showError(e);
    }
  }

  async function getLoginLinkHandler(id) {
    return customAxios.get("/auth/ololo/get_uuid/" + id);
  }

  async function loginByUuid(uuid) {
    return customAxios.post("/auth/ololo", {
      uuid: uuid,
    });
  }

  function resetUserLocalStorage() {
    localStorage.removeItem("user");
    localStorage.removeItem("user-role");
    localStorage.removeItem("user-profile");
    localStorage.removeItem("ss-main-child");
  }

  return {
    resetUserLocalStorage,
    loginByUuid,
    getUserHandler,
    updateUserInfo,
    downloadExcel,
    getUserById,
    downloadReport,
    getActiveUserGroups,
    activeUserGroups,
    getLoginLink,
  };
}
