<template>
  <v-select
    :items="timeSelect"
    variant="outlined"
    density="compact"
    placeholder="Выберите время начала"
    @update:model-value="$emit('update:modelValue', $event)"
    :modelValue="modelValue"
    hide-details
  />
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps(["modelValue"]);
defineEmits(["update:modelValue"]);

const timeSelect = ["11:00", "12:00", "13:00", "14:00", "15:00"];
</script>

<style lang="scss"></style>
