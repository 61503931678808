<template>
  <CustomForm
    title="оценочного листа"
    @create="create"
    @remove="remove"
    :saveIsDisable="saveIsDisable"
    :show-add-child="true"
    @addChild="getChilds"
  >
    <div v-if="dateTitle" class="form__item">
      <div class="form__label">Дата</div>
      <v-chip class="pa-2">{{ dateTitle }}</v-chip>
    </div>

    <div
      class="form__item"
      :class="{
        error: v$.sheetType.$errors.length,
        success:
          !v$.sheetType.$errors.length &&
          v$.sheetType.$model &&
          v$.sheetType.$dirty,
      }"
    >
      <v-select
        v-model="sheetType"
        :items="typesOfWork"
        variant="outlined"
        density="compact"
        hide-details
        placeholder="Выберите тип работы"
      ></v-select>
      <div
        class="input-errors"
        v-for="error of v$.sheetType.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <div
      :class="{
        error: v$.name.$errors.length,
        success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
      }"
      class="form__item"
    >
      <p class="form__label">Название:</p>
      <input
        v-model="v$.name.$model"
        type="text"
        @focus="v$.name.$touch"
        maxlength="100"
        class="form__input"
      />

      <div
        :class="{ 'showing-tip': v$.name.$dirty && v$.name.$model.length }"
        class="form__tip-wrapper"
      >
        <div class="form__tip form__tip--counter showing">
          <span>Любые символы </span>
          <span> {{ v$.name.$model.length }} / 100 </span>
        </div>
      </div>

      <div
        class="input-errors"
        v-for="error of v$.name.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <v-switch
      v-model="showResultForParent"
      color="success"
      :label="
        showResultForParent ? 'показывать родителям' : 'не показывать родителям'
      "
    />
  </CustomForm>
  <div v-if="showChildForm" class="mt-4 mb-4">
        <div class="page-wrapper">
          <h3 class="mb-3">Добавить ребенка</h3>
          <v-autocomplete
                    v-model="journalChildren"
                    :items="journalChildrenset"
                      outlined
                      dense
                      item-value="id"
                      label="Дети из группы (подгруппы)"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.lastname + ' ' + item.raw.firstname + ' ' + item.raw.secname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.lastname + ' ' + item.raw.firstname + ' ' + item.raw.secname"
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
            </v-autocomplete>
            <div class="text-right">
              <v-btn @click="showChildForm=false" class="bordered-item ma-1">Отмена</v-btn>
              <v-btn @click="addChildToJournal" class="bordered-item ma-1">Добавить</v-btn>
            </div>
        </div>
      </div>
</template>

<script setup>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";
import { ref, computed, watch, defineProps } from "vue";

import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { debounce } from "@/util/common";

import { usePopupStore } from "@/store/popup";
import { useRouter, useRoute } from "vue-router";
import { customAxios } from "@/service/customAxios";

import CustomForm from "@/components/CustomForm.vue";

const props = defineProps({ sheet: { type: Object, required: false } });

const typesOfWork = [
  { title: "Проверочная работа", value: 0 },
  { title: "Самостоятельная работа", value: 1 },
  { title: "Диагностическая работа", value: 2 },
];

const rules = {
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
  sheetType: {
    required: helpers.withMessage("Обязательное поле", required),
  },
};

const router = useRouter();
const route = useRoute();
const { closePopup, showError, showToastPopup, showConfirmPopup } =
  usePopupStore();

const { journal, dateFromJournal } = storeToRefs(useSubjectsJournalStore());

const sheetType = ref(null);
const name = ref("");
const showResultForParent = ref(false);
const dateTitle = computed(() => dateFromJournal.value(route.query.dayId));

watch(() => props.sheet, setCurrentData, { immediate: true });

//<!--add childs-->
const journalChildrenset = ref([]);
const journalChildren = ref('');
const showChildForm = ref(false);

async function getChildSet() {
  return customAxios.get("/teacher/log/children_for_log/" + route.query.filterByJournalId);
}
async function addChild() {
  return customAxios.post("/teacher/log/skill_list/add_child", {
    listId: Number(route.query.listId),
    childId: journalChildren.value
  });
}
async function addChildToJournal() {
  try {
    const res =await addChild();
    console.log("res:" + res.data)
   
    showChildForm.value=false;
    journalChildren.value='';
    
  } catch (e) {
    showError(e);
    showChildForm.value=false;
    journalChildren.value='';
  }
}

async function getChilds() {
  try {
    const res =await getChildSet();
    console.log("res:" + res.data)
    journalChildrenset.value=res.data;
    if(journalChildrenset.value.length>0){
      journalChildren.value=journalChildrenset.value[0].id
    }
    showChildForm.value=true;
  } catch (e) {
    showError(e);
  }
}
//

function setCurrentData() {
  if (!props.sheet) {
    return;
  }
  name.value = props.sheet.skillListDescription;
  showResultForParent.value = props.sheet.showResultForParent;
  sheetType.value = props.sheet.listType;
}

const v$ = useVuelidate(rules, {
  name: name,
  sheetType: sheetType,
});

const saveIsDisable = computed(() => {
  v$.value.$touch;

  return v$.value.$invalid;
});

const debounceUpdate = debounce(editSheet, 1000);

watch(name, async (n) => {
  if (props.sheet && n !== props.sheet.skillListDescription) {
    debounceUpdate();
  }
});

watch(showResultForParent, async (n) => {
  if (props.sheet && n !== props.sheet.showResultForParent) {
    editSheet();
  }
});

watch(sheetType, (v) => {
  if (props.sheet && v !== props.sheet.listType) {
    editSheet();
  }
});

async function editSheet() {
  if (!route.query.listId || v$.value.$invalid) {
    return;
  }
  try {
    await editSheetHandler();
    showToastPopup("Изменения сохранены");
  } catch (e) {
    showError(e);
  }
}

async function create() {
  if (!route.query.dayId) {
    return;
  }

  try {
    await createHandler();

    showToastPopup("Оценочный лист создан");

    back();
  } catch (e) {
    showError(e);
  }
}

async function createHandler() {
  return customAxios.post(`/teacher/log/skill_list`, {
    showResultForParent: showResultForParent.value,
    skillListDescription: name.value,
    listType: sheetType.value,
    teacherLogId: journal.value.id,
    teacherLogDayId: Number(route.query.dayId),
  });
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "no") {
      closePopup();
      return;
    }

    closePopup();
    await removeList();
    showToastPopup("Оценочный лист удален");
    back();
  } catch (e) {
    showError(e);
  }
}

async function editSheetHandler() {
  return customAxios.put(`/teacher/log/skill_list/${route.query.listId}`, {
    showResultForParent: showResultForParent.value,
    skillListDescription: name.value,
    listType: sheetType.value,
  });
}

async function customConfirm() {
  return showConfirmPopup("Удалить Оценочный лист?");
}

async function removeList() {
  return customAxios.delete(`/teacher/log/skill_list/${route.query.listId}`);
}

function back() {
  router.push({
    name: "sheets",
    query: {
      filterByJournalId: route.query.filterByJournalId,
      dayId: route.query.dayId,
    },
  });
}
</script>
