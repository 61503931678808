<template>
  <div
    class="g-col"
    :class="userGlobalRole === 'employee' ? 'g-col--1fr-max' : 'g-col--2-1fr'"
  >
    <FilterByGroupAndChild v-if="userGlobalRole === 'employee'" />
    <FilterByChild v-if="isParent" />
    <slot></slot>
  </div>
  <div class="g-col mt-2">
    <FilterBySkillLevel></FilterBySkillLevel>
  </div>
</template>

<script setup>
import FilterByChild from "@/components/filtersElements/FilterByChild.vue";
import FilterByGroupAndChild from "@/components/filtersElements/FilterByGroupAndChild.vue";
import FilterBySkillLevel from "@/components/filtersElements/FilterBySkillLevel.vue";
import { useAuthStore } from "@/store/auth";

const {  userGlobalRole, isParent } = useAuthStore();

</script>

