<template>
  <v-autocomplete
    v-if="children.length"
    v-model="selectedChildId"
    :items="children"
    variant="outlined"
    placeholder="Ребенок"
    item-value="value"
    item-title="title"
    density="compact"
    hide-details
    bg-color="white"
  ></v-autocomplete>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { removeFilterFromURL, pushFilterToURL } from "@/service/router";
import { useRoute } from "vue-router";

import { useParentData } from "@/composables/parent";
import { useFiltersStore } from "@/store/filters";
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";

const { setFilterChild } = useFiltersStore();

const route = useRoute();
const { getUser, children, selectedChildId } = useParentData();
const { mainChild } = storeToRefs(useAuthStore());

watch(selectedChildId, (id) =>
  id
    ? pushFilterToURL({ name: "filterByChildId", value: id })
    : removeFilterFromURL("filterByChildId")
);

watch(() => route.query.filterByChildId, setChild);
watch(mainChild, setChild, {immediate: true});
watch(children, setChild, { deep: true });

onMounted(getUser);

function setChild() {
  if (!children.value.length) {
    return;
  }

  let childId;

  if (route.query.filterByChildId) {
    childId = Number(route.query.filterByChildId);
  } else if (mainChild.value) {
    childId = mainChild.value;
  }

  selectedChildId.value =
    childId && children.value.findIndex((v) => v.value === childId) >= 0
      ? childId
      : children.value[0];

  setFilterChild(children.value, selectedChildId.value);
}
</script>
