import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { usePopupStore } from "./popup";
import { useRoute } from "vue-router";

export const useConsultationsStore = defineStore("consultations", () => {
  const { showToastPopup, showWarningPopup } = usePopupStore();

  const consultations = ref([]);
  const route = useRoute();
  const loadingStatus = ref("nothing");
  const slots = ref([]);

  const slotById = computed(() => {
    if (!route.params.id || !consultations.value.length) {
      return null;
    }
    return consultations.value.find((v) => v.id === Number(route.params.id));
  });

  function setConsultations(v) {
    consultations.value = v;
  }

  function setLoadingStatus(status) {
    loadingStatus.value = status;
  }

  function removeSlot(id) {
    const arr = slots.value.slice().filter((_, i) => i === id);
    slots.value = arr;
  }

  function addToSlots(v) {
    if (!slots.value.length) {
      add();
    } else {
      const isExisted = slots.value.find(
        (s) => s.consultationDate === v.consultationDate
      );
      isExisted
        ? showWarningPopup("Слот с такой датой и временем уже существует!")
        : add();
    }

    function add() {
      slots.value.push(v);
      showToastPopup("Слот добавлен");
    }
  }

  return {
    slots,
    addToSlots,
    removeSlot,
    loadingStatus,
    setLoadingStatus,
    slotById,
    consultations,
    setConsultations,
  };
});
