import { useStore } from "vuex";

export const consultationStatuses = {
  CONSULTATION_CREATED: "Консультация создана",
  CONSULTATION_BOOKED: "Заявка на бронирование создана родителем",
  CONSULTATION_BOOKED_CONFIRMED: "Бронирование подтверждено",
  CONSULTATION_BOOKED_REJECTED: "Бронирование отклонено",
  CONSULTATION_PROTOCOL_CREATED: "Протокол консультации создан",
  CONSULTATION_COMPLETED: "Консультация завершена",
};

export const schoolPeriods = [
  {
    title: "1 четверть",
    value: 1,
    valuePeriod: ["2023-09-01T00:00:00.000Z", "2023-10-31T00:00:00.000Z"],
  },
  {
    title: "2 четверть",
    value: 2,
    valuePeriod: ["2023-11-01T00:00:00.000Z", "2023-12-30T00:00:00.000Z"],
  },
  {
    title: "3 четверть",
    value: 3,
    valuePeriod: ["2024-01-08T00:00:00.000Z", "2024-03-29T00:00:00.000Z"],
  },
  {
    title: "4 четверть",
    value: 4,
    valuePeriod: ["2024-04-01T00:00:00.000Z", "2024-05-31T00:00:00.000Z"],
  },
];

export const monthDict = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь ",
  "Декабрь",
];

export const schoolMonthDict = [
  { title: "Январь", value: 0 },
  { title: "Февраль", value: 1 },
  { title: "Март", value: 2 },
  { title: "Апрель", value: 3 },
  { title: "Май", value: 4 },
  { title: "Июнь", value: 5 },
  { title: "Июль", value: 6 },
  { title: "Август", value: 7 },
  { title: "Сентябрь", value: 8 },
  { title: "Октябрь", value: 9 },
  { title: "Ноябрь ", value: 10 },
  { title: "Декабрь", value: 11 },
];

export const monthDictWithoutSummer = schoolMonthDict.filter(
  (v) => v.value !== 5 && v.value !== 6 && v.value !== 7
);

export const daysDictionary = [
  "Воскресенье",
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота",
];

export const shortNamesDaysDictionary = [
  "ВС",
  "ПН",
  "ВТ",
  "СР",
  "ЧТ",
  "ПТ",
  "СБ",
];

export const schooldaysDictionary = ["ПН", "ВТ", "СР", "ЧТ", "ПТ"];

export const dayOfWeekDict = [
  { id: 0, value: 0, active: false, title: "Воскресенье" },
  { id: 1, value: 1, active: true, title: "Понедельник" },
  { id: 2, value: 2, active: true, title: "Вторник" },
  { id: 3, value: 3, active: true, title: "Среда" },
  { id: 4, value: 4, active: true, title: "Четверг" },
  { id: 5, value: 5, active: true, title: "Пятница" },
  { id: 6, value: 6, active: false, title: "Суббота" },
];

export const visitStatusList = [
  {
    title: "Присутствие",
    value: 0,
    shortTitle: "П",
  },
  {
    title: "Отсутствие по болезни",
    value: 1,
    shortTitle: "ОБ",
  },
  {
    title: "Отсутствие по ув.причине",
    value: 2,
    shortTitle: "ОУ",
  },
  {
    title: "Отсутствие по не ув. причине",
    value: 3,
    shortTitle: "ОНУ",
  },
  { title: "Выходной", shortTitle: "В", value: 4 },
];

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getDateNamedMonth = (date) => {
  const d = new Date(date);

  return `${d.getDate()} ${monthDict[d.getMonth()]} ${d.getFullYear()}`;
};

export function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export const levelValues = [50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];

export function showErrorByStatus(status) {
  switch (status) {
    case 404:
      return "Неверный запрос на сервер";
  }
}

export function toggleArrayItem(array, id) {
  const isExisted =
    array.findIndex((v) => {
      return v === id;
    }) >= 0;
  let resultArray = [];

  if (isExisted) {
    resultArray = array.filter((v) => v !== id);
  } else {
    resultArray = array;
    array.push(id);
  }

  return resultArray;
}

export function areTwoDatesEqual(first, second) {
  const x1 = new Date(first);
  const x2 = new Date(second);

  const x = `${x1.getFullYear() / x1.getMonth() / x1.getDate()}`;

  const y = `${x2.getFullYear() / x2.getMonth() / x2.getDate()}`;

  return x === y;
}

export function isItToday(date) {
  const today = new Date();
  const comparedDate = new Date(date);

  return today.getDate() === comparedDate.getDate() ? "Сегодня" : "";
}

export function areTwoDatesWithTimeEqual(first, second) {
  const x1 = new Date(first);
  const x2 = new Date(second);

  const x = `${x1.getFullYear()}${x1.getMonth()}${x1.getDate()}${x1.getHours()}${x1.getMinutes()}`;
  const y = `${x2.getFullYear()}${x2.getMonth()}${x2.getDate()}${x2.getHours()}${x2.getMinutes()}`;

  return x === y;
}

export function getNewestDate(first, second) {
  const x1 = new Date(first);
  const x2 = new Date(second);

  return x1 > x2 ? first : second;
}

export function isExpiredDate(date) {
  const d = new Date(date);
  const today = new Date();

  return today > d;
}

export function sortByName(list) {
  return list.slice().sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
}

export function sortByLastName(list) {
  if (!list || !list.length) {
    return [];
  }
  
  return list.slice().sort(function (a, b) {
    if (a.lastname < b.lastname) {
      return -1;
    }
    if (a.lastname > b.lastname) {
      return 1;
    }
    return 0;
  });
}

export function sortByChildLastname(list) {
  return list.slice().sort(function (a, b) {
    if (a.child.lastname < b.child.lastname) {
      return -1;
    }
    if (a.child.lastname > b.child.lastname) {
      return 1;
    }
    return 0;
  });
}

export function dynamicSort(property, sortOrder) {
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function sortByDate(list, type) {
  return list.slice().sort(function (a, b) {
    if (type === "descending") {
      return b.createDateTime > a.createDateTime
        ? 1
        : b.createDateTime < a.createDateTime
        ? -1
        : 0;
    }

    return b.createDateTime > a.createDateTime
      ? -1
      : b.createDateTime < a.createDateTime
      ? 1
      : 0;
  });
}

export function compareArrays(firstArray, secondArray) {
  if (
    firstArray.length === secondArray.length &&
    firstArray.every((el, i) => el === secondArray[i])
  ) {
    return true;
  }
  return false;
}

export function getCurrentUserId() {
  const store = useStore();

  return store.state.authentication.user.id;
}

export const debounce = (mainFunc, delay) => {
  let timer;

  return function (...args) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      mainFunc(...args);
    }, delay);
  };
};

export function formatDate(dateString) {
  let formatter = new Intl.DateTimeFormat("ru", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return capitalizeFirstLetter(formatter.format(Date.parse(dateString)));
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function getPreviosSchoolMonth() {
  let month = [];
  const d = new Date();
  const currentMonth = d.getMonth();

  const JANUARY = 0;
  const MAY = 4;
  const DECEMBER = 11;
  const SEPTEMBER = 8;

  const allMonth = [8, 9, 10, 11, 0, 1, 2, 3, 4];

  const isSummer = currentMonth > MAY && currentMonth < SEPTEMBER;

  if (isSummer) {
    return allMonth;
  }

  if (currentMonth === JANUARY) {
    month = allMonth.slice(0, 5);
  }

  if (currentMonth > JANUARY && currentMonth < MAY) {
    month = allMonth.slice(0, 4);

    for (let i = 0; i <= currentMonth; i++) {
      month.push(i);
    }
  }

  if (currentMonth >= SEPTEMBER && currentMonth <= DECEMBER) {
    for (let i = SEPTEMBER; i <= currentMonth; i++) {
      month.push(i);
    }
  }

  return month;
}

export function getNewestItem(item1, item2) {
  const x1 = new Date(item1.cognationCreateDate);
  const x2 = new Date(item2.cognationCreateDate);

  return x1 > x2 ? item1 : item2;
}

export const TITLE_NO_LESSONS = "В этот день нет уроков";

export function generatePasswordHandler() {
  let text = "";
  const possible = "ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz";
  const possibleSimbols = "!@#$%&*";
  const possibleDigits = "123456789";

  for (var i = 0; i < 8; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  text += possibleSimbols.charAt(
    Math.floor(Math.random() * possibleSimbols.length)
  );
  text += possibleDigits.charAt(
    Math.floor(Math.random() * possibleDigits.length)
  );

  return text;
}

export function setCurrentMonth() {
  const d = new Date();
  const month = new Date(d);
  month.setDate(month.getDate() + 30);
  return [d, month];
}
