<template>
  <CustomForm
    title="оценочного листа"
    @create="create"
    @remove="remove"
    @addChild="getChilds"
    :saveIsDisable="saveIsDisable"
    :show-add-child="true"
  >
    <div v-if="dateTitle" class="form__item">
      <div class="form__label">Дата</div>
      <v-chip class="pa-2">{{ dateTitle }}</v-chip>
    </div>

    <div
      :class="{
        error: v$.name.$errors.length,
        success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
      }"
      class="form__item"
    >
      <input
        v-model="v$.name.$model"
        type="text"
        @focus="v$.name.$touch"
        placeholder="Название"
        maxlength="100"
        class="form__input"
      />

      <div
        :class="{ 'showing-tip': v$.name.$dirty && v$.name.$model.length }"
        class="form__tip-wrapper"
      >
        <div class="form__tip form__tip--counter showing">
          <span>Любые символы </span>
          <span> {{ v$.name.$model.length }} / 100 </span>
        </div>
      </div>

      <div
        class="input-errors"
        v-for="error of v$.name.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <v-switch
      v-model="showResultForParent"
      color="success"
      hide-details
      :label="
        showResultForParent ? 'показывать родителям' : 'не показывать родителям'
      "
    />
  </CustomForm>
  <div v-if="showChildForm" class="mt-4 mb-4">
        <div class="page-wrapper">
          <h3 class="mb-3">Добавить ребенка</h3>
          <v-autocomplete
                    v-model="journalChildren"
                    :items="journalChildrenset"
                      outlined
                      dense
                      item-value="id"
                      label="Дети из группы (подгруппы)"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.lastname + ' ' + item.raw.firstname + ' ' + item.raw.secname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.lastname + ' ' + item.raw.firstname + ' ' + item.raw.secname"
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
            </v-autocomplete>
            <div class="text-right">
              <v-btn @click="showChildForm=false" class="bordered-item ma-1">Отмена</v-btn>
              <v-btn @click="addChildToJournal" class="bordered-item ma-1">Добавить</v-btn>
            </div>
        </div>
      </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, onMounted } from "vue";
import { customAxios } from "@/service/customAxios";
import { useSubjectsJournalStore } from "@/store/subjects-journal";

import { storeToRefs } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";

import { usePopupStore } from "@/store/popup";
import { useRoute, useRouter } from "vue-router";
import { debounce } from "@/util/common";

import CustomForm from "@/components/CustomForm.vue";
import { useJournalData } from "@/composables/journalData";

const rules = {
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
};
const route = useRoute();
const router = useRouter();

const { journal, dateFromJournal } = storeToRefs(useSubjectsJournalStore());
const dateTitle = computed(() => dateFromJournal.value(route.query.dayId));

const { closePopup, showError, showToastPopup, showConfirmPopup } =
  usePopupStore();
const { updateRatingSheets } = useJournalData();

const props = defineProps({ sheet: { type: Object, required: false } });

const name = ref("");
const showResultForParent = ref(false);

onMounted(updateRatingSheets);

watch(() => props.sheet, setCurrentData, { immediate: true });
//<!--add childs-->
const journalChildrenset = ref([]);
const journalChildren = ref('');
const showChildForm = ref(false);

async function getChildSet() {
  return customAxios.get("/teacher/log/children_for_log/" + route.query.filterByJournalId);
}
async function addChild() {
  return customAxios.post("/foreign_checklist/add_child", {
    listId: Number(route.query.listId),
    childId: journalChildren.value
  });
}
async function addChildToJournal() {
  try {
    const res =await addChild();
    console.log("res:" + res.data)
   
    showChildForm.value=false;
    journalChildren.value='';
    
  } catch (e) {
    showError(e);
    showChildForm.value=false;
    journalChildren.value='';
  }
}

async function getChilds() {
  try {
    const res =await getChildSet();
    console.log("res:" + res.data)
    journalChildrenset.value=res.data;
    if(journalChildrenset.value.length>0){
      journalChildren.value=journalChildrenset.value[0].id
    }
    showChildForm.value=true;
  } catch (e) {
    showError(e);
  }
}
//

function setCurrentData() {
  if (!props.sheet) {
    return;
  }
  name.value = props.sheet.listDescription;
  showResultForParent.value = props.sheet.showResultForParent;
}

const v$ = useVuelidate(rules, {
  name: name,
});

const saveIsDisable = computed(() => {
  v$.value.$touch;

  return v$.value.$invalid;
});

async function create() {
  if (!route.query.dayId) {
    return;
  }

  try {
    await createHandler();
    showToastPopup("Оценочный лист создан");
    back();
  } catch (e) {
    showError(e);
  }
}

const debounceUpdate = debounce(edit, 1000);

watch(name, async (n) => {
  if (props.sheet && n !== props.sheet.listDescription) {
    debounceUpdate();
  }
});

watch(showResultForParent, async (n) => {
  if (props.sheet && n !== props.sheet.showResultForParent) {
    edit();
  }
});

async function edit() {
  if (!route.query.listId || v$.value.$invalid) {
    return;
  }

  try {
    await editHandler();
    showToastPopup("Изменения сохранены");
  } catch (e) {
    showError(e);
  }
}

async function editHandler() {
  return customAxios.put("/foreign_checklist/" + route.query.listId, {
    listDescription: name.value,
    showResultForParent: showResultForParent.value,
  });
}

async function createHandler() {
  return customAxios.post("/foreign_checklist/", {
    listDescription: name.value,
    teacherLogId: journal.value.id,
    teacherLogDayId: Number(route.query.dayId),
    showResultForParent: showResultForParent.value,
  });
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "no") {
      closePopup();
      return;
    }

    closePopup();
    await removeList();
    showToastPopup("Оценочный лист удален");
    back();
  } catch (e) {
    showError(e);
  }
}

async function customConfirm() {
  return showConfirmPopup("Удалить Оценочный лист?");
}

async function removeList() {
  return customAxios.delete(`/foreign_checklist/${route.query.listId}`);
}

function back() {
  router.push({
    name: "sheets",
    query: {
      filterByJournalId: route.query.filterByJournalId,
      dayId: route.query.dayId,
    },
  });
}
</script>
